"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisciplineInfoPanel = void 0;
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var material_1 = require("@mui/material");
var axios = require("axios");
var material_2 = require("@mui/material");
var MenuItem_1 = require("@mui/material/MenuItem");
var FormControl_1 = require("@mui/material/FormControl");
var Select_1 = require("@mui/material/Select");
var CircularProgress_1 = require("@mui/material/CircularProgress");
var Box_1 = require("@mui/material/Box");
var OrgUnitDisplayDataInDisc_1 = require("./OrgUnitDisplayDataInDisc");
var FormSelectAvailEvalPeriods_1 = require("../forms/FormSelectAvailEvalPeriods");
require("./DisciplineInfoPanel.scss");
var DisciplineFooterSummary = function (p) {
    if (p == null)
        return null;
    var lata = p.display.yrs.map(function (para, idx) { return React.createElement(React.Fragment, { key: "sumPar" + idx },
        React.createElement("tr", null,
            React.createElement("td", null, para.rok),
            React.createElement("td", null, para.etatow))); });
    return React.createElement("div", { key: "pods_dysc_" },
        React.createElement("div", null,
            "Liczba N: ",
            React.createElement("span", { className: "boldface" }, p.display.N)),
        React.createElement("div", null,
            "Liczba 3N: ",
            React.createElement("span", { className: "boldface" }, p.display.NNN)),
        React.createElement("div", null,
            React.createElement("table", { className: "daneNtable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Rok"),
                        React.createElement("th", null, "N"))),
                React.createElement("tbody", null, lata))));
};
var DisciplineInfoPanel = /** @class */ (function (_super) {
    __extends(DisciplineInfoPanel, _super);
    function DisciplineInfoPanel(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { dostDysc: [], errorMessage: null, currentSelectedDisc: '', zestawienie: null, loadProgressBar: true, periodCode: '' };
        return _this;
    }
    DisciplineInfoPanel.prototype.componentDidMount = function () {
        this.getAccesibleDisciplines(this);
    };
    DisciplineInfoPanel.prototype.comboSelectedItemChanged = function (item) {
        this.setState({ periodCode: item, currentSelectedDisc: '', zestawienie: null });
    };
    DisciplineInfoPanel.prototype.render = function () {
        var _this = this;
        var optList = this.state.dostDysc.sort(function (a, b) {
            if (a.dysc_nazwa < b.dysc_nazwa)
                return -1;
            if (a.dysc_nazwa > b.dysc_nazwa)
                return 1;
            return 0;
        }).map(function (el, i) { return React.createElement(MenuItem_1.default, { key: "mi_" + i.toString() + "_" + el.ue_disc_code, value: i.toString() + "_" + el.ue_disc_code }, el.dysc_nazwa); });
        var z = this.state.currentSelectedDisc.split('_');
        var kodDysc;
        if (this.state.currentSelectedDisc == "")
            kodDysc = "";
        else
            kodDysc = parseInt(z[1]);
        var nazwaDysc;
        if (this.state.dostDysc.length > 0) {
            var opt = this.state.dostDysc.find(function (x, ii) { return (ii.toString() + "_" + x.ue_disc_code) == _this.state.currentSelectedDisc; });
            if (opt !== undefined)
                nazwaDysc = opt.dysc_nazwa;
            else
                nazwaDysc = "";
        }
        else
            nazwaDysc = "";
        var catedraList = this.state.zestawienie == null ? null : this.state.zestawienie.units.map(function (el, i) { return React.createElement(OrgUnitDisplayDataInDisc_1.OrgUnitDisplayDataInDisc, __assign({ key: "PanelJedn_" + i, container: el, discipline: kodDysc, discipline_name: nazwaDysc, periodCode: _this.state.periodCode, displayDownloadButton: 1, currentUnitCode: null }, _this.props)); });
        var summaryDiscStat = this.state.zestawienie == null ? null : React.createElement("div", { key: "disciplineSummaryInfo" },
            React.createElement(material_1.Typography, { variant: "h5" },
                "Informacje podsumowuj\u0105ce dla dyscypliny: ",
                nazwaDysc),
            React.createElement(OrgUnitDisplayDataInDisc_1.OrgUnitSummary, { display: this.state.zestawienie.stats }),
            React.createElement(DisciplineFooterSummary, { display: this.state.zestawienie.discparam }));
        var backButton = (this.state.zestawienie == null) || (this.state.zestawienie.units.length == 0) ? React.createElement("div", { style: { textAlign: 'center', textAlignLast: 'center' } },
            React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika")) : null;
        return React.createElement("div", { key: "wyborPaneluDyscypliny" },
            this.state.errorMessage !== null ? React.createElement(material_2.Alert, { severity: "error" }, this.state.errorMessage) : null,
            React.createElement(FormSelectAvailEvalPeriods_1.FormSelectAvailEvalPeriods, __assign({ callback: function (frm, val) { return _this.comboSelectedItemChanged(val); } }, this.props)),
            React.createElement(material_1.Typography, { variant: "h6" }, "Dost\u0119pne dyscypliny:"),
            React.createElement(FormControl_1.default, { key: "FromCtrlSelectDiscipline", style: { alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around' } },
                React.createElement(Select_1.default, { value: this.state.currentSelectedDisc, style: { width: '100%', background: 'white' }, inputProps: { readOnly: false }, onChange: function (event, child) { return _this.handleChangeSelectDiscipline(_this, event.target.value.toString()); }, displayEmpty: true }, optList),
                this.state.loadProgressBar ? React.createElement(Box_1.default, { sx: { display: 'flex' } },
                    React.createElement(CircularProgress_1.default, null)) : null,
                catedraList),
            summaryDiscStat,
            backButton);
    };
    DisciplineInfoPanel.prototype.handleChangeSelectDiscipline = function (self, val) {
        var _this = this;
        self.setState({ currentSelectedDisc: val, loadProgressBar: true, zestawienie: null });
        if (self.state.periodCode == '')
            return;
        var z = val.split('_');
        var kodDysc;
        if (val == "")
            kodDysc = "";
        else
            kodDysc = parseInt(z[1]);
        axios.default.post("/api/v2/disciplineReport", { discCode: kodDysc, period: self.state.periodCode })
            .then(function (res) {
            _this.setState({ zestawienie: res.data, loadProgressBar: false, errorMessage: null });
        })
            .catch(function (err) {
            _this.setState({ zestawienie: null, errorMessage: err.message, loadProgressBar: false });
        });
    };
    DisciplineInfoPanel.prototype.getAccesibleDisciplines = function (self) {
        var _this = this;
        axios.default.get("/api/v2/discAccess")
            .then(function (res) {
            _this.setState({ dostDysc: res.data.disciplines, errorMessage: null, loadProgressBar: false });
        })
            .catch(function (err) {
            _this.setState({ dostDysc: [], errorMessage: err.message, loadProgressBar: false });
        });
    };
    return DisciplineInfoPanel;
}(React.Component));
exports.DisciplineInfoPanel = DisciplineInfoPanel;
