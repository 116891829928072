"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var material_1 = require("@mui/material");
var Button_1 = require("@mui/material/Button");
var FormSelectAvailEvalPeriods_1 = require("../forms/FormSelectAvailEvalPeriods");
var AdmRaportyPanel = /** @class */ (function (_super) {
    __extends(AdmRaportyPanel, _super);
    function AdmRaportyPanel(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { currentPeriod: '' };
        return _this;
    }
    AdmRaportyPanel.prototype.render = function () {
        var _this = this;
        var firstSuffix = this.state.currentPeriod == '' ? "" : "?";
        var secondSuffix = this.state.currentPeriod == '' ? "" : "&";
        var periodSuffix = this.state.currentPeriod == '' ? "" : "period=" + this.state.currentPeriod;
        return React.createElement(React.Fragment, { key: "AdmRaportyPanelFragment" },
            React.createElement(FormSelectAvailEvalPeriods_1.FormSelectAvailEvalPeriods, __assign({ callback: function (a, b) { return _this.setState({ currentPeriod: b }); } }, this.props)),
            React.createElement(material_1.Typography, { variant: "h6" }, "Raporty patent\u00F3w:"),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(react_router_dom_1.Link, { to: "/api/admin/ZestawieniePatenty1" + firstSuffix + periodSuffix, target: "_blank" }, "Zestawienie autor\u00F3w i patent\u00F3w")),
                React.createElement("li", null,
                    React.createElement(react_router_dom_1.Link, { to: "/api/admin/ZestawieniePatenty2?skipZeroPoint=f" + secondSuffix + periodSuffix, target: "_blank" }, "Zestawienie dyscyplin i patent\u00F3w (wszystkich)")),
                React.createElement("li", null,
                    React.createElement(react_router_dom_1.Link, { to: "/api/admin/ZestawieniePatenty2?skipZeroPoint=t" + secondSuffix + periodSuffix, target: "_blank" }, "Zestawienie dyscyplin i patent\u00F3w (bez wierszy za 0 pkt)"))),
            React.createElement(material_1.Typography, { variant: "h6" }, "Zestawienia:"),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(react_router_dom_1.Link, { to: "/admin/zestawieniaokresowe" }, "Zestawienia okresowe"))),
            React.createElement("div", { style: { textAlign: 'center', textAlignLast: 'center' } },
                React.createElement(Button_1.default, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika")));
    };
    AdmRaportyPanel.prototype.cancelWindow = function (self) {
        self.props.history.goBack();
    };
    return AdmRaportyPanel;
}(React.Component));
exports.default = AdmRaportyPanel;
