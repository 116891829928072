"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrgUnitInfoPanel = void 0;
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var material_1 = require("@mui/material");
var axios = require("axios");
var material_2 = require("@mui/material");
var MenuItem_1 = require("@mui/material/MenuItem");
var FormControl_1 = require("@mui/material/FormControl");
var Select_1 = require("@mui/material/Select");
var CircularProgress_1 = require("@mui/material/CircularProgress");
var Box_1 = require("@mui/material/Box");
var OrgUnitDisplayDataInDisc_1 = require("./OrgUnitDisplayDataInDisc");
var FormSelectAvailEvalPeriods_1 = require("../forms/FormSelectAvailEvalPeriods");
var OrgUnitInfoPanel = /** @class */ (function (_super) {
    __extends(OrgUnitInfoPanel, _super);
    function OrgUnitInfoPanel(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { dostJednostki: [], errorMessage: null, currentSelectedUnit: '', zestawienie: null, loadProgressBar: true, periodCode: '' };
        return _this;
    }
    OrgUnitInfoPanel.prototype.componentDidMount = function () {
        this.getAccesibleUnits(this);
    };
    OrgUnitInfoPanel.prototype.comboSelectedItemChanged = function (item) {
        this.setState({ periodCode: item, currentSelectedUnit: '', zestawienie: null });
    };
    OrgUnitInfoPanel.prototype.render = function () {
        var _this = this;
        var optList = this.state.dostJednostki.sort(function (a, b) {
            if (a.ou_name < b.ou_name)
                return -1;
            if (a.ou_name > b.ou_name)
                return 1;
            return 0;
        }).map(function (el) { return React.createElement(MenuItem_1.default, { key: "mi_" + el.ou_code, value: el.ou_code }, el.ou_name); });
        var catedraList = (this.state.zestawienie == null) ? null : React.createElement(OrgUnitDisplayDataInDisc_1.OrgUnitDisplayDataInDisc, __assign({ key: "PanelJedn_0", container: this.state.zestawienie, discipline: null, discipline_name: "", periodCode: this.state.periodCode, displayDownloadButton: 2, currentUnitCode: this.state.currentSelectedUnit }, this.props));
        var backButton = (this.state.zestawienie == null) ? React.createElement("div", { style: { textAlign: 'center', textAlignLast: 'center' } },
            React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika")) : null;
        return React.createElement("div", { key: "wyborPaneluJednostki" },
            this.state.errorMessage !== null ? React.createElement(material_2.Alert, { severity: "error" }, this.state.errorMessage) : null,
            React.createElement(FormSelectAvailEvalPeriods_1.FormSelectAvailEvalPeriods, __assign({ callback: function (frm, val) { return _this.comboSelectedItemChanged(val); } }, this.props)),
            React.createElement(material_1.Typography, { variant: "h6" }, "Dost\u0119pne jednostki:"),
            React.createElement(FormControl_1.default, { key: "FromCtrlSelectUnit", style: { alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around' } },
                React.createElement(Select_1.default, { value: this.state.currentSelectedUnit, style: { width: '100%', background: 'white' }, inputProps: { readOnly: false }, onChange: function (event, child) { return _this.handleChangeSelectUnit(_this, event.target.value.toString()); }, displayEmpty: true }, optList),
                this.state.loadProgressBar ? React.createElement(Box_1.default, { sx: { display: 'flex' } },
                    React.createElement(CircularProgress_1.default, null)) : null,
                catedraList),
            backButton);
    };
    OrgUnitInfoPanel.prototype.handleChangeSelectUnit = function (self, val) {
        var _this = this;
        self.setState({ currentSelectedUnit: val, loadProgressBar: true, zestawienie: null });
        if (self.state.periodCode == '')
            return;
        axios.default.post("/api/v2/unitReport", { unitCode: val, period: self.state.periodCode })
            .then(function (res) {
            _this.setState({ zestawienie: res.data, loadProgressBar: false, errorMessage: null });
        })
            .catch(function (err) {
            _this.setState({ zestawienie: null, errorMessage: err.message, loadProgressBar: false });
        });
    };
    OrgUnitInfoPanel.prototype.getAccesibleUnits = function (self) {
        var _this = this;
        axios.default.get("/api/v2/unitAccess")
            .then(function (res) {
            _this.setState({ dostJednostki: res.data.OrgUnits, errorMessage: null, loadProgressBar: false });
        })
            .catch(function (err) {
            _this.setState({ dostJednostki: [], errorMessage: err.message, loadProgressBar: false });
        });
    };
    return OrgUnitInfoPanel;
}(React.Component));
exports.OrgUnitInfoPanel = OrgUnitInfoPanel;
