"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var commonConstants_1 = require("./commonConstants");
var ActionPanel_1 = require("./ActionPanel");
var axios = require("axios");
var material_1 = require("@mui/material");
var DashboardRoutes = /** @class */ (function (_super) {
    __extends(DashboardRoutes, _super);
    function DashboardRoutes(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { panels: [], status: { mode: 0, version: 0, import: null, pendingupgrade: 0 }, errorMessage: null };
        return _this;
    }
    DashboardRoutes.prototype.componentDidMount = function () {
        var _this = this;
        axios.default.post("/api/v2/userPanels").then(function (res) {
            _this.setState(res.data);
        }).catch(function (err) {
            _this.setState({ panels: [], errorMessage: err.message });
        });
    };
    DashboardRoutes.prototype.render = function () {
        var tlsvalue = sessionStorage.getItem(commonConstants_1.ssLoginData);
        if (tlsvalue !== null) {
            var mData = JSON.parse(tlsvalue);
            /// <div>To jest widoczne po zalogowaniu. Użytkownik: '{mData.physUserName}'. Token: '{mData.formToken}'. Dane: '{JSON.stringify(mData.attrs)}'</div>
            /// display: 'flex', flexFlow: 'wrap', float: 'left',
            if (this.state !== null) {
                if (this.state.errorMessage !== null) {
                    return React.createElement(material_1.Alert, { severity: "error" },
                        "B\u0142\u0105d pobierania paneli u\u017Cytkownika: '",
                        this.state.errorMessage,
                        "'. Prosimy spr\u00F3bowa\u0107 p\u00F3\u017Aniej.");
                }
            }
            var panele = this.state.panels.map(function (idPanel) {
                switch (idPanel) {
                    case 0:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/main.php", color: "#31529C", displText: "Lista publikacji, zmiana przypisa\u0144 (poprzedni panel)", pageReload: true });
                    case 1:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/poloninfopanel", color: "#31529C", displText: "Informacje z systemu POLON", pageReload: false });
                    case 2:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/oswiadczenia", color: "#31529C", displText: "O\u015Bwiadczenia", pageReload: false });
                    case 3:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/zmianaprzypisan", color: "#31529C", displText: "Zmiana przypisa\u0144 publikacji do dyscyplin", pageReload: false });
                    case 4:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/admin/poloninfopnl", color: "#31529C", displText: "Podgl\u0105d danych z POLONu", pageReload: false });
                    case 5:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/admin/patenty", color: "#31529C", displText: "Druk o\u015Bw. patent\u00F3w", pageReload: false });
                    case 6:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/admin/AppAccounts", color: "#31529C", displText: "Konta u\u017Cytkownik\u00F3w", pageReload: false });
                    case 7:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/admin/publikacje", color: "#31529C", displText: "Druk o\u015Bw. publikacji", pageReload: false });
                    case 8:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/admin/raporty", color: "#31529C", displText: "Raporty", pageReload: false });
                    case 9:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/admin/zmianaprzypisan", color: "#31529C", displText: "Administracja przypisaniami publikacji do dyscypliny", pageReload: false });
                    case 10:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/publicationpanelevalasg", color: "#800000", displText: "Przypisania do dyscyplin", pageReload: false });
                    case 11:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/publicationpanelevalrep", color: "#800000", displText: "Wype\u0142nienie slot\u00F3w", pageReload: false });
                    case 12:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/discsuper/info", color: "#31529C", displText: "Widok dysycpliny", pageReload: false });
                    case 13:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/unitsuper/info", color: "#31529C", displText: "Widok jednostki", pageReload: false });
                    case 9000:
                        return React.createElement(ActionPanel_1.ActionPanel, { key: "ActionPanel" + idPanel, target: "/admin/testpanel", color: "#808000", displText: "Wyszukiwanie osoby", pageReload: false });
                }
            });
            var hdr = void 0;
            switch (this.state.status.mode) {
                case 1:
                    hdr = React.createElement("div", { style: { width: "75%", background: "#F0F0F0", color: "#800000", borderTopStyle: "solid", borderLeftStyle: "solid", borderRightStyle: "solid",
                            borderBottomStyle: "solid", borderWidth: "3pt", borderColor: "#FF0000", textAlign: "justify",
                            padding: "5pt", marginLeft: "auto", marginRight: "auto", borderRadius: "5pt" } }, "Wersja przedprodukcyjna do cel\u00F3w testowych! Dane mog\u0105 by\u0107 niekompletne albo nieaktualne");
                    break;
                case 2:
                    hdr = React.createElement("div", { style: { width: "75%", background: "#F0F0F0", color: "#800000", borderTopStyle: "solid", borderLeftStyle: "solid", borderRightStyle: "solid",
                            borderBottomStyle: "solid", borderWidth: "3pt", borderColor: "#FF0000", textAlign: "justify",
                            padding: "5pt", marginLeft: "auto", marginRight: "auto", borderRadius: "5pt" } }, "Wersja deweloperska do cel\u00F3w programistycznych! Dane mog\u0105 by\u0107 niekompletne albo nieaktualne");
                    break;
                default:
                    hdr = "";
                    break;
            }
            if (this.state.status.pendingupgrade > 0) {
                return React.createElement("div", null,
                    React.createElement("div", { style: { width: "75%", background: "#F0F0F0", color: "#800000", borderTopStyle: "solid", borderLeftStyle: "solid", borderRightStyle: "solid",
                            borderBottomStyle: "solid", borderWidth: "1pt", borderColor: "#FF0000", textAlign: "justify", padding: "5pt", marginLeft: "auto", marginRight: "auto",
                            borderRadius: "5pt", marginBlock: "5pt" } },
                        React.createElement("span", { style: { fontWeight: "bold" } }, "Trwa aktualizacja systemu")),
                    ";",
                    React.createElement("div", { style: { marginTop: "20pt", width: "75%", borderTopStyle: "solid", borderLeftStyle: "solid", borderRightStyle: "solid", borderBottomStyle: "solid",
                            borderWidth: "1pt", borderColor: "#404040", textAlign: "justify", padding: "5pt", marginLeft: "auto", marginRight: "auto" } }, "Prosimy spr\u00F3bowa\u0107 p\u00F3\u017Aniej"));
            }
            return React.createElement("div", { id: "mainPanel", style: { boxSizing: "border-box", marginBlock: '20pt' } },
                hdr,
                React.createElement("div", { id: "dashBrd", style: { display: 'grid', gridTemplateColumns: '30% 30% 30%', columnGap: '10pt', gridTemplateRows: '25% 25% 25% 25%', rowGap: '10pt', justifyItems: 'center', alignItems: 'center' } }, panele));
            /*<div style={{display:'flex', flexFlow:'wrap', float: 'none'}}>{panele}</div>  ;
             <div style={{display:'grid', gridTemplateColumns:'70% 30%', gridColumnGap: '10pt'}}>
            <ActionPanel target="/here" color="#FF0000" displText="Tekst panelu"></ActionPanel>
            <ActionPanel target="/nowhere" color="#00FF00" displText="Drugi panel"></ActionPanel>
            <ActionPanel target="/xor" color="#00FFFF" displText="Trzeci panel"></ActionPanel>
            </div>; */
        }
        else {
            return React.createElement(material_1.Alert, { severity: "error" }, "B\u0142\u0105d - brak danych logowania");
        }
    };
    return DashboardRoutes;
}(React.Component));
exports.default = DashboardRoutes;
