"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var axios = require("axios");
var React = require("react");
var MenuItem_1 = require("@mui/material/MenuItem");
var FormControl_1 = require("@mui/material/FormControl");
var Select_1 = require("@mui/material/Select");
var material_1 = require("@mui/material");
;
var EAIComboPrintedStatements = /** @class */ (function () {
    function EAIComboPrintedStatements(n) {
        this.year = n.year;
        this.generated = n.generated;
        this.dyscyplina1 = n.dyscyplina1;
        this.dyscyplina2 = n.dyscyplina2;
    }
    return EAIComboPrintedStatements;
}());
;
;
var FormPrintedStatementsList = /** @class */ (function (_super) {
    __extends(FormPrintedStatementsList, _super);
    function FormPrintedStatementsList(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { disciplinesList: null, errorMessage: null, showStatem: [] };
        return _this;
    }
    FormPrintedStatementsList.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a, _b, _c, _d, _e, _f, _g;
        var objectsEqual = function (o1, o2) { return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(function (p) { return o1[p] === o2[p]; }); };
        if (this.props.author_id != prevProps.author_id || (((_c = (_b = (_a = this === null || this === void 0 ? void 0 : this.props) === null || _a === void 0 ? void 0 : _a.disp) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) != ((_e = (_d = prevProps === null || prevProps === void 0 ? void 0 : prevProps.disp) === null || _d === void 0 ? void 0 : _d.length) !== null && _e !== void 0 ? _e : 0)) ||
            ((((_f = this === null || this === void 0 ? void 0 : this.props) === null || _f === void 0 ? void 0 : _f.disp) != null) && ((prevProps === null || prevProps === void 0 ? void 0 : prevProps.disp) != null) && !objectsEqual((_g = this === null || this === void 0 ? void 0 : this.props) === null || _g === void 0 ? void 0 : _g.disp, prevProps === null || prevProps === void 0 ? void 0 : prevProps.disp))) {
            this.recreateArrays(this.props.disp);
        }
    };
    FormPrintedStatementsList.prototype.componentDidMount = function () {
        var _this = this;
        axios.default.get("/api/v2/Disciplines").then(function (res) {
            _this.setState({ disciplinesList: res.data.disciplines, errorMessage: null });
            _this.recreateArrays(_this.props.disp);
        }).catch(function (err) {
            _this.setState({ disciplinesList: null, errorMessage: err.message });
        });
    };
    FormPrintedStatementsList.prototype.recreateArrays = function (src) {
        var _this = this;
        var dstArr = [];
        if ((src !== null) && (this.state.disciplinesList !== null)) {
            src.forEach(function (v) {
                var _a, _b, _c, _d;
                var nobj = new EAIComboPrintedStatements(v);
                var dsc_kod = (_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.disciplinesList.find(function (ff) { return ff.value == v.dyscyplina1; })) !== null && _b !== void 0 ? _b : undefined;
                if (dsc_kod !== undefined)
                    nobj.dyscyplina1_kod = dsc_kod.key.toString();
                else
                    nobj.dyscyplina1_kod = "null";
                var dsc_kod2 = (_d = (_c = _this.state) === null || _c === void 0 ? void 0 : _c.disciplinesList.find(function (ff) { return ff.value == v.dyscyplina2; })) !== null && _d !== void 0 ? _d : undefined;
                if (dsc_kod2 !== undefined)
                    nobj.dyscyplina2_kod = dsc_kod2.key.toString();
                else
                    nobj.dyscyplina2_kod = "null";
                dstArr.push(nobj);
            });
        }
        this.setState({ showStatem: dstArr });
    };
    FormPrintedStatementsList.prototype.obtainDisciplineCombo = function (rw, kolumna) {
        var _this = this;
        var optList = this.state.disciplinesList.map(function (el) { return React.createElement(MenuItem_1.default, { key: "mi_" + rw.year + "_" + kolumna + "_" + el.key, value: el.key }, el.value); });
        if (kolumna !== 0)
            optList.push(React.createElement(MenuItem_1.default, { key: "mi_" + rw.year + "_" + kolumna + "_empty", value: "null" }, "\u00A0"));
        var discId = kolumna == 0 ? rw.dyscyplina1_kod : rw.dyscyplina2_kod;
        var discipline_idX = discId !== null && discId !== void 0 ? discId : "null";
        return React.createElement(FormControl_1.default, { key: "FromCtrl" + rw.year },
            React.createElement(Select_1.default, { value: discipline_idX, style: { width: '100%', background: 'white' }, inputProps: { readOnly: false }, onChange: function (event, child) { return _this.handleChangeSelectDiscipline(_this, rw, kolumna, event.target.value); }, displayEmpty: true }, optList));
    };
    FormPrintedStatementsList.prototype.handleChangeSelectDiscipline = function (self, rw, kolumna, selVal) {
        var _a, _b;
        axios.default.post("/api/admin/ChangeDiscForStatem", { year: rw.year, author_id: (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.author_id) !== null && _b !== void 0 ? _b : 0, targetCol: kolumna, discipline_id: selVal }).then(function (res) {
            var dstArr = self.recreateArrays(res.data);
            /*let dstArr = self.state.showStatem;
            rw.generated = "";
            if(kolumna == 0)
            {
                rw.dyscyplina1_kod = selVal;
                if(selVal == "null")
                {
                    rw.dyscyplina1 = "";
                }
                else
                {
                    const dsc_nazwa = self.state?.disciplinesList.find(ff => { return ff.key.toString() == selVal; }) ?? undefined;
                    rw.dyscyplina1 = (dsc_nazwa !== undefined) ? dsc_nazwa.value : selVal;
                }
            }
            else
            {
                rw.dyscyplina2_kod = selVal;
                if(selVal == "null")
                {
                    rw.dyscyplina2 = "";
                }
                else
                {
                    const dsc_nazwa = self.state?.disciplinesList.find(ff => { return ff.key.toString() == selVal; }) ?? undefined;
                    rw.dyscyplina2 = (dsc_nazwa !== undefined) ? dsc_nazwa.value : selVal;
                }
            }
            self.setState({showStatem: dstArr, errorMessage: null}); */
        }).catch(function (err) {
            self.setState({ errorMessage: err.message });
        });
    };
    FormPrintedStatementsList.prototype.render = function () {
        var _this = this;
        var rows = this.state.showStatem.map(function (item, index) {
            return React.createElement("tr", { key: "statem" + index + "_" + _this.props.author_id },
                React.createElement("td", null, item.year),
                React.createElement("td", null, item.generated),
                React.createElement("td", null, _this.obtainDisciplineCombo(item, 0)),
                React.createElement("td", null, _this.obtainDisciplineCombo(item, 1)));
        });
        var msg_key;
        if (this.state.errorMessage !== null) {
            msg_key = React.createElement(React.Fragment, { key: "alrtKey" },
                React.createElement(material_1.Alert, { severity: "error" },
                    "B\u0142\u0105d aktualizacji dyscypliny w o\u015Bwiadczeniu: '",
                    this.state.errorMessage,
                    "'."));
        }
        else
            msg_key = React.createElement(React.Fragment, { key: "alrtKey" });
        return React.createElement("div", null,
            msg_key,
            React.createElement("table", { className: "tablechapters" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: 4, className: "articlescentertab articlestheader" }, "Wydrukowane o\u015Bwiadczenia")),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Rok"),
                        React.createElement("th", null, "Wygenerowano"),
                        React.createElement("th", null, "Dyscyplina 1"),
                        React.createElement("th", null, "Dyscyplina 2"))),
                React.createElement("tbody", null, rows)));
    };
    return FormPrintedStatementsList;
}(React.Component));
exports.default = FormPrintedStatementsList;
