"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var material_1 = require("@mui/material");
var material_2 = require("@mui/material");
var react_router_dom_1 = require("react-router-dom");
;
var FormPatentPrinter = /** @class */ (function (_super) {
    __extends(FormPatentPrinter, _super);
    function FormPatentPrinter(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { errorMessage: null, years: [], selectedYear: 0 };
        return _this;
    }
    FormPatentPrinter.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.props.author != prevProps.author) {
            this.recreateYearsToSelect();
        }
    };
    FormPatentPrinter.prototype.componentDidMount = function () {
        this.recreateYearsToSelect();
    };
    FormPatentPrinter.prototype.recreateYearsToSelect = function () {
        var _a;
        var ccc = (_a = this.props.patents) === null || _a === void 0 ? void 0 : _a.map(function (patent) { return patent.p_rok; });
        if (ccc == null)
            this.setState({ years: [] });
        else {
            this.setState({ years: ccc.filter(function (value, index, self) { return self.indexOf(value) === index; }) });
        }
    };
    FormPatentPrinter.prototype.updateCallAfterDelay = function (self, e) {
        if (self.state.selectedYear == 0) {
            alert("Nie wybrano roku");
            e.preventDefault();
            return;
        }
    };
    FormPatentPrinter.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement(material_2.Autocomplete, { id: "combo-box-select-patent-year", options: this.state.years, getOptionLabel: function (option) { return option.toString(); }, style: { width: 150 }, renderInput: function (params) { return React.createElement(material_1.TextField, __assign({}, params, { label: "Rok", variant: "outlined" })); }, onChange: function (event, newVal) { _this.setState({ selectedYear: parseInt(newVal) }); } }),
            React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/api/v2/PrintPatentStmt?yr=" + this.state.selectedYear + "&personid=" + this.props.author, color: "primary", variant: "outlined", target: "_blank", onClick: function (e) { return _this.updateCallAfterDelay(_this, e); } }, "Wydruk o\u015Bwiadczenia"),
            React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/api/v2/PrintPatentStmtAttachement?yr=" + this.state.selectedYear + "&personid=" + this.props.author, color: "primary", variant: "outlined", target: "_blank", onClick: function (e) { return _this.updateCallAfterDelay(_this, e); } }, "Za\u0142\u0105czniki"));
    };
    return FormPatentPrinter;
}(React.Component));
exports.default = FormPatentPrinter;
