"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var axios = require("axios");
;
;
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            hasError: false
        };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (_) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        axios.default.post('/api/v2/notifyUIError', { type: 'error', header: 'Błąd renderowania komponentu', message: 'Obiekt error: \n' +
                JSON.stringify(error) + '\nObiekt errorInfo: \n' + JSON.stringify(errorInfo) }).then(function (i) { }).catch(function (i) { });
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.hasError) { // Możesz wyrenderować dowolny interfejs zastępczy.      
            return React.createElement("div", null,
                "Wyst\u0105pi\u0142 problem z renderowaniem komponentu. ",
                React.createElement("a", { href: "/dashboard" }, "Mo\u017Cna powr\u00F3ci\u0107 do g\u0142\u00F3wnego panelu"),
                ".");
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
exports.default = ErrorBoundary;
