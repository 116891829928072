"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var axios = require("axios");
var material_1 = require("@mui/material");
var FormGroup_1 = require("@mui/material/FormGroup");
var FormControlLabel_1 = require("@mui/material/FormControlLabel");
var Checkbox_1 = require("@mui/material/Checkbox");
var Box_1 = require("@mui/material/Box");
var appaccounttype_1 = require("../apitypes/appaccounttype");
var FormAppAccounts_1 = require("./FormAppAccounts");
;
;
var FormAppAccountsAddEdit = /** @class */ (function (_super) {
    __extends(FormAppAccountsAddEdit, _super);
    function FormAppAccountsAddEdit(p) {
        var _this = _super.call(this, p) || this;
        var cmp = p.initValue !== "null";
        _this.state = { dispData: _this.zeroPersonData(), updateOldVersionToUnactive: cmp, disableOldVersionChbk: !cmp };
        return _this;
    }
    FormAppAccountsAddEdit.prototype.zeroPersonData = function () {
        var m = {
            row_num: 0,
            um_id_user: "",
            id_pracownik: 0,
            id_pracownik_list: "",
            um_polon_nazwisko: "",
            um_polon_imie: "",
            um_polon_drugie: "",
            um_stopien_tytul_wyliczany: "",
            um_current: true,
            orcid: "",
            polonuuid: "",
            userprincipalname: ""
        };
        return m;
    };
    FormAppAccountsAddEdit.prototype.componentDidMount = function () {
        var _this = this;
        if (this.props.initValue !== "null") {
            var row_num_data = parseInt(this.props.initValue);
            axios.default.post("/api/admin/AppAccount", { rownum: row_num_data })
                .then(function (res) {
                var t = _this.zeroPersonData(); /* {
                    row_num: 0,
                    um_id_user: "",
                    id_pracownik: 0,
                    id_pracownik_list: "",
                    um_polon_nazwisko: "",
                    um_polon_imie: "",
                    um_polon_drugie: "",
                    um_stopien_tytul_wyliczany: "",
                    um_current: true,
                    orcid: "",
                    polonuuid: "",
                    userprincipalname: ""
                }; */
                var s;
                if (res.data.length > 0) {
                    s = res.data[0];
                    if (s.row_num !== null)
                        t.row_num = s.row_num;
                    if (s.um_id_user !== null)
                        t.um_id_user = s.um_id_user;
                    if (s.id_pracownik !== null)
                        t.id_pracownik = s.id_pracownik;
                    if (s.id_pracownik_list !== null)
                        t.id_pracownik_list = s.id_pracownik_list;
                    if (s.um_polon_nazwisko !== null)
                        t.um_polon_nazwisko = s.um_polon_nazwisko;
                    if (s.um_polon_imie !== null)
                        t.um_polon_imie = s.um_polon_imie;
                    if (s.um_polon_drugie !== null)
                        t.um_polon_drugie = s.um_polon_drugie;
                    if (s.um_stopien_tytul_wyliczany !== null)
                        t.um_stopien_tytul_wyliczany = s.um_stopien_tytul_wyliczany;
                    if (s.um_current !== null)
                        t.um_current = s.um_current;
                    if (s.orcid !== null)
                        t.orcid = s.orcid;
                    if (s.polonuuid !== null)
                        t.polonuuid = s.polonuuid;
                    if (s.userprincipalname !== null)
                        t.userprincipalname = s.userprincipalname;
                }
                _this.setState({ dispData: t });
            })
                .catch(function (err) {
                alert(err.message);
                _this.setState({ dispData: null });
            });
        }
    };
    FormAppAccountsAddEdit.prototype.render = function () {
        var _this = this;
        var cmdAction;
        switch (this.props.mode) {
            case FormAppAccounts_1.EditButtons.ebAdd:
                cmdAction = "Nowe konto DN20";
                break;
            case FormAppAccounts_1.EditButtons.ebEdit:
                cmdAction = "Edycja konta DN20";
                break;
            case FormAppAccounts_1.EditButtonsAppAccounts.ebImportDN10:
                cmdAction = "Nowe konto DN20 z importu danych DN10";
                break;
            case FormAppAccounts_1.EditButtonsAppAccounts.ebImportPRz:
                cmdAction = "Nowe konto DN20 z importu danych UCI";
                break;
            case FormAppAccounts_1.EditButtonsAppAccounts.ebImportPolon:
                cmdAction = "Nowe konto DN20 z importu danych POLON";
                break;
            default:
                cmdAction = "Nieznana akcja";
                break;
        }
        var dspStyle = (this.props.mode != FormAppAccounts_1.EditButtons.ebEdit) ? { display: "inline" } : { display: "none" };
        return React.createElement("div", null,
            React.createElement("h2", null, cmdAction),
            React.createElement(Box_1.default, { display: "flex", flexDirection: "column", p: 1, m: 1, bgcolor: "background.paper", alignItems: "center", minWidth: '75%' },
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "inpNazwisko", label: "Nazwisko", value: this.state.dispData.um_polon_nazwisko, onChange: function (e) { return _this.updatePartComponent(_this, e); } })),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "inpImie", label: "Imi\u0119", value: this.state.dispData.um_polon_imie, onChange: function (e) { return _this.updatePartComponent(_this, e); } })),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "inpImieDrugie", label: "Drugie imi\u0119", value: this.state.dispData.um_polon_drugie, onChange: function (e) { return _this.updatePartComponent(_this, e); } })),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "inpStopien", label: "Stopie\u0144/tytu\u0142", value: this.state.dispData.um_stopien_tytul_wyliczany, onChange: function (e) { return _this.updatePartComponent(_this, e); } })),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "inpIdBibl", label: "Id pracownika w DN10", value: this.state.dispData.id_pracownik, onChange: function (e) { return _this.updatePartComponent(_this, e); } })),
                React.createElement("div", { className: "tinyFooter" }, "Zero oznacza brak identyfikatora"),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "inpIdBiblList", label: "Lista wszystkich Id pracownika w DN10", value: this.state.dispData.id_pracownik_list, onChange: function (e) { return _this.updatePartComponent(_this, e); } })),
                React.createElement("div", { className: "tinyFooter" }, "Wszystkie dotychczasowe identyfikatory DN 10 separowane tylko przecinkiem (bez spacji)"),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%', display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between" },
                    React.createElement(material_1.TextField, { style: { minWidth: '70%' }, id: "inpGUID", label: "Identyfikator wewn\u0119trzny DN20", value: this.state.dispData.um_id_user, required: true, onChange: function (e) { return _this.updatePartComponent(_this, e); } }),
                    React.createElement(material_1.Button, { variant: "contained", color: "primary", onClick: function (e) { return _this.generateId(_this); } }, "Generuj ID zast\u0119pczy")),
                React.createElement("div", { className: "tinyFooter" }, "Jednoznaczny z POLON (gdy nie ma numeru POLON to tymczasowo mo\u017Cna wygenerowa\u0107 zast\u0119pczy numer na bazie id pracownika z DN10)"),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "inpOrcid", label: "Numer ORCID", value: this.state.dispData.orcid, onChange: function (e) { return _this.updatePartComponent(_this, e); } })),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "inpPolon", label: "POLON UUID", value: this.state.dispData.polonuuid, onChange: function (e) { return _this.updatePartComponent(_this, e); } })),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "inpLogin", label: "Login PRz", value: this.state.dispData.userprincipalname, onChange: function (e) { return _this.updatePartComponent(_this, e); } })),
                React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
                    React.createElement(FormGroup_1.default, { row: true },
                        React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.dispData.um_current, onChange: function (e) { return _this.handleChangeActive(e, _this); }, name: "um_current" }), label: "Aktywny" }),
                        React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.updateOldVersionToUnactive, onChange: function (e) { return _this.handleChangeActiveInState(e, _this); }, name: "updateOldVersionToUnactive", disabled: this.state.disableOldVersionChbk }), label: "Deaktywuj uprzednio wybrany rekord", style: dspStyle }))),
                React.createElement(Box_1.default, { display: "flex", flexDirection: "row", p: 1, m: "auto", bgcolor: "background.paper", alignItems: "center", width: '25%', flexWrap: "nowrap", justifyContent: "space-between" },
                    React.createElement(material_1.Button, { variant: "contained", color: "primary", onClick: function (e) { return _this.commitWindow(_this); } }, "Zatwierd\u017A"),
                    React.createElement(material_1.Button, { variant: "contained", color: "primary", onClick: function (e) { return _this.cancelWindow(_this); } }, "Anuluj"))));
    };
    FormAppAccountsAddEdit.prototype.commitWindow = function (self) {
        if (this.props.mode == FormAppAccounts_1.EditButtons.ebEdit) {
            axios.default.post("/api/admin/AppAccountUpdate", { "account": self.state.dispData }).then(function (res) {
                alert('Zapisano');
                self.cancelWindow(self);
            }).catch(function (err) {
                alert("Błąd zapisu: " + err.message);
                self.cancelWindow(self);
            });
        }
        else {
            var payload = self.state.updateOldVersionToUnactive ? { "account": self.state.dispData, "disable_row_num": self.props.initValue } : { "account": self.state.dispData };
            axios.default.post("/api/admin/AppAccountInsert", payload).then(function (res) {
                alert('Zapisano');
                self.cancelWindow(self);
            }).catch(function (err) {
                alert("Błąd zapisu: " + err.message);
                self.cancelWindow(self);
            });
        }
    };
    FormAppAccountsAddEdit.prototype.cancelWindow = function (self) {
        self.props.history.goBack();
    };
    FormAppAccountsAddEdit.prototype.updatePartComponent = function (self, e) {
        var _a;
        var ww = (_a = this.state) === null || _a === void 0 ? void 0 : _a.dispData;
        switch (e.target.id) {
            case "inpNazwisko":
                ww.um_polon_nazwisko = e.target.value;
                break;
            case "inpImie":
                ww.um_polon_imie = e.target.value;
                break;
            case "inpImieDrugie":
                ww.um_polon_drugie = e.target.value;
                break;
            case "inpIdBibl":
                var mm = parseInt(e.target.value);
                if (isNaN(mm))
                    mm = 0;
                ww.id_pracownik = mm;
                break;
            case "inpIdBiblList":
                ww.id_pracownik_list = e.target.value;
                break;
            case "inpGUID":
                ww.um_id_user = e.target.value;
                break;
            case "inpStopien":
                ww.um_stopien_tytul_wyliczany = e.target.value;
                break;
            case "inpOrcid":
                ww.orcid = e.target.value;
                break;
            case "inpPolon":
                ww.polonuuid = e.target.value;
                break;
            case "inpLogin":
                ww.userprincipalname = e.target.value;
                break;
        }
        this.setState({ dispData: ww });
    };
    FormAppAccountsAddEdit.prototype.handleChangeActive = function (event, frm) {
        var _a;
        var ww = (_a = this.state) === null || _a === void 0 ? void 0 : _a.dispData;
        ww.um_current = event.target.checked;
        frm.setState({ dispData: ww });
    };
    FormAppAccountsAddEdit.prototype.handleChangeActiveInState = function (event, frm) {
        if (event.target.name == "updateOldVersionToUnactive")
            frm.setState({ updateOldVersionToUnactive: event.target.checked });
    };
    FormAppAccountsAddEdit.prototype.generateId = function (self) {
        var _a, _b, _c;
        var num = parseInt((_b = (_a = self.state.dispData) === null || _a === void 0 ? void 0 : _a.id_pracownik) === null || _b === void 0 ? void 0 : _b.toString());
        var ww = (_c = this.state) === null || _c === void 0 ? void 0 : _c.dispData;
        ww.um_id_user = appaccounttype_1.AppAccountGenereateReplacementUUID(num);
        this.setState({ dispData: ww });
    };
    return FormAppAccountsAddEdit;
}(React.Component));
exports.default = FormAppAccountsAddEdit;
