"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PotwierdzeniaPanel = void 0;
var React = require("react");
var axios = require("axios");
;
var PotwierdzeniaPanel = /** @class */ (function (_super) {
    __extends(PotwierdzeniaPanel, _super);
    function PotwierdzeniaPanel(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { data: null, errorMessage: null, dummyState: p.dummy };
        return _this;
    }
    PotwierdzeniaPanel.prototype.fetchData = function () {
        var _this = this;
        var cData = this.props.forUser;
        if (cData !== null) {
            axios.default.post("/api/admin/PRzInfoPanelConfirm", { user: cData }).then(function (res) {
                _this.setState({ data: res.data, errorMessage: null });
            }).catch(function (err) {
                _this.setState({ data: null, errorMessage: err.message });
            });
        }
        else {
            axios.default.get("/api/v2/PRzInfoPanelConfirm").then(function (res) {
                _this.setState({ data: res.data, errorMessage: null });
            }).catch(function (err) {
                _this.setState({ data: null, errorMessage: err.message });
            });
        }
    };
    PotwierdzeniaPanel.prototype.componentDidMount = function () {
        this.fetchData();
    };
    PotwierdzeniaPanel.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.props.dummy != prevProps.dummy || this.props.forUser != prevProps.forUser) {
            this.fetchData();
        }
    };
    PotwierdzeniaPanel.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f;
        if ((((_a = this === null || this === void 0 ? void 0 : this.state) === null || _a === void 0 ? void 0 : _a.data) === null) || (((_d = (_c = (_b = this === null || this === void 0 ? void 0 : this.state) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) == 0)) {
            return React.createElement("div", { id: "uniquePotwierdzeniaPanelId", style: { display: 'none' } });
        }
        var wiersze = (_f = (_e = this === null || this === void 0 ? void 0 : this.state) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.map(function (r) {
            var prow;
            switch (r.confirmstatus) {
                case 1:
                    prow = "potwierdzone";
                    break;
                case 0:
                    prow = "usterka";
                    break;
                case 2:
                    prow = "naprawione";
                    break;
                case 3:
                    prow = "zwrócone";
                    break;
                default:
                    prow = "nieznany";
                    break;
            }
            return (React.createElement("tr", { key: "problemsWithDataR" + r.row_num },
                React.createElement("td", null, r.confirmstamp),
                React.createElement("td", null, prow),
                React.createElement("td", null, r.notes)));
        });
        return (React.createElement("div", { id: "uniquePotwierdzeniaPanelId" },
            React.createElement("table", { className: "tablepolonaccept" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "polonerror_data_r1" },
                        React.createElement("td", { colSpan: 3, className: "centertab theader" }, "Potwierdzenia danych i zg\u0142oszenia b\u0142\u0119d\u00F3w")),
                    React.createElement("tr", { key: "problemsWithData" },
                        React.createElement("th", null, "Data zg\u0142oszenia"),
                        React.createElement("th", null, "Status"),
                        React.createElement("th", null, "Opis"))),
                React.createElement("tbody", null, wiersze))));
    };
    return PotwierdzeniaPanel;
}(React.Component));
exports.PotwierdzeniaPanel = PotwierdzeniaPanel;
