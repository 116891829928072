"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var axios = require("axios");
var React = require("react");
require("./PatentDisplayInfoStyles.scss");
var FormPatentPrinter_1 = require("./FormPatentPrinter");
;
var PatentDisplayInfo = /** @class */ (function (_super) {
    __extends(PatentDisplayInfo, _super);
    function PatentDisplayInfo(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { disciplinesList: null, errorMessage: null };
        return _this;
    }
    PatentDisplayInfo.prototype.componentDidMount = function () {
        var _this = this;
        axios.default.get("/api/v2/Disciplines").then(function (res) {
            _this.setState({ disciplinesList: res.data.disciplines, errorMessage: null });
        }).catch(function (err) {
            _this.setState({ disciplinesList: null, errorMessage: err.message });
        });
    };
    PatentDisplayInfo.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var wiersze = (_b = (_a = this === null || this === void 0 ? void 0 : this.props) === null || _a === void 0 ? void 0 : _a.disp) === null || _b === void 0 ? void 0 : _b.map(function (r, ridx) {
            var _a, _b, _c;
            var w = (_c = ((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.disciplinesList) === null || _b === void 0 ? void 0 : _b.find(function (ff) { return ff.key == r.es_discipline_id; }))) !== null && _c !== void 0 ? _c : undefined;
            var disc_nazwa = (w === undefined) ? r.es_discipline_id : w.value;
            var clsNameRow = ((ridx & 1) == 0) ? "patent_row_even" : "patent_row_odd";
            return (React.createElement(React.Fragment, { key: "patentyhdr" + r.id_publikacja },
                React.createElement("tr", { className: clsNameRow },
                    React.createElement("td", null, r.id_publikacja),
                    React.createElement("td", null, r.p_id_ocena),
                    React.createElement("td", null, r.la_o_imie),
                    React.createElement("td", null, r.la_o_nazwisko),
                    React.createElement("td", null, r.la_id_autor),
                    React.createElement("td", null, r.p_typ),
                    React.createElement("td", null, r.p_rok),
                    React.createElement("td", null, disc_nazwa),
                    React.createElement("td", null, r.data_mod),
                    React.createElement("td", null, r.statement_printed),
                    React.createElement("td", null, r.frozen ? "Tak" : "Nie")),
                React.createElement("tr", { className: clsNameRow },
                    React.createElement("th", null, "Tytu\u0142:"),
                    React.createElement("td", { colSpan: 10, className: "patent_title" }, r.p_tytul)),
                React.createElement("tr", { className: clsNameRow },
                    React.createElement("th", null, "Opis:"),
                    React.createElement("td", { colSpan: 10 }, r.o_nazwa))));
        });
        var combo = React.createElement("div", null);
        if (((_c = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _c !== void 0 ? _c : 0) == 0) {
            wiersze = [React.createElement("tr", { key: "patentyid_one_row" },
                    React.createElement("td", { colSpan: 11, className: "patentycentertab patentyred" }, "Brak informacji o patentach"))];
        }
        else {
            combo = React.createElement(FormPatentPrinter_1.default, { patents: this.props.disp, author: this.props.disp[0].la_id_autor });
        }
        return React.createElement("div", null,
            React.createElement("table", { className: "tablepatenty" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "patentysid_one_hdr" },
                        React.createElement("td", { colSpan: 11, className: "patentycentertab patentytheader" }, "Patenty / wzory przemys\u0142owe")),
                    React.createElement("tr", { key: "patentyid_two_row" },
                        React.createElement("th", null, "ID pub."),
                        React.createElement("th", null, "Kod ocn."),
                        React.createElement("th", null, "Imi\u0119"),
                        React.createElement("th", null, "Nazwisko"),
                        React.createElement("th", null, "ID autora"),
                        React.createElement("th", null, "Typ"),
                        React.createElement("th", null, "Rok"),
                        React.createElement("th", null, "Dyscyplina"),
                        React.createElement("th", null, "Data wyboru dysc."),
                        React.createElement("th", null, "Data o\u015Bwiadczenia"),
                        React.createElement("th", null, "Zablokowany"))),
                React.createElement("tbody", null, wiersze)),
            combo);
    };
    return PatentDisplayInfo;
}(React.Component));
exports.default = PatentDisplayInfo;
