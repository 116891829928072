"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./DanePolonTable.scss");
var DanePolonOswiadczenia = /** @class */ (function (_super) {
    __extends(DanePolonOswiadczenia, _super);
    function DanePolonOswiadczenia(p) {
        return _super.call(this, p) || this;
    }
    DanePolonOswiadczenia.prototype.render = function () {
        var _a, _b, _c;
        var wiersze = (_b = (_a = this === null || this === void 0 ? void 0 : this.props) === null || _a === void 0 ? void 0 : _a.disp) === null || _b === void 0 ? void 0 : _b.map(function (r) {
            return (React.createElement("tr", { key: r.polonemployeenstatementid },
                React.createElement("td", null, r.statementfrom),
                React.createElement("td", null, r.statementto),
                React.createElement("td", null, r.discipline1label),
                React.createElement("td", null, r.discipline1code),
                React.createElement("td", null, r.discipline2label),
                React.createElement("td", null, r.discipline2code)));
        });
        if (((_c = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _c !== void 0 ? _c : 0) == 0)
            wiersze = [React.createElement("tr", { key: "polonemployeenstatementid_dscy_rs" },
                    React.createElement("td", { colSpan: 6, className: "centertab red" }, "Brak o\u015Bwiadcze\u0144 o przynale\u017Cno\u015Bci do dyscypliny"))];
        return (React.createElement("div", null,
            React.createElement("table", { className: "tablepolonoswiad" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "polonemployeenstatementid_dscy_r1" },
                        React.createElement("td", { colSpan: 6, className: "centertab theader" }, "POLON - zestawienie o\u015Bwiadcze\u0144 o prowadzeniu bada\u0144")),
                    React.createElement("tr", { key: "polonemployeenstatementid_dscy_r2" },
                        React.createElement("th", null, "Data od"),
                        React.createElement("th", null, "Data do"),
                        React.createElement("th", null, "Dyscyplina 1"),
                        React.createElement("th", null, "Kod dyscypliny 1"),
                        React.createElement("th", null, "Dyscyplina 2"),
                        React.createElement("th", null, "Kod dyscypliny 2"))),
                React.createElement("tbody", null, wiersze))));
    };
    return DanePolonOswiadczenia;
}(React.Component));
exports.default = DanePolonOswiadczenia;
