"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdmPanelPRzInfoPanel = void 0;
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var axios = require("axios");
var DanePRz_1 = require("./DanePRz");
var DanePolon_1 = require("./DanePolon");
var DanePolonZatrudnienie_1 = require("./DanePolonZatrudnienie");
var DanePolonDyscypliny_1 = require("./DanePolonDyscypliny");
var DanePolonOswiadczenia_1 = require("./DanePolonOswiadczenia");
var DaneDoEwaulacji_1 = require("./DaneDoEwaulacji");
var material_1 = require("@mui/material");
var PotwierdzeniaPanel_1 = require("./PotwierdzeniaPanel");
var PersonFindSelect_1 = require("../forms/PersonFindSelect");
var FormSelectAvailEvalPeriods_1 = require("../forms/FormSelectAvailEvalPeriods");
var AdmPanelPRzInfoPanel = /** @class */ (function (_super) {
    __extends(AdmPanelPRzInfoPanel, _super);
    function AdmPanelPRzInfoPanel(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { data: null, errorMessage: null, selectedPerson: '', selectedPeriod: '' };
        return _this;
    }
    AdmPanelPRzInfoPanel.prototype.comboSelectedItemChanged = function (period) {
        this.setState({ data: null, errorMessage: null, selectedPeriod: period });
    };
    AdmPanelPRzInfoPanel.prototype.render = function () {
        var _this = this;
        var _a;
        var btm;
        if ((_a = this === null || this === void 0 ? void 0 : this.state) === null || _a === void 0 ? void 0 : _a.data) {
            btm = (React.createElement(React.Fragment, null,
                React.createElement(DanePRz_1.default, { disp: this.state.data.danePRz }),
                React.createElement(DanePolon_1.default, { disp: this.state.data.danePracownika }),
                React.createElement(DanePolonZatrudnienie_1.default, { disp: this.state.data.zatrudnienie }),
                React.createElement(DanePolonDyscypliny_1.default, { disp: this.state.data.dyscypliny }),
                React.createElement(DanePolonOswiadczenia_1.default, { disp: this.state.data.oswiad }),
                React.createElement(DaneDoEwaulacji_1.default, { disp: this.state.data.calculated }),
                React.createElement(PotwierdzeniaPanel_1.PotwierdzeniaPanel, { dummy: false, forUser: this.state.selectedPerson })));
        }
        else
            btm = React.createElement("div", null);
        return (React.createElement("div", null,
            React.createElement(FormSelectAvailEvalPeriods_1.FormSelectAvailEvalPeriods, __assign({ callback: function (frm, val) { return _this.comboSelectedItemChanged(val); } }, this.props)),
            React.createElement(PersonFindSelect_1.PersonFindSelect, { onSelectCallback: function (c, i) {
                    _this.handleDispClick(i.userprincipalname);
                } }),
            React.createElement("div", { style: { textAlign: 'center', textAlignLast: 'center' } },
                React.createElement(material_1.Button, { variant: "contained", color: "primary", onClick: function () { return _this.handleDispClick(_this.state.selectedPerson); } }, "Wy\u015Bwietl")),
            btm,
            React.createElement("div", { style: { textAlign: 'center', textAlignLast: 'center' } },
                React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika"))));
    };
    AdmPanelPRzInfoPanel.prototype.handleDispClick = function (p) {
        this.setState({ data: null, errorMessage: null, selectedPerson: p });
    };
    AdmPanelPRzInfoPanel.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if ((prevState.selectedPeriod != this.state.selectedPeriod) ||
            (prevState.selectedPerson != this.state.selectedPerson))
            this.refreshPanel();
    };
    AdmPanelPRzInfoPanel.prototype.refreshPanel = function () {
        var _this = this;
        var suffix;
        if (this.state.selectedPeriod != '')
            suffix = '/' + this.state.selectedPeriod;
        else
            suffix = '';
        if (this.state.selectedPerson == null)
            return;
        axios.default.post("/api/admin/PRzInfoPanel" + suffix, { user: this.state.selectedPerson }).then(function (res) {
            _this.setState({ data: res.data, errorMessage: null });
        }).catch(function (err) {
            _this.setState({ data: null, errorMessage: err.message });
        });
    };
    return AdmPanelPRzInfoPanel;
}(React.Component));
exports.AdmPanelPRzInfoPanel = AdmPanelPRzInfoPanel;
