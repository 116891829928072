"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.namePublication_p_typ = void 0;
var React = require("react");
var material_1 = require("@mui/material");
var _ = require("lodash");
require("./DanePolonTable.scss");
var EvaluationPanelResultSummary_1 = require("./EvaluationPanelResultSummary");
;
var EvaluationPanelResultPersonOsw = function (props) {
    var _a;
    var rows;
    if (((_a = props === null || props === void 0 ? void 0 : props.entries.length) !== null && _a !== void 0 ? _a : 0) == 0) {
        rows = React.createElement("tr", { key: "oswiadczenia_one_row" },
            React.createElement("td", { colSpan: 5, className: "articlescentertab articlesred" }, "Brak informacji o o\u015Bwiadczeniach"));
    }
    else {
        rows = props.entries.map(function (r) {
            var sloty = r.rok <= 2021 ? 0.8 * r.slot_w_dyscyplinie : r.slot_w_dyscyplinie;
            return (React.createElement("tr", { key: r.rok.toString() + "_" + r.dyscyplina_kod.toString() },
                React.createElement("td", null, r.rok),
                React.createElement("td", null, r.dyscyplina),
                React.createElement("td", null, r.wielkosc_etatu),
                React.createElement("td", null,
                    r.prc_dysc,
                    "\u00A0%"),
                React.createElement("td", null, sloty.toFixed(4))));
        });
    }
    return React.createElement(React.Fragment, { key: "oswiadczenia" },
        React.createElement("div", null,
            React.createElement("table", { className: "tableoswiadczenia" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "oswiadczenia_one_row" },
                        React.createElement("td", { colSpan: 5, className: "oswiadczeniacentertab oswiadczeniatheader" }, "O\u015Bwiadczenia")),
                    React.createElement("tr", { key: "oswiadczenia_two_row" },
                        React.createElement("th", null, "Rok"),
                        React.createElement("th", null, "Dyscyplina"),
                        React.createElement("th", null, "Wielko\u015B\u0107 etatu"),
                        React.createElement("th", null, "Udzia\u0142"),
                        React.createElement("th", null, "Slot w dysc."))),
                React.createElement("tbody", null, rows))));
};
var EvaluationPanelResultPersonSumySlotow = function (props) {
    var summary = props.dyscypliny.map(function (dyscyplina, i, a) {
        var cv = _.has(props.sumy_slotow_w_dyscyplinach, dyscyplina);
        return React.createElement(React.Fragment, { key: "podsumowanieDyscypliny" + dyscyplina },
            React.createElement("div", null,
                props.dyscypliny_nazwy[i],
                ": ",
                cv ? React.createElement("span", { className: "boldface" }, props.sumy_slotow_w_dyscyplinach[dyscyplina].toFixed(2)) : " - brak - ",
                ", na monografie: ",
                cv ? React.createElement("span", { className: "boldface" }, props.limit_mono_o[dyscyplina].toFixed(2)) : " - brak -"));
    });
    return React.createElement(React.Fragment, { key: "podsumowanieDyscypliny" },
        React.createElement(material_1.Typography, { variant: "h6" }, "Maksymalne sloty do wype\u0142nienia"),
        summary,
        React.createElement("div", { style: { marginBottom: '10pt' } }, "\u00A0"));
};
function namePublication_p_typ(val) {
    switch (val) {
        case 3: return "Monografia";
        case 4: return "Konferencja";
        case 6: return "Proj. badawcze";
        case 7: return "Patenty i wzory użytkowe";
        case 8: return "Wdrożenie";
        case 11: return "Członkostwo i pełnione funkcje";
        case 13: return "Certyfikat";
        case 19: return "Rozdział w monografii";
        case 20: return "Artykuł w czasopiśmie";
        case 21: return "Redakcja monografii";
        case 25: return "Artykuł konferencyjny w czasopiśmie";
        case 27: return "Referat";
        case 28: return "Ekspertyza";
        case 32: return "Projekt / Grant";
        case 42: return "Stopnie i tytuły naukowe - doktor";
        case 43: return "Stopnie i tytuły naukowe - profesor";
        case 44: return "Stopnie i tytuły naukowe - habilitant";
        default: return "Kod: " + val;
    }
}
exports.namePublication_p_typ = namePublication_p_typ;
var EvaluationPanelResultAnyPubTable = function (props) {
    var _a;
    var tabela_rows = props.data.map(function (qp) {
        return (React.createElement("tr", { key: qp.id_publikacja.toString() + "_" + qp.p_rok.toString() },
            React.createElement("td", null, qp.id_publikacja),
            React.createElement("td", null, namePublication_p_typ(qp.rp_typ)),
            React.createElement("td", null, qp.p_rok),
            React.createElement("td", null, qp.p_tytul),
            React.createElement("td", null, qp.authors),
            React.createElement("td", null, _.has(qp, 'w_nazwa') ? qp.w_nazwa : ""),
            React.createElement("td", null, qp.dpp_punkty.toFixed(4))));
    });
    if (((_a = tabela_rows === null || tabela_rows === void 0 ? void 0 : tabela_rows.length) !== null && _a !== void 0 ? _a : 0) == 0)
        tabela_rows = [React.createElement("tr", { key: "publikacja_bdesc_one_row" },
                React.createElement("td", { colSpan: 12, className: "centertab red" }, props.noPublicationsMessage))];
    return React.createElement("div", null,
        React.createElement("table", { className: "tablearticlesinsummary" },
            React.createElement("thead", null,
                React.createElement("tr", { key: "articlesid_one_hdr" },
                    React.createElement("td", { colSpan: 12, className: "articlescentertab articlestheader" }, props.tableHeader)),
                React.createElement("tr", { key: "articlesid_two_row" },
                    React.createElement("th", null, "ID pub."),
                    React.createElement("th", null, "Typ"),
                    React.createElement("th", null, "Rok"),
                    React.createElement("th", null, "Tytu\u0142"),
                    React.createElement("th", null, "Autorzy"),
                    React.createElement("th", null, "Czasopismo"),
                    React.createElement("th", null, "Punkty ca\u0142kowite"))),
            React.createElement("tbody", null, tabela_rows)));
};
var EvaluationPanelResultPerson = /** @class */ (function (_super) {
    __extends(EvaluationPanelResultPerson, _super);
    function EvaluationPanelResultPerson(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { errorMessage: null, disciplinesList: null };
        return _this;
    }
    /*componentDidMount() {
        axios.get("/api/v2/Disciplines").then(res => {
            this.setState({disciplinesList : res.data.disciplines, errorMessage: null});
        }).catch(err => {
            this.setState({disciplinesList:null, errorMessage: err.message});
        });
    }*/
    EvaluationPanelResultPerson.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        var pubsprint = (_b = (_a = this === null || this === void 0 ? void 0 : this.props) === null || _a === void 0 ? void 0 : _a.shares) === null || _b === void 0 ? void 0 : _b.dyscypliny.map(function (dysckod, i) {
            if (_this.props.limitForOneDiscipline !== null) {
                if (_this.props.limitForOneDiscipline !== dysckod)
                    return React.createElement(React.Fragment, { key: "dyscyplina_pubs" + dysckod });
            }
            if (_.has(_this.props.shares.sumy_slotow_w_dyscyplinach, dysckod)) {
                if (_.isUndefined(_this.props.shares.evaluation_result))
                    return React.createElement(React.Fragment, { key: "dyscyplina_pubs" + dysckod });
                return React.createElement(React.Fragment, { key: "dyscyplina_pubs" + dysckod },
                    React.createElement(EvaluationPanelResultSummary_1.EvaluationPanelResultSummary, { setres: _this.props.shares.evaluation_result[dysckod], setname: _this.props.shares.dyscypliny_nazwy[i], max_slotow_w_dyscyplinie: _this.props.shares.sumy_slotow_w_dyscyplinach[dysckod], summarytable: _this.props.shares.stats_result[dysckod], mode: EvaluationPanelResultSummary_1.EvaluationPanelResultSummaryMode.eprsmExtraColumnIncludeInCust, currentPersonId: _this.props.currentPersonId }));
            }
            else {
                return React.createElement(React.Fragment, { key: "dyscyplina_pubs" + dysckod });
            }
        });
        var unassigned;
        if (_.has((_e = (_d = (_c = this === null || this === void 0 ? void 0 : this.props) === null || _c === void 0 ? void 0 : _c.shares) === null || _d === void 0 ? void 0 : _d.dorobek) === null || _e === void 0 ? void 0 : _e.dyscypliny_kod_publikacje, "-1")) {
            unassigned = React.createElement(React.Fragment, { key: "dyscyplina_unassigned" },
                React.createElement(material_1.Typography, { variant: "h6", style: { color: 'red' } }, "brak przypisania do dyscyplin"),
                React.createElement(EvaluationPanelResultAnyPubTable, { data: this.props.shares.dorobek.dyscypliny_kod_publikacje[-1], noPublicationsMessage: 'Brak informacji o nieprzypisanych publikacjach do dyscypliny', tableHeader: 'Publikacje bez dyscypliny' }));
        }
        else {
            unassigned = React.createElement(React.Fragment, { key: "dyscyplina_unassigned" });
        }
        var notrelated;
        if (_.has((_h = (_g = (_f = this === null || this === void 0 ? void 0 : this.props) === null || _f === void 0 ? void 0 : _f.shares) === null || _g === void 0 ? void 0 : _g.dorobek) === null || _h === void 0 ? void 0 : _h.dyscypliny_kod_publikacje, "0")) {
            notrelated = React.createElement(React.Fragment, { key: "dyscyplina_notrelated" },
                React.createElement(material_1.Typography, { variant: "h6", style: { color: 'red' } }, "brak zwi\u0105zku z badaniami"),
                React.createElement(EvaluationPanelResultAnyPubTable, { data: this.props.shares.dorobek.dyscypliny_kod_publikacje[0], noPublicationsMessage: 'Brak informacji o publikacjach bez zwi\u0105zku z dyscyplinami', tableHeader: 'Publikacje bez zwi\u0105zku z dysycplinami' }));
        }
        else {
            notrelated = React.createElement(React.Fragment, { key: "dyscyplina_notrelated" });
        }
        var rejpubyear;
        if (((_o = (_m = (_l = (_k = (_j = this === null || this === void 0 ? void 0 : this.props) === null || _j === void 0 ? void 0 : _j.shares) === null || _k === void 0 ? void 0 : _k.dorobek) === null || _l === void 0 ? void 0 : _l.rejected_pub_year) === null || _m === void 0 ? void 0 : _m.length) !== null && _o !== void 0 ? _o : 0) > 0) {
            rejpubyear = React.createElement(React.Fragment, { key: "dyscyplina_rejected_year" },
                React.createElement(material_1.Typography, { variant: "h6", style: { color: 'red' } }, "odrzucone z powodu braku zaliczania do N w roku publikacji"),
                React.createElement(EvaluationPanelResultAnyPubTable, { data: this.props.shares.dorobek.rejected_pub_year, noPublicationsMessage: 'Brak informacji o odrzuconych publikacjach w roku bez N', tableHeader: 'Publikacje odrzucone z powodu braku zaliczania do N' }));
        }
        else {
            rejpubyear = React.createElement(React.Fragment, { key: "dyscyplina_rejected_year" });
        }
        var rejpublevel;
        if (((_t = (_s = (_r = (_q = (_p = this === null || this === void 0 ? void 0 : this.props) === null || _p === void 0 ? void 0 : _p.shares) === null || _q === void 0 ? void 0 : _q.dorobek) === null || _r === void 0 ? void 0 : _r.rejected_pub_level) === null || _s === void 0 ? void 0 : _s.length) !== null && _t !== void 0 ? _t : 0) > 0) {
            rejpublevel = React.createElement(React.Fragment, { key: "dyscyplina_rejected_level" },
                React.createElement(material_1.Typography, { variant: "h6", style: { color: '#959504' } }, "oczekuj\u0105ce na zatwierdzenie przez bibliotek\u0119"),
                React.createElement(EvaluationPanelResultAnyPubTable, { data: this.props.shares.dorobek.rejected_pub_level, noPublicationsMessage: 'Brak informacji o publikacjach oczekuj\u0105cych na zatwierdzenie przez bibliotek\u0119', tableHeader: 'Publikacje oczekuj\u0105ce na zatwierdzenie przez bibliotek\u0119' }));
        }
        else {
            rejpublevel = React.createElement(React.Fragment, { key: "dyscyplina_rejected_level" });
        }
        return React.createElement("div", null,
            React.createElement(EvaluationPanelResultPersonOsw, { entries: (_v = (_u = this === null || this === void 0 ? void 0 : this.props) === null || _u === void 0 ? void 0 : _u.shares) === null || _v === void 0 ? void 0 : _v.osw }),
            React.createElement(EvaluationPanelResultPersonSumySlotow, { sumy_slotow_w_dyscyplinach: this.props.shares.sumy_slotow_w_dyscyplinach, limit_mono_o: this.props.shares.limit_mono_o, dyscypliny: this.props.shares.dyscypliny, dyscypliny_nazwy: this.props.shares.dyscypliny_nazwy }),
            React.createElement(material_1.Typography, { variant: "h5" }, "Zakwalifikowane publikacje do ewaluacji"),
            pubsprint,
            unassigned,
            notrelated,
            rejpubyear,
            rejpublevel);
    };
    return EvaluationPanelResultPerson;
}(React.Component));
exports.default = EvaluationPanelResultPerson;
