"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormDeleteAppAccountDialog = void 0;
var React = require("react");
var axios = require("axios");
var material_1 = require("@mui/material");
;
;
var FormDeleteAppAccountDialog = /** @class */ (function (_super) {
    __extends(FormDeleteAppAccountDialog, _super);
    function FormDeleteAppAccountDialog(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { dispData: {
                row_num: 0,
                um_id_user: "",
                id_pracownik: 0,
                id_pracownik_list: "",
                um_polon_nazwisko: "",
                um_polon_imie: "",
                um_polon_drugie: "",
                um_stopien_tytul_wyliczany: "",
                um_current: true,
                orcid: "",
                polonuuid: "",
                userprincipalname: ""
            } };
        return _this;
    }
    FormDeleteAppAccountDialog.prototype.componentDidMount = function () {
        var _this = this;
        if (this.props.actionValue !== "null") {
            var row_num_data = parseInt(this.props.actionValue);
            axios.default.post("/api/admin/AppAccount", { rownum: row_num_data })
                .then(function (res) {
                var t; /* = {
                    row_num: 0,
                    um_id_user: "",
                    id_pracownik: 0,
                    id_pracownik_list: "",
                    um_polon_nazwisko: "",
                    um_polon_imie: "",
                    um_polon_drugie: "",
                    um_stopien_tytul_wyliczany: "",
                    um_current: true,
                    orcid: "",
                    polonuuid: "",
                    userprincipalname: ""
                };
                let s : FormAppAccountsPersonSelectData = res.data;
                debugger;
                if(s.row_num !== null)
                    t.row_num = s.row_num;
                if(s.um_id_user !== null)
                    t.um_id_user = s.um_id_user;
                if(s.id_pracownik !== null)
                    t.id_pracownik = s.id_pracownik;
                if(s.id_pracownik_list !== null)
                    t.id_pracownik_list = s.id_pracownik_list;
                if(s.um_polon_nazwisko !== null)
                    t.um_polon_nazwisko = s.um_polon_nazwisko;
                if(s.um_polon_imie !== null)
                    t.um_polon_imie = s.um_polon_imie;
                if(s.um_polon_drugie !== null)
                    t.um_polon_drugie = s.um_polon_drugie;
                if(s.um_stopien_tytul_wyliczany !== null)
                    t.um_stopien_tytul_wyliczany = s.um_stopien_tytul_wyliczany;
                if(s.um_current !== null)
                    t.um_current = s.um_current;
                if(s.orcid !== null)
                    t.orcid = s.orcid;
                if(s.polonuuid !== null)
                    t.polonuuid = s.polonuuid;
                if(s.userprincipalname !== null)
                    t.userprincipalname = s.userprincipalname;*/
                if (res.data.length > 0)
                    t = res.data[0];
                else
                    t = null;
                _this.setState({ dispData: t });
            })
                .catch(function (err) {
                alert(err.message);
                _this.setState({ dispData: null });
            });
        }
    };
    FormDeleteAppAccountDialog.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("h2", null, "Usuwanie konta"),
            React.createElement("table", null,
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Wiersz"),
                        React.createElement("td", null, this.state.dispData.row_num)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Identyfikator wewn."),
                        React.createElement("td", null, this.state.dispData.um_id_user)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "ID pracownika DN 10"),
                        React.createElement("td", null, this.state.dispData.id_pracownik)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Lista ID pracownika DN 10"),
                        React.createElement("td", null, this.state.dispData.id_pracownik_list)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Nazwisko"),
                        React.createElement("td", null, this.state.dispData.um_polon_nazwisko)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Imi\u0119"),
                        React.createElement("td", null, this.state.dispData.um_polon_imie)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Drugie imi\u0119"),
                        React.createElement("td", null, this.state.dispData.um_polon_drugie)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Stopie\u0144 / tytu\u0142"),
                        React.createElement("td", null, this.state.dispData.um_stopien_tytul_wyliczany)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Aktualne dane"),
                        React.createElement("td", null, this.state.dispData.um_current ? "Tak" : "Nie")),
                    React.createElement("tr", null,
                        React.createElement("th", null, "ORCID"),
                        React.createElement("td", null, this.state.dispData.orcid)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "POLON UUID"),
                        React.createElement("td", null, this.state.dispData.polonuuid)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Login"),
                        React.createElement("td", null, this.state.dispData.userprincipalname)))),
            React.createElement(material_1.Button, { key: "btnOK", color: "secondary", variant: "outlined", onClick: function (e) { return _this.proceedDelete(_this, e); } }, "Potwierd\u017A usuni\u0119cie"),
            React.createElement(material_1.Button, { key: "btnCancel", color: "secondary", variant: "outlined", onClick: function (e) { return _this.cancelWindow(_this); } }, "Anuluj"));
    };
    FormDeleteAppAccountDialog.prototype.proceedDelete = function (self, e) {
        axios.default.post("/api/admin/AppAccountDelete", { "account": this.state.dispData }).then(function (res) {
            alert('Usunięto');
            self.cancelWindow(self);
        }).catch(function (err) {
            alert("Błąd usuwania: " + err.message);
            self.cancelWindow(self);
        });
    };
    FormDeleteAppAccountDialog.prototype.cancelWindow = function (self) {
        self.props.history.goBack();
    };
    return FormDeleteAppAccountDialog;
}(React.Component));
exports.FormDeleteAppAccountDialog = FormDeleteAppAccountDialog;
