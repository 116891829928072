"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var material_1 = require("@mui/material");
var Autocomplete_1 = require("@mui/material/Autocomplete");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
;
var FormPublicationPrinter = /** @class */ (function (_super) {
    __extends(FormPublicationPrinter, _super);
    function FormPublicationPrinter(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { errorMessage: null, years: [], selectedYear: 0 };
        return _this;
    }
    FormPublicationPrinter.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.props.author_id != prevProps.author_id) {
            this.recreateYearsToSelect();
        }
    };
    FormPublicationPrinter.prototype.componentDidMount = function () {
        this.recreateYearsToSelect();
    };
    FormPublicationPrinter.prototype.recreateYearsToSelect = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var aaa = (_c = (_b = (_a = this.props.pubcont) === null || _a === void 0 ? void 0 : _a.publications) === null || _b === void 0 ? void 0 : _b.articles) === null || _c === void 0 ? void 0 : _c.map(function (pub) { return pub.p_rok; });
        var bbb = (_f = (_e = (_d = this.props.pubcont) === null || _d === void 0 ? void 0 : _d.publications) === null || _e === void 0 ? void 0 : _e.monographs) === null || _f === void 0 ? void 0 : _f.map(function (pub) { return pub.p_rok; });
        var ccc = (_j = (_h = (_g = this.props.pubcont) === null || _g === void 0 ? void 0 : _g.publications) === null || _h === void 0 ? void 0 : _h.redactions) === null || _j === void 0 ? void 0 : _j.map(function (pub) { return pub.p_rok; });
        var ddd = (_m = (_l = (_k = this.props.pubcont) === null || _k === void 0 ? void 0 : _k.publications) === null || _l === void 0 ? void 0 : _l.chapters) === null || _m === void 0 ? void 0 : _m.map(function (pub) { return pub.p_rok; });
        var comb;
        if (aaa == null)
            comb = [];
        else
            comb = aaa;
        if (bbb != null)
            comb = _.concat(comb, bbb);
        if (ccc != null)
            comb = _.concat(comb, ccc);
        if (ddd != null)
            comb = _.concat(comb, ddd);
        this.setState({ years: comb.filter(function (value, index, self) { return self.indexOf(value) === index; }) });
    };
    FormPublicationPrinter.prototype.updateCallAfterDelay = function (self, e) {
        if (self.state.selectedYear == 0) {
            alert("Nie wybrano roku");
            e.preventDefault();
            return;
        }
        if (self.props.callbackfunction != null) {
            setTimeout(self.props.callbackfunction, 2000);
        }
    };
    FormPublicationPrinter.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement(Autocomplete_1.default, { id: "combo-box-select-patent-year", options: this.state.years, getOptionLabel: function (option) { return option.toString(); }, style: { width: 150 }, renderInput: function (params) { return React.createElement(material_1.TextField, __assign({}, params, { label: "Rok", variant: "outlined" })); }, onChange: function (event, newVal) { _this.setState({ selectedYear: parseInt(newVal) }); } }),
            React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/api/v2/PrintPublicationStmt?yr=" + this.state.selectedYear + "&personid=" + this.props.author_id, color: "primary", variant: "outlined", target: "_blank", onClick: function (e) { return _this.updateCallAfterDelay(_this, e); } }, "Wydruk o\u015Bwiadczenia"),
            React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/api/v2/PrintPublicationStmtAttachement?yr=" + this.state.selectedYear + "&personid=" + this.props.author_id, color: "primary", variant: "outlined", target: "_blank", onClick: function (e) { return _this.updateCallAfterDelay(_this, e); } }, "Za\u0142\u0105czniki"));
    };
    return FormPublicationPrinter;
}(React.Component));
exports.default = FormPublicationPrinter;
