"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonFindSelect = void 0;
var React = require("react");
var axios = require("axios");
var material_1 = require("@mui/material");
var FormLabel_1 = require("@mui/material/FormLabel");
var FormControl_1 = require("@mui/material/FormControl");
var FormGroup_1 = require("@mui/material/FormGroup");
var FormControlLabel_1 = require("@mui/material/FormControlLabel");
var Checkbox_1 = require("@mui/material/Checkbox");
var Box_1 = require("@mui/material/Box");
var Radio_1 = require("@mui/material/Radio");
;
;
var PersonFindSelect = /** @class */ (function (_super) {
    __extends(PersonFindSelect, _super);
    function PersonFindSelect(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { inpString: "", szuknazw: true, szukimie: true, szukpoczatek: true, szukjednostka: true, szukjednkod: false, apidata: null, selectedPerson: null, szukUPN: true };
        return _this;
    }
    PersonFindSelect.prototype.handleSelectClick = function (event, self) {
        self.handleSelectClickWorker(event.target.value, self);
    };
    PersonFindSelect.prototype.handleSelectClickWorker = function (w, self) {
        self.setState({ selectedPerson: w });
        if (w != null) {
            var person = self.state.apidata.find(function (el) { return el.employeenumber === w; });
            self.props.onSelectCallback(self, person);
        }
    };
    PersonFindSelect.prototype.handleChange = function (event, frm) {
        var _a;
        var ns = __assign(__assign({}, frm.state), (_a = {}, _a[event.target.name] = event.target.checked, _a));
        frm.setState(ns);
    };
    ;
    PersonFindSelect.prototype.handleSearchClick = function (self) {
        var _this = this;
        axios.default.post("/api/admin/PersonSearch", { dtext: self.state.inpString, flgnazw: self.state.szuknazw, flgimie: self.state.szukimie, flgUPN: self.state.szukUPN, flgjednostka: self.state.szukjednostka,
            flgjednkod: self.state.szukjednkod, flgstart: self.state.szukpoczatek }).then(function (res) {
            _this.setState({ apidata: res.data });
        }).catch(function (err) {
            alert(err.message);
            _this.setState({ apidata: null });
        });
    };
    PersonFindSelect.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var result;
        if (((_b = (_a = this.state.apidata) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) == 0)
            result = React.createElement("div", null);
        else {
            var rows = (_c = this.state.apidata) === null || _c === void 0 ? void 0 : _c.map(function (r, idx) {
                return React.createElement("tr", { key: r.employeenumber },
                    React.createElement("td", null,
                        React.createElement(Radio_1.default, { checked: _this.state.selectedPerson === r.employeenumber, value: r.employeenumber, name: "radio-list-persons", onChange: function (e) { return _this.handleSelectClick(e, _this); } })),
                    React.createElement("td", null, idx + 1),
                    React.createElement("td", null, r.imie),
                    React.createElement("td", null, r.nazwisko),
                    React.createElement("td", null, r.userprincipalname),
                    React.createElement("td", null, r.wyswietlane),
                    React.createElement("td", null, r.stopien),
                    React.createElement("td", null, r.polonuuid),
                    React.createElement("td", null, r.department),
                    React.createElement("td", null, r.jednostka_kod));
            });
            result = React.createElement("table", { className: "tablepolondysc" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "polonemployeedisciplineid_dscy_r1" },
                        React.createElement("td", { colSpan: 10, className: "centertab theader" }, "CAS \u2013 lista pracownik\u00F3w")),
                    React.createElement("tr", { key: "polonemployeedisciplineid_dscy_r2" },
                        React.createElement("th", null, "Wybierz"),
                        React.createElement("th", null, "Lp"),
                        React.createElement("th", null, "Imie"),
                        React.createElement("th", null, "Nazwisko"),
                        React.createElement("th", null, "Login"),
                        React.createElement("th", null, "Wy\u015Bwietlane"),
                        React.createElement("th", null, "Stopie\u0144"),
                        React.createElement("th", null, "Polon UUID"),
                        React.createElement("th", null, "Jednostka"),
                        React.createElement("th", null, "Kod jednostki"))),
                React.createElement("tbody", null, rows));
        }
        return React.createElement("div", null,
            React.createElement("form", { noValidate: true, autoComplete: "off", style: { textAlign: 'center', textAlignLast: 'center' }, onSubmit: function (e) {
                    e.preventDefault();
                    _this.handleSearchClick(_this);
                } },
                React.createElement(Box_1.default, { display: "flex", flexDirection: "column", p: 1, m: 1, bgcolor: "background.paper" },
                    React.createElement(Box_1.default, { p: 1 },
                        React.createElement(material_1.TextField, { style: { minWidth: '30%' }, id: "inpUserIdName", label: "Szukaj aktywnych pracownik\u00F3w", value: this.state.inpString, onChange: function (e) { return _this.setState({ inpString: e.target.value }); } })),
                    React.createElement(Box_1.default, { p: 2 },
                        React.createElement(material_1.Button, { variant: "contained", color: "primary", onClick: function () { return _this.handleSearchClick(_this); } }, "Szukaj")),
                    React.createElement(Box_1.default, { p: 2 },
                        React.createElement(FormControl_1.default, { component: "fieldset" },
                            React.createElement(FormLabel_1.default, { component: "legend" }, "Zakres przeszukiwania"),
                            React.createElement(FormGroup_1.default, { row: true },
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szuknazw, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szuknazw" }), label: "Nazwisko" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukimie, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukimie" }), label: "Imi\u0119" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukUPN, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukUPN" }), label: "Login" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukpoczatek, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukpoczatek" }), label: this.state.szukpoczatek ? "Rozpoczyna" : "Zawiera" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukjednostka, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukjednostka" }), label: "Nazwa jednostki" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukjednkod, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukjednkod" }), label: "Kod jednostki" })))))),
            result);
    };
    return PersonFindSelect;
}(React.Component));
exports.PersonFindSelect = PersonFindSelect;
