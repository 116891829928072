"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSelectAvailEvalPeriods = void 0;
var React = require("react");
var axios = require("axios");
var material_1 = require("@mui/material");
var MenuItem_1 = require("@mui/material/MenuItem");
var FormControl_1 = require("@mui/material/FormControl");
var FormLabel_1 = require("@mui/material/FormLabel");
var Select_1 = require("@mui/material/Select");
;
;
var FormSelectAvailEvalPeriods = /** @class */ (function (_super) {
    __extends(FormSelectAvailEvalPeriods, _super);
    function FormSelectAvailEvalPeriods(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { data: null, errorMessage: null, selectedEval: '' };
        return _this;
    }
    FormSelectAvailEvalPeriods.prototype.componentDidMount = function () {
        var _this = this;
        axios.default.get("/api/v2/evalAvailPeriods").then(function (res) {
            _this.setState({ data: res.data, errorMessage: null });
        }).catch(function (err) {
            _this.setState({ data: null, errorMessage: err.message });
        });
    };
    FormSelectAvailEvalPeriods.prototype.render = function () {
        var _this = this;
        var n;
        if (this.state.data === null) {
            if (this.state.errorMessage === null)
                n = null;
            else
                n = React.createElement(material_1.Alert, { severity: "error" }, this.state.errorMessage);
        }
        else {
            var optList = this.state.data.map(function (el) {
                return React.createElement(MenuItem_1.default, { key: "miep_" + el.row_id, value: el.row_id },
                    React.createElement("span", { style: { fontWeight: el.display_default ? "bolder" : "normal" } },
                        el.year_from,
                        " \u2013 ",
                        el.year_to));
            });
            n = React.createElement(FormControl_1.default, { key: "FromCtrlPeriod" },
                React.createElement(FormLabel_1.default, { component: "legend" }, "Wybierz okres do ewaluacji:"),
                React.createElement(Select_1.default, { value: this.state.selectedEval, style: { width: '100%', background: 'white' }, onChange: function (event, child) { _this.setState({ selectedEval: event.target.value }); _this.props.callback(child, event.target.value.toString()); }, displayEmpty: true }, optList));
        }
        return n;
    };
    return FormSelectAvailEvalPeriods;
}(React.Component));
exports.FormSelectAvailEvalPeriods = FormSelectAvailEvalPeriods;
