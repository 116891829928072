"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var axios = require("axios");
var material_1 = require("@mui/material");
var FormLabel_1 = require("@mui/material/FormLabel");
var FormGroup_1 = require("@mui/material/FormGroup");
var FormControlLabel_1 = require("@mui/material/FormControlLabel");
var Radio_1 = require("@mui/material/Radio");
var FormAppAccounts_1 = require("../forms/FormAppAccounts");
var PersonFindSelect_1 = require("../forms/PersonFindSelect");
var material_2 = require("@mui/material");
var PatentDisplayInfo_1 = require("../forms/PatentDisplayInfo");
var FormSelectAvailEvalPeriods_1 = require("../forms/FormSelectAvailEvalPeriods");
var AdmPatentyPanel = /** @class */ (function (_super) {
    __extends(AdmPatentyPanel, _super);
    function AdmPatentyPanel(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { patents: null, error: null, personSource: 1, selectedPeriod: '' };
        return _this;
    }
    AdmPatentyPanel.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var msg = (((_a = this.state) === null || _a === void 0 ? void 0 : _a.error) === null ? "" : React.createElement(material_2.Alert, { severity: "error" },
            "B\u0142\u0105d pobierania patent\u00F3w: ", (_b = this.state) === null || _b === void 0 ? void 0 :
            _b.error));
        var szukaj;
        switch (this.state.personSource) {
            case 0:
                szukaj = React.createElement(FormAppAccounts_1.default, __assign({ onSelectCallback: function (comp, item) { return _this.fillDataForUserWithAccount(item, _this); }, editButtons: [FormAppAccounts_1.EditButtons.ebNone] }, this.props));
                break;
            case 1:
                szukaj = React.createElement(PersonFindSelect_1.PersonFindSelect, { onSelectCallback: function (comp, item) { return _this.fillDataForUser(item, _this); } });
                break;
        }
        return React.createElement("div", null,
            React.createElement(FormGroup_1.default, { row: true },
                React.createElement(FormLabel_1.default, null, "\u0179r\u00F3d\u0142o:"),
                React.createElement(FormControlLabel_1.default, { value: 0, control: React.createElement(Radio_1.default, { checked: this.state.personSource === 0, value: 0, name: "radio-list-sourceData", onChange: function (e) { return _this.handleSelectSourceClick(e, _this); } }), label: "Konto aplikacji" }),
                React.createElement(FormControlLabel_1.default, { value: 1, control: React.createElement(Radio_1.default, { checked: this.state.personSource === 1, value: 1, name: "radio-list-sourceData", onChange: function (e) { return _this.handleSelectSourceClick(e, _this); } }), label: "Baza pracownik\u00F3w" })),
            React.createElement(FormSelectAvailEvalPeriods_1.FormSelectAvailEvalPeriods, __assign({ callback: function (a, b) { return _this.handleSelectPeriodClick(b, _this); } }, this.props)),
            szukaj,
            msg,
            React.createElement(PatentDisplayInfo_1.default, { disp: this.state.patents }),
            React.createElement("div", { style: { textAlign: 'center', textAlignLast: 'center' } },
                React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika")));
    };
    AdmPatentyPanel.prototype.fillDataForUser = function (person, self) {
        var _this = this;
        axios.default.post("/api/admin/PatentsGetUserPN", { userpnpname: person.userprincipalname, period: self.state.selectedPeriod }).then(function (res) {
            _this.setState(res.data);
        }).catch(function (err) {
            _this.setState({ patents: [], error: err.message });
        });
    };
    AdmPatentyPanel.prototype.fillDataForUserWithAccount = function (person, self) {
        var _this = this;
        axios.default.post("/api/admin/PatentsGetUserId", { ue_mapping_rowid: person.row_num, period: self.state.selectedPeriod }).then(function (res) {
            _this.setState(res.data);
        }).catch(function (err) {
            _this.setState({ patents: null, error: err.message });
        });
    };
    AdmPatentyPanel.prototype.handleSelectSourceClick = function (event, self) {
        self.setState({ personSource: parseInt(event.target.value), patents: null });
    };
    AdmPatentyPanel.prototype.handleSelectPeriodClick = function (v, self) {
        self.setState({ patents: null, error: null, selectedPeriod: v });
    };
    return AdmPatentyPanel;
}(React.Component));
exports.default = AdmPatentyPanel;
