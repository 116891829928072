"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var axios = require("axios");
var React = require("react");
var FormPublicationPrinter_1 = require("./FormPublicationPrinter");
var FormPrintedStatementsList_1 = require("./FormPrintedStatementsList");
var DaneDoEwaulacji_1 = require("../danePanel/DaneDoEwaulacji");
require("./PublicationDisplayInfoStyles.scss");
var _ = require("lodash");
var MenuItem_1 = require("@mui/material/MenuItem");
var FormControl_1 = require("@mui/material/FormControl");
var Select_1 = require("@mui/material/Select");
var material_1 = require("@mui/material");
var PublicationDisplayInfo = /** @class */ (function (_super) {
    __extends(PublicationDisplayInfo, _super);
    function PublicationDisplayInfo(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { disciplinesList: null, errorMessage: null, postedUpdates: [] };
        return _this;
    }
    PublicationDisplayInfo.prototype.componentDidMount = function () {
        var _this = this;
        axios.default.get("/api/v2/Disciplines").then(function (res) {
            _this.setState({ disciplinesList: res.data.disciplines, errorMessage: null });
        }).catch(function (err) {
            _this.setState({ disciplinesList: null, errorMessage: err.message });
        });
    };
    PublicationDisplayInfo.prototype.obtainDisciplineCombo = function (article_id, discipline_id, disabled) {
        var _this = this;
        var options = this.state.disciplinesList.filter(function (di, idx, ar) { return (ar[idx].key === 0) || ((_this._userDisciplines.indexOf(di.value) != -1)); });
        var optList = options.map(function (el) { return React.createElement(MenuItem_1.default, { key: "mi_" + article_id + "_" + el.key, value: el.key }, el.value); });
        if (optList.length > 0)
            optList.push(React.createElement(MenuItem_1.default, { key: "mi_" + article_id + "_empty", value: "null" }, "\u00A0"));
        var fnd_idx = this.state.postedUpdates.findIndex(function (fv) { return fv.article_id == article_id; });
        if (fnd_idx != -1)
            discipline_id = this.state.postedUpdates[fnd_idx].discipline_id;
        var discipline_idX = discipline_id !== null && discipline_id !== void 0 ? discipline_id : "null";
        var warnMsg = null;
        if ((discipline_idX != "null") && (discipline_idX != 0) && (options.findIndex(function (p) { return p.key == discipline_idX; }) == -1)) {
            var dk = this.state.disciplinesList.find(function (di) { return di.key == discipline_id; });
            discipline_idX = "";
            warnMsg = React.createElement(material_1.Alert, { severity: 'warning' },
                "Publikacja jest przypisana do dyscypliny ",
                (dk !== undefined) ? dk.value : discipline_idX,
                ", kt\u00F3rej nie ma w dyscyplinach autora");
        }
        return React.createElement(FormControl_1.default, { key: "FromCtrl" + article_id },
            React.createElement(Select_1.default, { value: discipline_idX, style: { width: '100%', background: 'white' }, inputProps: { readOnly: disabled }, onChange: function (event, child) { return _this.handleChangeSelectDiscipline(_this, article_id, event.target.value.toString()); }, displayEmpty: true }, optList),
            warnMsg);
    };
    PublicationDisplayInfo.prototype.handleChangeSelectDiscipline = function (self, publ_id, selVal) {
        var _this = this;
        var _a, _b, _c;
        axios.default.post("/api/admin/ChangeDiscForPub", { article_id: publ_id, author_id: (_c = (_b = (_a = this.props.disp) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id_pracownik) !== null && _c !== void 0 ? _c : 0, discipline_id: selVal }).then(function (res) {
            var w = res.data;
            if (w.discipline_id == "null")
                w.discipline_id = null;
            var fnd_idx = _this.state.postedUpdates.findIndex(function (fv) { return fv.article_id == w.article_id; });
            var newData;
            if (fnd_idx == -1)
                newData = _.concat(_this.state.postedUpdates, w);
            else {
                newData = _this.state.postedUpdates;
                newData[fnd_idx].discipline_id = w.discipline_id;
            }
            _this.setState({ postedUpdates: newData });
        }).catch(function (err) {
            _this.setState({ errorMessage: err.message });
        });
    };
    PublicationDisplayInfo.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.props.disp != prevProps.disp || this.props.changeDiscMode != prevProps.changeDiscMode) {
            this.setState({ postedUpdates: [] });
        }
    };
    PublicationDisplayInfo.prototype.render_articles = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_b = (_a = this.props.disp) === null || _a === void 0 ? void 0 : _a.publications) === null || _b === void 0 ? void 0 : _b.articles) === null) {
            return React.createElement(React.Fragment, { key: "renderArticles" });
        }
        else {
            var wiersze = (_f = (_e = (_d = (_c = this === null || this === void 0 ? void 0 : this.props) === null || _c === void 0 ? void 0 : _c.disp) === null || _d === void 0 ? void 0 : _d.publications) === null || _e === void 0 ? void 0 : _e.articles) === null || _f === void 0 ? void 0 : _f.map(function (r, ridx) {
                var _a, _b, _c;
                var w = (_c = ((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.disciplinesList) === null || _b === void 0 ? void 0 : _b.find(function (ff) { return ff.key == r.es_discipline_id; }))) !== null && _c !== void 0 ? _c : undefined;
                var disc_nazwa = _this.props.changeDiscMode ? _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, false) : ((w === undefined) ? r.es_discipline_id : w.value);
                var clsNameRow = ((ridx & 1) == 0) ? "article_row_even" : "article_row_odd";
                return (React.createElement(React.Fragment, { key: "articlehdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, r.w_nazwa),
                        React.createElement("td", null, r.w_issn),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"))));
            });
            if (((_g = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _g !== void 0 ? _g : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "articlesid_one_row" },
                        React.createElement("td", { colSpan: 10, className: "articlescentertab articlesred" }, "Brak informacji o publikacjach"))];
            }
            return React.createElement(React.Fragment, { key: "renderArticles" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tablearticles" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "articlesid_one_hdr" },
                                React.createElement("td", { colSpan: 10, className: "articlescentertab articlestheader" }, "Artyku\u0142y")),
                            React.createElement("tr", { key: "articlesid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Tytu\u0142"),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "Czasopismo/tom pokonf."),
                                React.createElement("th", null, "ISSN/ISBN"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"))),
                        React.createElement("tbody", null, wiersze))));
        }
    };
    PublicationDisplayInfo.prototype.render_monographs = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_b = (_a = this.props.disp) === null || _a === void 0 ? void 0 : _a.publications) === null || _b === void 0 ? void 0 : _b.monographs) === null) {
            return React.createElement(React.Fragment, { key: "renderMonographs" });
        }
        else {
            var wiersze = (_f = (_e = (_d = (_c = this === null || this === void 0 ? void 0 : this.props) === null || _c === void 0 ? void 0 : _c.disp) === null || _d === void 0 ? void 0 : _d.publications) === null || _e === void 0 ? void 0 : _e.monographs) === null || _f === void 0 ? void 0 : _f.map(function (r, ridx) {
                var _a, _b, _c;
                var w = (_c = ((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.disciplinesList) === null || _b === void 0 ? void 0 : _b.find(function (ff) { return ff.key == r.es_discipline_id; }))) !== null && _c !== void 0 ? _c : undefined;
                var disc_nazwa = _this.props.changeDiscMode ? _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, false) : ((w === undefined) ? r.es_discipline_id : w.value);
                var clsNameRow = ((ridx & 1) == 0) ? "article_row_even" : "article_row_odd";
                return (React.createElement(React.Fragment, { key: "monographhdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, r.p_doi),
                        React.createElement("td", null, r.p_isbn),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"))));
            });
            if (((_g = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _g !== void 0 ? _g : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "monographsid_one_row" },
                        React.createElement("td", { colSpan: 10, className: "articlescentertab articlesred" }, "Brak informacji o monografiach"))];
            }
            return React.createElement(React.Fragment, { key: "renderMonographs" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tablemonographs" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "monographsid_one_hdr" },
                                React.createElement("td", { colSpan: 10, className: "articlescentertab articlestheader" }, "Monografie")),
                            React.createElement("tr", { key: "monographsid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Tytu\u0142"),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "DOI"),
                                React.createElement("th", null, "ISBN"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"))),
                        React.createElement("tbody", null, wiersze))));
        }
    };
    PublicationDisplayInfo.prototype.render_redactions = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_b = (_a = this.props.disp) === null || _a === void 0 ? void 0 : _a.publications) === null || _b === void 0 ? void 0 : _b.redactions) === null) {
            return React.createElement(React.Fragment, { key: "renderRedactions" });
        }
        else {
            var wiersze = (_f = (_e = (_d = (_c = this === null || this === void 0 ? void 0 : this.props) === null || _c === void 0 ? void 0 : _c.disp) === null || _d === void 0 ? void 0 : _d.publications) === null || _e === void 0 ? void 0 : _e.redactions) === null || _f === void 0 ? void 0 : _f.map(function (r, ridx) {
                var _a, _b, _c;
                var w = (_c = ((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.disciplinesList) === null || _b === void 0 ? void 0 : _b.find(function (ff) { return ff.key == r.es_discipline_id; }))) !== null && _c !== void 0 ? _c : undefined;
                var disc_nazwa = _this.props.changeDiscMode ? _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, false) : ((w === undefined) ? r.es_discipline_id : w.value);
                var clsNameRow = ((ridx & 1) == 0) ? "article_row_even" : "article_row_odd";
                return (React.createElement(React.Fragment, { key: "redactionhdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, r.p_doi),
                        React.createElement("td", null, r.p_isbn),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"))));
            });
            if (((_g = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _g !== void 0 ? _g : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "redactionsid_one_row" },
                        React.createElement("td", { colSpan: 10, className: "articlescentertab articlesred" }, "Brak informacji o redakcjach monografii"))];
            }
            return React.createElement(React.Fragment, { key: "renderRedactions" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tableredactions" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "redactionsid_one_hdr" },
                                React.createElement("td", { colSpan: 10, className: "articlescentertab articlestheader" }, "Redakcje monografii")),
                            React.createElement("tr", { key: "redactionsid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Tytu\u0142"),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "DOI"),
                                React.createElement("th", null, "ISBN"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"))),
                        React.createElement("tbody", null, wiersze))));
        }
    };
    PublicationDisplayInfo.prototype.render_chapters = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_b = (_a = this.props.disp) === null || _a === void 0 ? void 0 : _a.publications) === null || _b === void 0 ? void 0 : _b.chapters) === null) {
            return React.createElement(React.Fragment, { key: "renderChapters" });
        }
        else {
            var wiersze = (_f = (_e = (_d = (_c = this === null || this === void 0 ? void 0 : this.props) === null || _c === void 0 ? void 0 : _c.disp) === null || _d === void 0 ? void 0 : _d.publications) === null || _e === void 0 ? void 0 : _e.chapters) === null || _f === void 0 ? void 0 : _f.map(function (r, ridx) {
                var _a, _b, _c;
                var w = (_c = ((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.disciplinesList) === null || _b === void 0 ? void 0 : _b.find(function (ff) { return ff.key == r.es_discipline_id; }))) !== null && _c !== void 0 ? _c : undefined;
                var disc_nazwa = _this.props.changeDiscMode ? _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, false) : ((w === undefined) ? r.es_discipline_id : w.value);
                var clsNameRow = ((ridx & 1) == 0) ? "article_row_even" : "article_row_odd";
                return (React.createElement(React.Fragment, { key: "chapterhdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.p_tytul_rozdzialu),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, r.p_doi),
                        React.createElement("td", null, r.p_isbn),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"))));
            });
            if (((_g = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _g !== void 0 ? _g : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "chapterssid_one_row" },
                        React.createElement("td", { colSpan: 11, className: "articlescentertab articlesred" }, "Brak informacji o rozdzia\u0142ach w monografiach"))];
            }
            return React.createElement(React.Fragment, { key: "renderChapters" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tablechapters" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "chaptersid_one_hdr" },
                                React.createElement("td", { colSpan: 11, className: "articlescentertab articlestheader" }, "Rozdzia\u0142y w monografiach")),
                            React.createElement("tr", { key: "chapterssid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Tytu\u0142 mon."),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "Tytu\u0142 roz."),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "DOI"),
                                React.createElement("th", null, "ISBN"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"))),
                        React.createElement("tbody", null, wiersze))));
        }
    };
    PublicationDisplayInfo.prototype.render_patents = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        if (((_b = (_a = this.props.disp) === null || _a === void 0 ? void 0 : _a.publications) === null || _b === void 0 ? void 0 : _b.patents) === null) {
            return React.createElement(React.Fragment, { key: "renderPatents" });
        }
        else {
            var wiersze = (_e = (_d = (_c = this === null || this === void 0 ? void 0 : this.props) === null || _c === void 0 ? void 0 : _c.disp) === null || _d === void 0 ? void 0 : _d.publications) === null || _e === void 0 ? void 0 : _e.patents.map(function (r, ridx) {
                var _a, _b, _c;
                var w = (_c = ((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.disciplinesList) === null || _b === void 0 ? void 0 : _b.find(function (ff) { return ff.key == r.es_discipline_id; }))) !== null && _c !== void 0 ? _c : undefined;
                var disc_nazwa = _this.props.changeDiscMode ? _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, false) : ((w === undefined) ? r.es_discipline_id : w.value);
                var clsNameRow = ((ridx & 1) == 0) ? "patent_row_even" : "patent_row_odd";
                return (React.createElement(React.Fragment, { key: "patentyhdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, _.has(r, 'p_id_ocena') ? r.p_id_ocena : ""),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.la_id_autor),
                        React.createElement("td", null, r.p_typ),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"))));
            });
            if (((_f = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _f !== void 0 ? _f : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "patentyid_one_row" },
                        React.createElement("td", { colSpan: 11, className: "patentycentertab patentyred" }, "Brak informacji o patentach"))];
            }
            return React.createElement(React.Fragment, { key: "renderPatents" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tablepatenty" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "patentysid_one_hdr" },
                                React.createElement("td", { colSpan: 11, className: "patentycentertab patentytheader" }, "Patenty / wzory przemys\u0142owe")),
                            React.createElement("tr", { key: "patentyid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Kod ocn."),
                                React.createElement("th", null, "Tytu\u0142"),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "ID autora"),
                                React.createElement("th", null, "Typ"),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"))),
                        React.createElement("tbody", null, wiersze))));
        }
    };
    PublicationDisplayInfo.prototype.refreshList = function (self) {
        if (self.props.callbackFunc != null) {
            self.props.callbackFunc();
        }
    };
    PublicationDisplayInfo.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        var combo;
        var aaa = (_d = (_c = (_b = (_a = this.props.disp) === null || _a === void 0 ? void 0 : _a.publications) === null || _b === void 0 ? void 0 : _b.articles) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0;
        var bbb = (_h = (_g = (_f = (_e = this.props.disp) === null || _e === void 0 ? void 0 : _e.publications) === null || _f === void 0 ? void 0 : _f.monographs) === null || _g === void 0 ? void 0 : _g.length) !== null && _h !== void 0 ? _h : 0;
        var ccc = (_m = (_l = (_k = (_j = this.props.disp) === null || _j === void 0 ? void 0 : _j.publications) === null || _k === void 0 ? void 0 : _k.redactions) === null || _l === void 0 ? void 0 : _l.length) !== null && _m !== void 0 ? _m : 0;
        var ddd = (_r = (_q = (_p = (_o = this.props.disp) === null || _o === void 0 ? void 0 : _o.publications) === null || _p === void 0 ? void 0 : _p.chapters) === null || _q === void 0 ? void 0 : _q.length) !== null && _r !== void 0 ? _r : 0;
        if (this.props.changeDiscMode || ((aaa + bbb + ccc + ddd) == 0)) {
            combo = React.createElement(React.Fragment, { key: "comboForYear" });
        }
        else {
            combo = React.createElement(React.Fragment, { key: "comboForYear" },
                React.createElement(FormPublicationPrinter_1.default, { pubcont: this.props.disp, author_id: (_u = (_t = (_s = this.props.disp) === null || _s === void 0 ? void 0 : _s.user) === null || _t === void 0 ? void 0 : _t.id_pracownik) !== null && _u !== void 0 ? _u : 0, callbackfunction: function () { return _this.refreshList(_this); } }),
                React.createElement(DaneDoEwaulacji_1.default, { disp: this.props.disp.disciplines }),
                React.createElement(FormPrintedStatementsList_1.default, { disp: this.props.disp.prn_stmts, author_id: (_x = (_w = (_v = this.props.disp) === null || _v === void 0 ? void 0 : _v.user) === null || _w === void 0 ? void 0 : _w.id_pracownik) !== null && _x !== void 0 ? _x : 0 }));
        }
        var aadyscypliny = (_z = (_y = this.props.disp) === null || _y === void 0 ? void 0 : _y.disciplines) === null || _z === void 0 ? void 0 : _z.map(function (r) { return r.dane.map(function (q) { return q.dyscyplina; }); });
        this._userDisciplines = _.flatten(aadyscypliny);
        return [this.render_articles(), this.render_monographs(), this.render_redactions(), this.render_chapters(), this.render_patents(), combo];
    };
    return PublicationDisplayInfo;
}(React.Component));
exports.default = PublicationDisplayInfo;
