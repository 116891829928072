"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var axios = require("axios");
var _ = require("lodash");
var MenuItem_1 = require("@mui/material/MenuItem");
var FormControl_1 = require("@mui/material/FormControl");
var Select_1 = require("@mui/material/Select");
var Tooltip_1 = require("@mui/material/Tooltip");
var PublicationComplianceChecker_1 = require("../forms/PublicationComplianceChecker");
var ExplainPublicationReject_1 = require("./ExplainPublicationReject");
var material_1 = require("@mui/material");
var EvaluationPanelResultPerson_1 = require("./EvaluationPanelResultPerson");
;
var PublicationPanelByPubKind = /** @class */ (function (_super) {
    __extends(PublicationPanelByPubKind, _super);
    function PublicationPanelByPubKind(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { disciplinesList: null, errorMessage: null, displayData: null };
        return _this;
    }
    PublicationPanelByPubKind.prototype.componentDidMount = function () {
        var _this = this;
        axios.default.get("/api/v2/Disciplines").then(function (res) {
            var _a;
            _this.setState({ disciplinesList: res.data.disciplines, errorMessage: null, displayData: (_a = _this.props) === null || _a === void 0 ? void 0 : _a.presentData });
        }).catch(function (err) {
            _this.setState({ disciplinesList: null, errorMessage: err.message });
        });
    };
    PublicationPanelByPubKind.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        var objectsEqual = function (o1, o2) {
            if (o1 === undefined && o2 === undefined)
                return true;
            if ((o1 !== undefined) && (o2 === undefined))
                return false;
            if ((o1 === undefined) && (o2 !== undefined))
                return false;
            if (o1 === null && o2 === null)
                return true;
            if (o1 === null || o2 === null)
                return false;
            return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(function (p) { return o1[p] === o2[p]; });
        };
        if (!objectsEqual((_b = (_a = this === null || this === void 0 ? void 0 : this.props) === null || _a === void 0 ? void 0 : _a.presentData) === null || _b === void 0 ? void 0 : _b.articles, (_c = prevProps === null || prevProps === void 0 ? void 0 : prevProps.presentData) === null || _c === void 0 ? void 0 : _c.articles) || !objectsEqual((_e = (_d = this === null || this === void 0 ? void 0 : this.props) === null || _d === void 0 ? void 0 : _d.presentData) === null || _e === void 0 ? void 0 : _e.chapters, (_f = prevProps === null || prevProps === void 0 ? void 0 : prevProps.presentData) === null || _f === void 0 ? void 0 : _f.chapters) ||
            !objectsEqual((_h = (_g = this === null || this === void 0 ? void 0 : this.props) === null || _g === void 0 ? void 0 : _g.presentData) === null || _h === void 0 ? void 0 : _h.monographs, (_j = prevProps === null || prevProps === void 0 ? void 0 : prevProps.presentData) === null || _j === void 0 ? void 0 : _j.monographs) || !objectsEqual((_l = (_k = this === null || this === void 0 ? void 0 : this.props) === null || _k === void 0 ? void 0 : _k.presentData) === null || _l === void 0 ? void 0 : _l.redactions, (_m = prevProps === null || prevProps === void 0 ? void 0 : prevProps.presentData) === null || _m === void 0 ? void 0 : _m.redactions) ||
            !objectsEqual((_p = (_o = this === null || this === void 0 ? void 0 : this.props) === null || _o === void 0 ? void 0 : _o.presentData) === null || _p === void 0 ? void 0 : _p.patents, (_q = prevProps === null || prevProps === void 0 ? void 0 : prevProps.presentData) === null || _q === void 0 ? void 0 : _q.patents) || !objectsEqual((_s = (_r = this === null || this === void 0 ? void 0 : this.props) === null || _r === void 0 ? void 0 : _r.presentData) === null || _s === void 0 ? void 0 : _s.rejected_pub_level, (_t = prevProps === null || prevProps === void 0 ? void 0 : prevProps.presentData) === null || _t === void 0 ? void 0 : _t.rejected_pub_level) ||
            !objectsEqual((_v = (_u = this === null || this === void 0 ? void 0 : this.props) === null || _u === void 0 ? void 0 : _u.presentData) === null || _v === void 0 ? void 0 : _v.rejected_pub_year, (_w = prevProps === null || prevProps === void 0 ? void 0 : prevProps.presentData) === null || _w === void 0 ? void 0 : _w.rejected_pub_year) || (this.props.userId != prevProps.userId)) {
            this.setState({ displayData: (_x = this.props) === null || _x === void 0 ? void 0 : _x.presentData });
        }
    };
    PublicationPanelByPubKind.prototype.render = function () {
        var _this = this;
        var emInfo = this.props.messageData == null ? [] : this.props.messageData.map(function (s, i) { return React.createElement(material_1.Alert, { key: "msge" + i, severity: s.startsWith("[Błąd]") ? "error" : "warning" }, s); });
        if (this.state.errorMessage != null) {
            emInfo.push(React.createElement(material_1.Alert, { key: "msgd", severity: 'error' }, this.state.errorMessage));
        }
        this._userDisciplines = this.props.userDisciplines.map(function (v) { var _a, _b; return (_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.disciplinesList) === null || _b === void 0 ? void 0 : _b.find(function (di) { return di.key == v; }); });
        return [emInfo, this.render_articles(), this.render_monographs(), this.render_redactions(), this.render_chapters(), this.render_patents(), this.render_rejected_pub_level() /*, this.render_rejected_pub_year() */];
    };
    PublicationPanelByPubKind.prototype.obtainDisciplineCombo = function (article_id, discipline_id, disabled) {
        var _this = this;
        var options = _.clone(this._userDisciplines);
        options.push(this.state.disciplinesList.find(function (di, idx, ar) { return (ar[idx].key === 0); }));
        var optList = options.map(function (el) { return React.createElement(MenuItem_1.default, { key: "mi_" + article_id + "_" + el.key, value: el.key }, el.value); });
        if (optList.length > 0)
            optList.push(React.createElement(MenuItem_1.default, { key: "mi_" + article_id + "_empty", value: "null" }, "\u00A0"));
        var discipline_idX = discipline_id !== null && discipline_id !== void 0 ? discipline_id : "null";
        return React.createElement(FormControl_1.default, { key: "FromCtrl" + article_id },
            React.createElement(Select_1.default, { value: discipline_idX, style: { width: '100%', background: 'white' }, inputProps: { readOnly: disabled }, onChange: function (event, child) { return _this.handleChangeSelectDiscipline(_this, article_id, event.target.value.toString()); }, displayEmpty: true }, optList));
    };
    PublicationPanelByPubKind.prototype.render_articles = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.displayData) === null || _b === void 0 ? void 0 : _b.articles) === null) {
            return React.createElement(React.Fragment, { key: "renderArticles" });
        }
        else {
            var wiersze = (_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.displayData) === null || _d === void 0 ? void 0 : _d.articles) === null || _e === void 0 ? void 0 : _e.map(function (ro, ridx) {
                var r = ro;
                var disc_nazwa = _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, r.frozen);
                var clsNameRow = ((ridx & 1) == 0) ? "article_row_even" : "article_row_odd";
                var acceptReason;
                if (r.es_discipline_id) {
                    acceptReason = r.accepted_to_eval ? "Tak" : "Nie: R" + String(r.reject_reason).padStart(2, '0');
                }
                else {
                    acceptReason = "";
                }
                return (React.createElement(React.Fragment, { key: "articlehdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, r.w_nazwa),
                        React.createElement("td", null, r.w_issn),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"),
                        React.createElement("td", null, acceptReason),
                        React.createElement("td", null,
                            React.createElement(PublicationComplianceChecker_1.default, { key: "checker" + r.id_publikacja, idPub: r.id_publikacja, author: r.la_id_autor, discipline: r.es_discipline_id })))));
            });
            if (((_f = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _f !== void 0 ? _f : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "articlesid_one_row" },
                        React.createElement("td", { colSpan: 12, className: "articlescentertab articlesred" }, "Brak informacji o publikacjach"))];
            }
            return React.createElement(React.Fragment, { key: "renderArticles" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tablearticles" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "articlesid_one_hdr" },
                                React.createElement("td", { colSpan: 12, className: "articlescentertab articlestheader" }, "Artyku\u0142y")),
                            React.createElement("tr", { key: "articlesid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Tytu\u0142"),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "Czasopismo/tom pokonf."),
                                React.createElement("th", null, "ISSN/ISBN"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"),
                                React.createElement("th", null, "Akceptacja"),
                                React.createElement("th", null,
                                    React.createElement(Tooltip_1.default, { title: "Wy\u015Bwietla zgodno\u015B\u0107 wybranej dyscypliny z dyscyplinami czasopisma" },
                                        React.createElement("span", null, "Zgodno\u015B\u0107"))))),
                        React.createElement("tbody", null, wiersze),
                        React.createElement("tfoot", null,
                            React.createElement("tr", null,
                                React.createElement("td", { colSpan: 12 },
                                    React.createElement(ExplainPublicationReject_1.default, { rowsnum: (_g = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _g !== void 0 ? _g : 0 })))))));
        }
    };
    PublicationPanelByPubKind.prototype.render_monographs = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.displayData) === null || _b === void 0 ? void 0 : _b.monographs) === null) {
            return React.createElement(React.Fragment, { key: "renderMonographs" });
        }
        else {
            var wiersze = (_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.displayData) === null || _d === void 0 ? void 0 : _d.monographs) === null || _e === void 0 ? void 0 : _e.map(function (ro, ridx) {
                var r = ro;
                var disc_nazwa = _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, r.frozen);
                var clsNameRow = ((ridx & 1) == 0) ? "article_row_even" : "article_row_odd";
                var acceptReason;
                if (r.es_discipline_id) {
                    acceptReason = r.accepted_to_eval ? "Tak" : "Nie: R" + String(r.reject_reason).padStart(2, '0');
                }
                else {
                    acceptReason = "";
                }
                return (React.createElement(React.Fragment, { key: "monographhdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, r.p_doi),
                        React.createElement("td", null, r.p_isbn),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"),
                        React.createElement("td", null, acceptReason))));
            });
            if (((_f = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _f !== void 0 ? _f : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "monographsid_one_row" },
                        React.createElement("td", { colSpan: 11, className: "articlescentertab articlesred" }, "Brak informacji o monografiach"))];
            }
            return React.createElement(React.Fragment, { key: "renderMonographs" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tablemonographs" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "monographsid_one_hdr" },
                                React.createElement("td", { colSpan: 11, className: "articlescentertab articlestheader" }, "Monografie")),
                            React.createElement("tr", { key: "monographsid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Tytu\u0142"),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "DOI"),
                                React.createElement("th", null, "ISBN"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"),
                                React.createElement("th", null, "Akceptacja"))),
                        React.createElement("tbody", null, wiersze),
                        React.createElement("tfoot", null,
                            React.createElement("tr", null,
                                React.createElement("td", { colSpan: 11 },
                                    React.createElement(ExplainPublicationReject_1.default, { rowsnum: (_g = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _g !== void 0 ? _g : 0 })))))));
        }
    };
    PublicationPanelByPubKind.prototype.render_redactions = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.displayData) === null || _b === void 0 ? void 0 : _b.redactions) === null) {
            return React.createElement(React.Fragment, { key: "renderRedactions" });
        }
        else {
            var wiersze = (_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.displayData) === null || _d === void 0 ? void 0 : _d.redactions) === null || _e === void 0 ? void 0 : _e.map(function (ro, ridx) {
                var r = ro;
                var disc_nazwa = _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, r.frozen);
                var clsNameRow = ((ridx & 1) == 0) ? "article_row_even" : "article_row_odd";
                var acceptReason;
                if (r.es_discipline_id) {
                    acceptReason = r.accepted_to_eval ? "Tak" : "Nie: R" + String(r.reject_reason).padStart(2, '0');
                }
                else {
                    acceptReason = "";
                }
                return (React.createElement(React.Fragment, { key: "redactionhdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, r.p_doi),
                        React.createElement("td", null, r.p_isbn),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"),
                        React.createElement("td", null, acceptReason))));
            });
            if (((_f = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _f !== void 0 ? _f : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "redactionsid_one_row" },
                        React.createElement("td", { colSpan: 11, className: "articlescentertab articlesred" }, "Brak informacji o redakcjach monografii"))];
            }
            return React.createElement(React.Fragment, { key: "renderRedactions" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tableredactions" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "redactionsid_one_hdr" },
                                React.createElement("td", { colSpan: 11, className: "articlescentertab articlestheader" }, "Redakcje monografii")),
                            React.createElement("tr", { key: "redactionsid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Tytu\u0142"),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "DOI"),
                                React.createElement("th", null, "ISBN"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"),
                                React.createElement("th", null, "Akceptacja"))),
                        React.createElement("tbody", null, wiersze),
                        React.createElement("tfoot", null,
                            React.createElement("tr", null,
                                React.createElement("td", { colSpan: 11 },
                                    React.createElement(ExplainPublicationReject_1.default, { rowsnum: (_g = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _g !== void 0 ? _g : 0 })))))));
        }
    };
    PublicationPanelByPubKind.prototype.render_chapters = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.displayData) === null || _b === void 0 ? void 0 : _b.chapters) === null) {
            return React.createElement(React.Fragment, { key: "renderChapters" });
        }
        else {
            var wiersze = (_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.displayData) === null || _d === void 0 ? void 0 : _d.chapters) === null || _e === void 0 ? void 0 : _e.map(function (ro, ridx) {
                var r = ro;
                var disc_nazwa = _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, r.frozen);
                var clsNameRow = ((ridx & 1) == 0) ? "article_row_even" : "article_row_odd";
                var acceptReason;
                if (r.es_discipline_id) {
                    acceptReason = r.accepted_to_eval ? "Tak" : "Nie: R" + String(r.reject_reason).padStart(2, '0');
                }
                else {
                    acceptReason = "";
                }
                return (React.createElement(React.Fragment, { key: "chapterhdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.p_tytul_rozdzialu),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, r.p_doi),
                        React.createElement("td", null, r.p_isbn),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"),
                        React.createElement("td", null, acceptReason))));
            });
            if (((_f = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _f !== void 0 ? _f : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "chapterssid_one_row" },
                        React.createElement("td", { colSpan: 12, className: "articlescentertab articlesred" }, "Brak informacji o rozdzia\u0142ach w monografiach"))];
            }
            return React.createElement(React.Fragment, { key: "renderChapters" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tablechapters" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "chaptersid_one_hdr" },
                                React.createElement("td", { colSpan: 12, className: "articlescentertab articlestheader" }, "Rozdzia\u0142y w monografiach")),
                            React.createElement("tr", { key: "chapterssid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Tytu\u0142 mon."),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "Tytu\u0142 roz."),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "DOI"),
                                React.createElement("th", null, "ISBN"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"),
                                React.createElement("th", null, "Akceptacja"))),
                        React.createElement("tbody", null, wiersze),
                        React.createElement("tfoot", null,
                            React.createElement("tr", null,
                                React.createElement("td", { colSpan: 12 },
                                    React.createElement(ExplainPublicationReject_1.default, { rowsnum: (_g = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _g !== void 0 ? _g : 0 })))))));
        }
    };
    PublicationPanelByPubKind.prototype.render_patents = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        if (((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.displayData) === null || _b === void 0 ? void 0 : _b.patents) === null) {
            return React.createElement(React.Fragment, { key: "renderPatents" });
        }
        else {
            var wiersze = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.displayData) === null || _d === void 0 ? void 0 : _d.patents.map(function (r, ridx) {
                var _a, _b, _c;
                var w = (_c = ((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.disciplinesList) === null || _b === void 0 ? void 0 : _b.find(function (ff) { return ff.key == r.es_discipline_id; }))) !== null && _c !== void 0 ? _c : undefined;
                var disc_nazwa = _this.obtainDisciplineCombo(r.id_publikacja, r.es_discipline_id, r.frozen);
                var clsNameRow = ((ridx & 1) == 0) ? "patent_row_even" : "patent_row_odd";
                return (React.createElement(React.Fragment, { key: "patentyhdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, _.has(r, 'p_id_ocena') ? r.p_id_ocena : ""),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, r.la_id_autor),
                        React.createElement("td", null, r.p_typ),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, disc_nazwa),
                        React.createElement("td", null, r.data_mod),
                        React.createElement("td", null, r.statement_printed),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"))));
            });
            if (((_e = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _e !== void 0 ? _e : 0) == 0) {
                wiersze = [React.createElement("tr", { key: "patentyid_one_row" },
                        React.createElement("td", { colSpan: 11, className: "patentycentertab patentyred" }, "Brak informacji o patentach"))];
            }
            return React.createElement(React.Fragment, { key: "renderPatents" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tablepatenty" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "patentysid_one_hdr" },
                                React.createElement("td", { colSpan: 11, className: "patentycentertab patentytheader" }, "Patenty / wzory u\u017Cytkowe")),
                            React.createElement("tr", { key: "patentyid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Kod ocn."),
                                React.createElement("th", null, "Tytu\u0142"),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "ID autora"),
                                React.createElement("th", null, "Typ"),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "Dyscyplina"),
                                React.createElement("th", null, "Data wyboru dysc."),
                                React.createElement("th", null, "Data o\u015Bwiadczenia"),
                                React.createElement("th", null, "Zablokowany"))),
                        React.createElement("tbody", null, wiersze))));
        }
    };
    PublicationPanelByPubKind.prototype.render_rejected_pub_level = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if ((((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.displayData) === null || _b === void 0 ? void 0 : _b.rejected_pub_level) === null) || (((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.displayData) === null || _d === void 0 ? void 0 : _d.rejected_pub_level.length) == 0)) {
            return React.createElement(React.Fragment, { key: "renderRPL" });
        }
        else {
            var wiersze = (_g = (_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.displayData) === null || _f === void 0 ? void 0 : _f.rejected_pub_level) === null || _g === void 0 ? void 0 : _g.map(function (r, ridx) {
                var clsNameRow = ((ridx & 1) == 0) ? "article_row_even" : "article_row_odd";
                var x = _.has(r, 'p_isbn') ? r.p_isbn : "";
                var y = _.has(r, 'w_issn') ? r.w_issn : "";
                var var_num_id = (x.length * y.length) ? x + "/" + y : (x + y);
                return (React.createElement(React.Fragment, { key: "rrplhdr" + r.id_publikacja },
                    React.createElement("tr", { className: clsNameRow },
                        React.createElement("td", null, r.id_publikacja),
                        React.createElement("td", null, EvaluationPanelResultPerson_1.namePublication_p_typ(r.rp_typ)),
                        React.createElement("td", null, r.p_tytul),
                        React.createElement("td", null, r.authors),
                        React.createElement("td", null, _.has(r, 'w_nazwa') ? r.w_nazwa : (_.has(r, 'p_tytul_rozdzialu') ? r.p_tytul_rozdzialu : "")),
                        React.createElement("td", null, r.p_rok),
                        React.createElement("td", null, r.p_doi),
                        React.createElement("td", null, var_num_id),
                        React.createElement("td", null, r.frozen ? "Tak" : "Nie"))));
            });
            return React.createElement(React.Fragment, { key: "renderRPL" },
                React.createElement("div", null,
                    React.createElement("table", { className: "tablechapters" },
                        React.createElement("thead", null,
                            React.createElement("tr", { key: "chaptersid_one_hdr" },
                                React.createElement("td", { colSpan: 12, className: "articlescentertab articlestheader" }, "Publikacje oczekuj\u0105ce na zatwierdzenie")),
                            React.createElement("tr", { key: "chapterssid_two_row" },
                                React.createElement("th", null, "ID pub."),
                                React.createElement("th", null, "Typ publikacji"),
                                React.createElement("th", null, "Tytu\u0142"),
                                React.createElement("th", null, "Autorzy"),
                                React.createElement("th", null, "Tytu\u0142 2"),
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "DOI"),
                                React.createElement("th", null, "ISBN/ISSN"),
                                React.createElement("th", null, "Zablokowany"))),
                        React.createElement("tbody", null, wiersze))));
        }
    };
    PublicationPanelByPubKind.prototype.handleChangeSelectDiscipline = function (self, publ_id, selVal) {
        var _this = this;
        axios.default.post("/api/v2/ChangeDiscForPub", { article_id: publ_id, author_id: self.props.userId, discipline_id: selVal }).then(function (res) {
            var w = res.data;
            if (w.discipline_id == "null")
                w.discipline_id = null;
            var conn = _.concat(self.state.displayData.articles, self.state.displayData.monographs, self.state.displayData.redactions, self.state.displayData.chapters, self.state.displayData.patents, self.state.displayData.rejected_pub_level, self.state.displayData.rejected_pub_year);
            var fnd = conn.find(function (x) { return x.id_publikacja === publ_id; });
            if (fnd !== undefined) {
                fnd.es_discipline_id = w.discipline_id;
                var fndx = fnd;
                fndx.accepted_to_eval = false;
                fndx.reject_reason = 5;
            }
            _this.setState({ displayData: self.state.displayData });
        }).catch(function (err) {
            _this.setState({ errorMessage: err.message });
        });
    };
    return PublicationPanelByPubKind;
}(React.Component));
exports.default = PublicationPanelByPubKind;
