"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppAccountGenereateReplacementUUID = void 0;
/**
 * Funkcja generująca zastępczy identyfikator, gdy nie ma jeszcze nadanego numeru POLON
 * @param num - dotychczasowy identyfikator DN10
 * @returns łańcuch z zastępczym GUIDem
 */
function AppAccountGenereateReplacementUUID(num) {
    if (isNaN(num))
        num = 0;
    var p = num.toString();
    var o = "00000000-0000-0000-0000-000000000000".split("");
    var pl = p.length - 1;
    var ol = o.length - 1;
    while ((pl >= 0) && (ol >= 0)) {
        o[ol] = p[pl];
        pl--;
        ol--;
        if (o[ol] == '-')
            ol--;
    }
    return o.join("");
}
exports.AppAccountGenereateReplacementUUID = AppAccountGenereateReplacementUUID;
