"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditButtonsAppAccounts = exports.EditButtons = void 0;
var React = require("react");
var axios = require("axios");
var material_1 = require("@mui/material");
var FormLabel_1 = require("@mui/material/FormLabel");
var FormControl_1 = require("@mui/material/FormControl");
var FormGroup_1 = require("@mui/material/FormGroup");
var FormControlLabel_1 = require("@mui/material/FormControlLabel");
var Checkbox_1 = require("@mui/material/Checkbox");
var Box_1 = require("@mui/material/Box");
var Radio_1 = require("@mui/material/Radio");
;
;
var EditButtons;
(function (EditButtons) {
    EditButtons[EditButtons["ebNone"] = 0] = "ebNone";
    EditButtons[EditButtons["ebAdd"] = 1] = "ebAdd";
    EditButtons[EditButtons["ebDelete"] = 2] = "ebDelete";
    EditButtons[EditButtons["ebEdit"] = 4] = "ebEdit";
})(EditButtons || (EditButtons = {}));
exports.EditButtons = EditButtons;
;
var EditButtonsAppAccounts;
(function (EditButtonsAppAccounts) {
    EditButtonsAppAccounts[EditButtonsAppAccounts["ebImportPolon"] = 16] = "ebImportPolon";
    EditButtonsAppAccounts[EditButtonsAppAccounts["ebImportPRz"] = 32] = "ebImportPRz";
    EditButtonsAppAccounts[EditButtonsAppAccounts["ebImportDN10"] = 64] = "ebImportDN10";
})(EditButtonsAppAccounts || (EditButtonsAppAccounts = {}));
exports.EditButtonsAppAccounts = EditButtonsAppAccounts;
;
var FormAppAccounts = /** @class */ (function (_super) {
    __extends(FormAppAccounts, _super);
    function FormAppAccounts(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { inpString: "", szuknazw: true, szukimie: true, szukpoczatek: true, szukpolon: true, szukUUID: false, szukOrcid: false, szukUPN: true, apidata: null, selectedPerson: null };
        return _this;
    }
    FormAppAccounts.prototype.handleSelectClick = function (event, self) {
        self.handleSelectClickWorker(parseInt(event.target.value), self);
    };
    FormAppAccounts.prototype.handleSelectClickWorker = function (w, self) {
        self.setState({ selectedPerson: w });
        var person = self.state.apidata.find(function (el) {
            return el.row_num === w;
        });
        self.props.onSelectCallback(self, person);
    };
    FormAppAccounts.prototype.handleSearchClick = function (self) {
        var _this = this;
        axios.default.post("/api/admin/AccountSearch", { dtext: self.state.inpString, flgnazw: self.state.szuknazw, flgimie: self.state.szukimie, flgstart: self.state.szukpoczatek,
            flgpolon: self.state.szukpolon, flgorcid: self.state.szukOrcid, flgUUID: self.state.szukUUID, flgUPN: self.state.szukUPN }).then(function (res) {
            _this.setState({ apidata: res.data });
        }).catch(function (err) {
            alert(err.message);
            _this.setState({ apidata: null });
        });
    };
    FormAppAccounts.prototype.handleChange = function (event, frm) {
        var _a;
        var ns = __assign(__assign({}, frm.state), (_a = {}, _a[event.target.name] = event.target.checked, _a));
        frm.setState(ns);
    };
    ;
    FormAppAccounts.prototype.goWithHistory = function (self, target, event) {
        self.props.history.push(target + "/" + self.state.selectedPerson);
    };
    FormAppAccounts.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var result;
        if (((_b = (_a = this.state.apidata) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) == 0)
            result = React.createElement("div", null);
        else {
            var rows = (_c = this.state.apidata) === null || _c === void 0 ? void 0 : _c.map(function (r, idx) {
                return React.createElement("tr", { key: "account_row_" + r.row_num },
                    React.createElement("td", null,
                        React.createElement(Radio_1.default, { checked: _this.state.selectedPerson === r.row_num, value: r.row_num, name: "radio-list-persons", onChange: function (e) { return _this.handleSelectClick(e, _this); } })),
                    React.createElement("td", null, idx + 1),
                    React.createElement("td", null, r.um_polon_imie),
                    React.createElement("td", null, r.um_polon_drugie),
                    React.createElement("td", null, r.um_polon_nazwisko),
                    React.createElement("td", null, r.um_stopien_tytul_wyliczany),
                    React.createElement("td", null, r.id_pracownik),
                    React.createElement("td", null, r.id_pracownik_list),
                    React.createElement("td", null, r.polonuuid),
                    React.createElement("td", null, r.orcid),
                    React.createElement("td", null, r.userprincipalname),
                    React.createElement("td", null, r.um_current ? " Tak" : "Nie"),
                    React.createElement("td", null, r.um_id_user));
            });
            result = React.createElement("table", { className: "tablepolondysc" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "polonemployeedisciplineid_dscy_r1" },
                        React.createElement("td", { colSpan: 13, className: "centertab theader" }, "DN 2.0 \u2013 lista pracownik\u00F3w")),
                    React.createElement("tr", { key: "polonemployeedisciplineid_dscy_r2" },
                        React.createElement("th", null, "Wybierz"),
                        React.createElement("th", null, "Lp"),
                        React.createElement("th", null, "Imie"),
                        React.createElement("th", null, "Drugie"),
                        React.createElement("th", null, "Nazwisko"),
                        React.createElement("th", null, "Stopie\u0144"),
                        React.createElement("th", null, "ID pracownika"),
                        React.createElement("th", null, "Lista ID prac."),
                        React.createElement("th", null, "Polon UUID"),
                        React.createElement("th", null, "Orcid"),
                        React.createElement("th", null, "Login"),
                        React.createElement("th", null, "Aktywny"),
                        React.createElement("th", null, "ID wewn\u0119trzny"))),
                React.createElement("tbody", null, rows));
        }
        var buttons = [];
        var valueVector = [EditButtons.ebAdd, EditButtons.ebEdit, EditButtons.ebDelete, EditButtonsAppAccounts.ebImportDN10, EditButtonsAppAccounts.ebImportPRz, EditButtonsAppAccounts.ebImportPolon];
        var redirectVector = ["/admin/AppAccounts/Add", "/admin/AppAccounts/Edit", "/admin/AppAccounts/Delete", "/admin/AppAccounts/ImportDN10", "/admin/AppAccounts/ImportPRz", "/admin/AppAccounts/ImportPolon"];
        var stringVector = ["Dodaj użytkownika", "Edytuj", "Usuń użytkownika", "Zaimportuj z DN10", "Zaimportuj z CAS", "Zaimportuj z POLON"];
        var _loop_1 = function (iii) {
            var cw = this_1.props.editButtons.indexOf(valueVector[iii]);
            if (cw != -1) {
                // buttons.push(<Button key={"btn" + iii} component={Link} to={redirectVector[iii] + "/" + this.state.selectedPerson} color="primary" variant="outlined">{stringVector[iii]}</Button>);
                buttons.push(React.createElement(material_1.Button, { key: "btn" + iii, color: "primary", variant: "outlined", onClick: function (e) { return _this.goWithHistory(_this, redirectVector[iii], e); } }, stringVector[iii]));
            }
        };
        var this_1 = this;
        for (var iii = 0; iii < valueVector.length; iii++) {
            _loop_1(iii);
        }
        return React.createElement("div", null,
            React.createElement("form", { noValidate: true, autoComplete: "off", style: { textAlign: 'center', textAlignLast: 'center' }, onSubmit: function (e) {
                    e.preventDefault();
                    _this.handleSearchClick(_this);
                } },
                React.createElement(Box_1.default, { display: "flex", flexDirection: "column", p: 1, m: 1, bgcolor: "background.paper" },
                    React.createElement(Box_1.default, { p: 1 },
                        React.createElement(material_1.TextField, { style: { minWidth: '30%' }, id: "inpUserIdName", label: "Szukane konto", value: this.state.inpString, onChange: function (e) { return _this.setState({ inpString: e.target.value }); } })),
                    React.createElement(Box_1.default, { p: 2 },
                        React.createElement(material_1.Button, { variant: "contained", color: "primary", onClick: function () { return _this.handleSearchClick(_this); } }, "Szukaj")),
                    React.createElement(Box_1.default, { p: 2 },
                        React.createElement(FormControl_1.default, { component: "fieldset" },
                            React.createElement(FormLabel_1.default, { component: "legend" }, "Zakres przeszukiwania"),
                            React.createElement(FormGroup_1.default, { row: true },
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szuknazw, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szuknazw" }), label: "Nazwisko" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukimie, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukimie" }), label: "Imi\u0119" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukUPN, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukUPN" }), label: "Login" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukpoczatek, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukpoczatek" }), label: this.state.szukpoczatek ? "Rozpoczyna" : "Zawiera" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukpolon, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukpolon" }), label: "UUID Polon" }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukUUID, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukUUID" }), label: "UUID Wewn." }),
                                React.createElement(FormControlLabel_1.default, { control: React.createElement(Checkbox_1.default, { checked: this.state.szukOrcid, onChange: function (e) { return _this.handleChange(e, _this); }, name: "szukOrcid" }), label: "ORCID" })))))),
            result,
            (buttons.length > 0) ? React.createElement("div", { className: "buttonActionPanel" }, buttons) : "");
    };
    return FormAppAccounts;
}(React.Component));
exports.default = FormAppAccounts;
