"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./DanePolonTable.scss");
var DanePolonDyscypliny = /** @class */ (function (_super) {
    __extends(DanePolonDyscypliny, _super);
    function DanePolonDyscypliny(p) {
        return _super.call(this, p) || this;
    }
    DanePolonDyscypliny.prototype.render = function () {
        var _a, _b, _c;
        var wiersze = (_b = (_a = this === null || this === void 0 ? void 0 : this.props) === null || _a === void 0 ? void 0 : _a.disp) === null || _b === void 0 ? void 0 : _b.map(function (r, idx) {
            return (React.createElement("tr", { key: r.polonemployeedisciplineid + "_" + idx },
                React.createElement("td", null, r.statementfrom),
                React.createElement("td", null, r.statementto),
                React.createElement("td", null, r.discipline1label),
                React.createElement("td", null, r.discipline1percentage == null ? "" : r.discipline1percentage.toString() + " %"),
                React.createElement("td", null, r.discipline2label),
                React.createElement("td", null, r.discipline2percentage == null ? "" : r.discipline2percentage.toString() + " %"),
                React.createElement("td", null, r.participationindex),
                React.createElement("td", null, r.participationfromdate),
                React.createElement("td", null, r.participationtodate),
                React.createElement("td", null, r.closereason)));
        });
        if (((_c = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _c !== void 0 ? _c : 0) == 0)
            wiersze = [React.createElement("tr", { key: "polonemployeedisciplineid_dscy_rs" },
                    React.createElement("td", { colSpan: 10, className: "centertab red" }, "Brak o\u015Bwiadcze\u0144 o przynale\u017Cno\u015Bci do dyscypliny"))];
        return (React.createElement("div", null,
            React.createElement("table", { className: "tablepolondysc" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "polonemployeedisciplineid_dscy_r1" },
                        React.createElement("td", { colSpan: 10, className: "centertab theader" }, "POLON - zestawienie procentowego udzia\u0142u w dyscyplinach")),
                    React.createElement("tr", { key: "polonemployeedisciplineid_dscy_r2" },
                        React.createElement("th", null, "Data od"),
                        React.createElement("th", null, "Data do"),
                        React.createElement("th", null, "Dyscyplina 1"),
                        React.createElement("th", null, "Udzia\u0142 dysc. 1"),
                        React.createElement("th", null, "Dyscyplina 2"),
                        React.createElement("th", null, "Udzia\u0142 dysc. 2"),
                        React.createElement("th", null, "Numer kolejny udzia\u0142u"),
                        React.createElement("th", null, "Udzia\u0142 od"),
                        React.createElement("th", null, "Udzia\u0142 do"),
                        React.createElement("th", null, "Pow\u00F3d zamkni\u0119cia"))),
                React.createElement("tbody", null, wiersze))));
    };
    return DanePolonDyscypliny;
}(React.Component));
exports.default = DanePolonDyscypliny;
