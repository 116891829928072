"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CASPRzLogin = void 0;
var React = require("react");
var axios = require("axios");
require("./CASPRzLogin.scss");
var commonConstants_1 = require("./commonConstants");
;
;
var CASPRzLogin = /** @class */ (function (_super) {
    __extends(CASPRzLogin, _super);
    function CASPRzLogin(p) {
        var _this = _super.call(this, p) || this;
        _this.state = null;
        return _this;
    }
    CASPRzLogin.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props.authManager, ctx = _a[0], setV = _a[1];
        axios.default.get("/api/isLogged").then(function (res) {
            var _a = res.data, status = _a.status, userName = _a.userName, csr = _a.csr, attrs = _a.attrs;
            if (status === true) {
                var data_object = { physUserName: userName,
                    loggedUserName: userName,
                    formToken: csr,
                    attrs: attrs };
                setV(data_object);
                sessionStorage.setItem(commonConstants_1.ssLoginData, JSON.stringify(data_object));
                document.getElementById("myPRzLoginStatus").innerHTML = "<b class=\"casmenu\">" + userName + "</b> | <a href=\"/api/logout\" class=\"casmenu\">wyloguj się</a>";
            }
            else {
                var data_object = { physUserName: null,
                    loggedUserName: null,
                    formToken: null,
                    attrs: null };
                setV(data_object);
                sessionStorage.setItem(commonConstants_1.ssLoginData, JSON.stringify(data_object));
                document.getElementById("myPRzLoginStatus").innerHTML = "<b class=\"casmenu\">Nie zalogowano</b> | <a href=\"/api/login\" class=\"casmenu\">Zaloguj się</a>";
            }
            _this.props.reloader();
        });
    };
    CASPRzLogin.prototype.render = function () {
        return (React.createElement("div", { style: { margin: 0, padding: 0, border: 0, width: "100%", height: "27px", background: "#333", color: "#aaa", fontFamily: "Arial, sans-serif", fontSize: "11pt", fontWeight: "normal", textDecoration: "none", textAlign: "left", borderBottom: '1px dashed #888' }, id: "casmenu" },
            React.createElement("table", { style: { margin: 0, width: "100%" } },
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { style: { whiteSpace: "nowrap", padding: '6px 0 0 8px', lineHeight: "normal", textAlign: 'left', fontSize: '11px', fontFamily: 'Tahoma, Helvetica, Segoe UI, Segoe, Arial, Lucida Grande, Verdana, sans-serif' } },
                            React.createElement("b", { className: "casmenu" }, "Politechnika Rzeszowska im. Ignacego \u0141ukasiewicza"),
                            " - Centralny System Uwierzytelniania"),
                        React.createElement("td", { style: { whiteSpace: 'nowrap', padding: '6px 8px 0 6px', lineHeight: 'normal', textAlign: 'right', fontFamily: 'Tahoma, Helvetica, Segoe UI, Segoe, Arial, Lucida Grande, Verdana, sans-serif', fontSize: '11px' } },
                            React.createElement("div", { id: "myPRzLoginStatus" })))))));
    };
    return CASPRzLogin;
}(React.Component));
exports.CASPRzLogin = CASPRzLogin;
