"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var react_router_1 = require("react-router");
var axios = require("axios");
var material_1 = require("@mui/material");
var DanePRz_1 = require("./DanePRz");
var DanePolon_1 = require("./DanePolon");
var DanePolonZatrudnienie_1 = require("./DanePolonZatrudnienie");
var DanePolonDyscypliny_1 = require("./DanePolonDyscypliny");
var DanePolonOswiadczenia_1 = require("./DanePolonOswiadczenia");
var DaneDoEwaulacji_1 = require("./DaneDoEwaulacji");
var material_2 = require("@mui/material");
var PotwierdzeniaPanel_1 = require("./PotwierdzeniaPanel");
var FormSelectAvailEvalPeriods_1 = require("../forms/FormSelectAvailEvalPeriods");
var PRzInfoPanel = /** @class */ (function (_super) {
    __extends(PRzInfoPanel, _super);
    function PRzInfoPanel(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { data: null, errorMessage: null, dummy: false };
        return _this;
    }
    PRzInfoPanel.prototype.componentDidMount = function () {
        var _this = this;
        axios.default.get("/api/v2/PRzInfoPanel").then(function (res) {
            _this.setState({ data: res.data, errorMessage: null, dummy: false });
        }).catch(function (err) {
            _this.setState({ data: null, errorMessage: err.message, dummy: false });
        });
    };
    /*
        shouldComponentUpdate(nextProps: Readonly<{}>, nextState: Readonly<PRzInfoPanelState>, nextContext: any) : boolean {
            debugger;
            return true;
        }
    */
    PRzInfoPanel.prototype.updatePeriod = function (self, b) {
        var _this = this;
        axios.default.get("/api/v2/PRzInfoPanel/" + b).then(function (res) {
            _this.setState({ data: res.data, errorMessage: null, dummy: false });
        }).catch(function (err) {
            _this.setState({ data: null, errorMessage: err.message, dummy: false });
        });
    };
    PRzInfoPanel.prototype.render = function () {
        var _this = this;
        var _a, _b;
        if ((_a = this === null || this === void 0 ? void 0 : this.state) === null || _a === void 0 ? void 0 : _a.errorMessage) {
            return React.createElement(React.Fragment, null,
                React.createElement(material_1.Alert, { severity: "error" },
                    "B\u0142\u0105d pobierania informacji o u\u017Cytkowniku: '",
                    this.state.errorMessage,
                    "'."),
                React.createElement(react_router_1.Redirect, { to: "/dashboard" }));
        }
        if ((_b = this === null || this === void 0 ? void 0 : this.state) === null || _b === void 0 ? void 0 : _b.data) {
            return (React.createElement("div", null,
                React.createElement(DanePRz_1.default, { disp: this.state.data.danePRz }),
                React.createElement(DanePolon_1.default, { disp: this.state.data.danePracownika }),
                React.createElement(DanePolonZatrudnienie_1.default, { disp: this.state.data.zatrudnienie }),
                React.createElement(DanePolonDyscypliny_1.default, { disp: this.state.data.dyscypliny }),
                React.createElement(DanePolonOswiadczenia_1.default, { disp: this.state.data.oswiad }),
                React.createElement(FormSelectAvailEvalPeriods_1.FormSelectAvailEvalPeriods, __assign({ callback: function (a, b) { return _this.updatePeriod(_this, b); } }, this.props)),
                React.createElement(DaneDoEwaulacji_1.default, { disp: this.state.data.calculated }),
                React.createElement("div", { id: "menu", style: { display: 'grid', gridTemplateColumns: '33% 33% 33%', columnGap: '10pt', gridTemplateRows: '50% 50%', rowGap: '10pt', justifyItems: 'center', alignItems: 'center' } },
                    React.createElement(material_2.Button, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika"),
                    React.createElement(material_2.Button, { color: "primary", variant: "outlined", onClick: function () {
                            axios.default.post("/api/v2/PRzInfoPanelConfirm", { status: 1, note: null }).then(function (res) {
                                alert('Zapisano potwierdzenie');
                                _this.setState({ dummy: !_this.state.dummy });
                            }).catch(function (err) {
                                alert('Błąd przesyłania potwierdzenia: ' + err.message);
                            });
                        } }, "Potwierd\u017A poprawno\u015B\u0107 danych"),
                    React.createElement(material_2.Button, { id: "bmzd", color: "secondary", variant: "outlined", onClick: function () {
                            var f = document.getElementById("this-note-error");
                            if (typeof f === "undefined" || f === null) {
                                alert("Błąd wewnętrzny - nie znaleziono komponentu podrzędnego");
                            }
                            else {
                                f.style.display = 'block';
                                var m = document.getElementById("standard-multiline-static");
                                if (typeof f === "undefined" || f === null) { }
                                else {
                                    m.focus();
                                }
                            }
                        } }, "Zg\u0142o\u015B b\u0142\u0105d danych")),
                React.createElement("div", { id: "this-note-error", style: { display: 'none', textAlign: 'center', textAlignLast: 'center' } },
                    React.createElement("form", { name: "fnsubmitter", onSubmit: function (e) { e.preventDefault(); } },
                        React.createElement("div", { style: { marginBlock: '10px' } },
                            React.createElement(material_2.TextField, { id: "standard-multiline-static", name: "notes", variant: "outlined", label: "Opis b\u0142\u0119du danych", multiline: true, rows: 4, defaultValue: "", style: { width: '50%', textAlign: 'left', textAlignLast: 'left' } })),
                        React.createElement("div", null,
                            React.createElement(material_2.Button, { color: "primary", variant: "outlined", onClick: function () {
                                    axios.default.post("/api/v2/PRzInfoPanelConfirm", { status: 0, note: document.forms['fnsubmitter'].notes.value }).then(function (res) {
                                        // alert('Zapisano zgłoszenie na serwerze');
                                        _this.setState({ dummy: !_this.state.dummy });
                                        var f = document.getElementById("this-note-error");
                                        if (typeof f === "undefined" || f === null) {
                                            alert("Błąd wewnętrzny - nie znaleziono komponentu podrzędnego");
                                        }
                                        else {
                                            f.style.display = 'none';
                                        }
                                    }).catch(function (err) {
                                        alert('Błąd przesyłania zgłoszenia: ' + err.message);
                                    });
                                } }, "Prze\u015Blij zg\u0142oszenie")))),
                React.createElement(PotwierdzeniaPanel_1.PotwierdzeniaPanel, { dummy: this.state.dummy, forUser: null })));
        }
        return React.createElement("div", null);
    };
    return PRzInfoPanel;
}(React.Component));
exports.default = PRzInfoPanel;
