"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionPanel = void 0;
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
;
function ActionPanel(props) {
    var inner = props.pageReload ? React.createElement("a", { style: { color: 'white', textAlign: 'center', verticalAlign: 'middle', textDecoration: 'none', fontSize: '12pt' }, href: props.target }, props.displText) : React.createElement(react_router_dom_1.Link, { style: { color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '12pt' }, to: props.target }, props.displText);
    return React.createElement("div", { style: { borderRadius: 5, backgroundColor: props.color, margin: '10pt', display: 'flex', flexFlow: 'wrap', float: 'left', padding: '5pt', fontFamily: 'Segoe UI, Tahoma, Helvetica, Segoe, Arial, Lucida Grande, Verdana, sans-serif' } }, inner);
}
exports.ActionPanel = ActionPanel;
