"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormAppAccountsImportDN10 = void 0;
var React = require("react");
var appaccounttype_1 = require("../apitypes/appaccounttype");
var Box_1 = require("@mui/material/Box");
var material_1 = require("@mui/material");
var axios = require("axios");
var Radio_1 = require("@mui/material/Radio");
var PersonFindSelect_1 = require("./PersonFindSelect");
var material_2 = require("@mui/material");
;
;
var FormAppAccountsImportDN10 = /** @class */ (function (_super) {
    __extends(FormAppAccountsImportDN10, _super);
    function FormAppAccountsImportDN10(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { dispData: _this.zeroPersonData(), foundData: null, error: null, selectedPersonId: 0, displayPRzUser: false, acceptAccount: false };
        return _this;
    }
    FormAppAccountsImportDN10.prototype.zeroPersonData = function () {
        var m = {
            row_num: 0,
            um_id_user: "",
            id_pracownik: 0,
            id_pracownik_list: "",
            um_polon_nazwisko: "",
            um_polon_imie: "",
            um_polon_drugie: "",
            um_stopien_tytul_wyliczany: "",
            um_current: true,
            orcid: "",
            polonuuid: "",
            userprincipalname: ""
        };
        return m;
    };
    FormAppAccountsImportDN10.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        var top = React.createElement(Box_1.default, { m: 1, p: 1, minWidth: '50%' },
            React.createElement(material_1.Typography, { variant: "h6" }, "Wprowad\u017A szukane ID albo nazwisko"),
            React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "identDN10", label: "Identyfikator DN10", value: this.state.dispData.id_pracownik, onChange: function (e) { return _this.updateIdPracownik(_this, e); } }),
            React.createElement(material_1.TextField, { style: { minWidth: '100%' }, id: "identDN10", label: "Nazwisko", value: this.state.dispData.um_polon_nazwisko, onChange: function (e) { return _this.updateNazwiskoPracownik(_this, e); } }));
        var ems = React.createElement(React.Fragment, { key: "disp-err-msg" }, (this.state.error) ? React.createElement(material_2.Alert, { severity: "error" }, this.state.error) : null);
        var btm = React.createElement(Box_1.default, { display: "flex", flexDirection: "row", p: 1, m: "auto", bgcolor: "background.paper", alignItems: "center", width: '25%', flexWrap: "nowrap", justifyContent: "space-between" },
            React.createElement(material_1.Button, { variant: "contained", color: "primary", disabled: !this.state.acceptAccount, onClick: function (e) { return _this.commitWindow(_this); } }, "Zatwierd\u017A"),
            React.createElement(material_1.Button, { variant: "contained", color: "primary", onClick: function (e) { return _this.cancelWindow(_this); } }, "Anuluj"));
        var fndEl;
        if (((_d = (_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.foundData) === null || _b === void 0 ? void 0 : _b.dn10) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) == 0)
            fndEl = React.createElement(React.Fragment, { key: "importDN10NotFound" });
        else {
            var rows = (_g = (_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.foundData) === null || _f === void 0 ? void 0 : _f.dn10) === null || _g === void 0 ? void 0 : _g.map(function (r, idx) {
                return React.createElement("tr", { key: "account_row_" + r.id_pracownik },
                    React.createElement("td", null,
                        React.createElement(Radio_1.default, { checked: _this.state.selectedPersonId === r.id_pracownik, value: r.id_pracownik, name: "radio-list-persons", onChange: function (e) { return _this.handleSelectClick(e, _this); } })),
                    React.createElement("td", null, idx + 1),
                    React.createElement("td", null, r.id_pracownik),
                    React.createElement("td", null, r.os_imie_i),
                    React.createElement("td", null, r.os_nazwisko),
                    React.createElement("td", null, r.t_nazwa),
                    React.createElement("td", null, r.jo_nazwa),
                    React.createElement("td", null, r.os_email),
                    React.createElement("td", null, r.n_aktywny),
                    React.createElement("td", null, r.os_aktywny));
            });
            fndEl = React.createElement(React.Fragment, { key: "importDN10NotFound" },
                React.createElement("table", { className: "tablepolondysc" },
                    React.createElement("thead", null,
                        React.createElement("tr", { key: "polonemployeedisciplineid_dscy_r1" },
                            React.createElement("td", { colSpan: 13, className: "centertab theader" }, "DN 10 \u2013 lista znalezionych pracownik\u00F3w")),
                        React.createElement("tr", { key: "polonemployeedisciplineid_dscy_r2" },
                            React.createElement("th", null, "Wybierz"),
                            React.createElement("th", null, "Lp"),
                            React.createElement("th", null, "ID pracownika"),
                            React.createElement("th", null, "Imie"),
                            React.createElement("th", null, "Nazwisko"),
                            React.createElement("th", null, "Tytu\u0142/stopie\u0144"),
                            React.createElement("th", null, "Jednostka"),
                            React.createElement("th", null, "e-mail"),
                            React.createElement("th", null, "DN10 - Osoba - Aktywny"),
                            React.createElement("th", null, "DN10 - Pracownik - Aktywny"))),
                    React.createElement("tbody", null, rows)));
        }
        var subPanel;
        if (this.state.displayPRzUser) {
            subPanel = React.createElement(React.Fragment, { key: "PersonPRzSelector" },
                React.createElement(Box_1.default, { display: "flex", flexDirection: "column", p: 1, m: 1, bgcolor: "background.paper", alignItems: "center", minWidth: '75%' },
                    React.createElement(material_1.Typography, { variant: "h6" }, "Wska\u017C konto pracownicze do po\u0142\u0105czenia")),
                React.createElement(PersonFindSelect_1.PersonFindSelect, { onSelectCallback: function (comp, item) {
                        _this.personPRzDidSelect(_this, item);
                    } }));
        }
        else {
            subPanel = React.createElement(React.Fragment, { key: "PersonPRzSelector" });
        }
        return React.createElement(React.Fragment, { key: "importDN10" },
            React.createElement(Box_1.default, { display: "flex", flexDirection: "column", p: 1, m: 1, bgcolor: "background.paper", alignItems: "center", minWidth: '75%' },
                top,
                fndEl),
            subPanel,
            React.createElement(Box_1.default, { display: "flex", flexDirection: "column", p: 1, m: 1, bgcolor: "background.paper", alignItems: "center", minWidth: '75%' },
                ems,
                btm));
    };
    FormAppAccountsImportDN10.prototype.personPRzDidSelect = function (comp, item) {
        var p = comp.state.dispData;
        p.row_num = 0;
        if (item.polonuuid == null || item.polonuuid.length == 0) {
            p.um_id_user = appaccounttype_1.AppAccountGenereateReplacementUUID(p.id_pracownik);
        }
        else {
            p.um_id_user = item.polonuuid;
            axios.default.post('/api/admin/SearchForPolonOrcid', { polonuuid: item.polonuuid }).then(function (r) {
                if (r.data.status == "OK") {
                    var pp = comp.state.dispData;
                    pp.um_polon_drugie = r.data.polon[0].middlenames;
                    pp.orcid = r.data.polon[0].orcid;
                    comp.setState({ dispData: pp, acceptAccount: true });
                }
                else {
                    alert('Nie znaleziono podanego identyfikatora POLON');
                    comp.setState({ acceptAccount: true });
                }
            }).catch(function (r) {
                alert('Błąd komunikacji: ' + r.message);
                comp.setState({ acceptAccount: true });
            });
        }
        p.id_pracownik_list = p.id_pracownik.toString();
        p.um_polon_nazwisko = item.nazwisko;
        p.um_polon_imie = item.imie;
        p.um_stopien_tytul_wyliczany = item.stopien;
        p.um_current = true;
        p.polonuuid = item.polonuuid;
        p.userprincipalname = item.userprincipalname;
        comp.setState({ dispData: p });
    };
    FormAppAccountsImportDN10.prototype.updateIdPracownik = function (self, e) {
        var p = self.state.dispData;
        p.id_pracownik = parseInt(e.target.value);
        if (isNaN(p.id_pracownik))
            p.id_pracownik = 0;
        self.setState({ dispData: p });
        axios.default.post("/api/admin/AppAccountQueryFromDN10ByID", { "id_pracownik": p.id_pracownik }).then(function (res) {
            self.setState({ foundData: res.data, selectedPersonId: 0 });
        }).catch(function (err) {
            self.setState({ error: err.message });
        });
    };
    FormAppAccountsImportDN10.prototype.updateNazwiskoPracownik = function (self, e) {
        var p = self.state.dispData;
        p.um_polon_nazwisko = e.target.value;
        self.setState({ dispData: p });
        axios.default.post("/api/admin/AppAccountQueryFromDN10ByName", { "nazwisko": p.um_polon_nazwisko }).then(function (res) {
            self.setState({ foundData: res.data, selectedPersonId: 0 });
        }).catch(function (err) {
            self.setState({ error: err.message });
        });
    };
    FormAppAccountsImportDN10.prototype.cancelWindow = function (self) {
        self.props.history.goBack();
    };
    FormAppAccountsImportDN10.prototype.commitWindow = function (self) {
        // alert("Zaakceptowano połączenie kont:" + JSON.stringify(self.state.dispData));
        axios.default.post("/api/admin/AppAccountInsert", { account: self.state.dispData }).then(function (res) {
            var dst = res.data;
            if (dst.length > 0)
                self.props.history.replace("/admin/AppAccounts/Edit/" + dst.pop().row_num);
            else
                self.setState({ error: "Import konta nie zwrócił nowego identyfikatora" });
            // self.props.history.goBack();
        }).catch(function (err) {
            self.setState({ error: err.message });
        });
    };
    FormAppAccountsImportDN10.prototype.handleSelectClick = function (event, self) {
        self.handleSelectClickWorker(parseInt(event.target.value), self);
    };
    FormAppAccountsImportDN10.prototype.handleSelectClickWorker = function (w, self) {
        var p = self.state.dispData;
        p.id_pracownik = w;
        if (isNaN(p.id_pracownik))
            p.id_pracownik = 0;
        self.setState({ dispData: p, selectedPersonId: w, displayPRzUser: true });
    };
    return FormAppAccountsImportDN10;
}(React.Component));
exports.FormAppAccountsImportDN10 = FormAppAccountsImportDN10;
