"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EvaluationPanelResultSummaryMode = exports.EvaluationPanelResultSummary = void 0;
var React = require("react");
var _ = require("lodash");
var ExplainPublicationReject_1 = require("./ExplainPublicationReject");
var material_1 = require("@mui/material");
var EvaluationPanelResultPerson_1 = require("./EvaluationPanelResultPerson");
var axios = require("axios");
var EvaluationPanelResultSummaryMode;
(function (EvaluationPanelResultSummaryMode) {
    EvaluationPanelResultSummaryMode[EvaluationPanelResultSummaryMode["eprsmNoExtraColumn"] = 0] = "eprsmNoExtraColumn";
    EvaluationPanelResultSummaryMode[EvaluationPanelResultSummaryMode["eprsmExtraColumnIncludeInOpt"] = 1] = "eprsmExtraColumnIncludeInOpt";
    EvaluationPanelResultSummaryMode[EvaluationPanelResultSummaryMode["eprsmExtraColumnIncludeInCust"] = 2] = "eprsmExtraColumnIncludeInCust";
    EvaluationPanelResultSummaryMode[EvaluationPanelResultSummaryMode["eprsmExtraColumnIncludeInOptEditable"] = 65537] = "eprsmExtraColumnIncludeInOptEditable";
    EvaluationPanelResultSummaryMode[EvaluationPanelResultSummaryMode["eprsmExtraColumnIncludeInCustEditable"] = 131074] = "eprsmExtraColumnIncludeInCustEditable";
})(EvaluationPanelResultSummaryMode || (EvaluationPanelResultSummaryMode = {}));
exports.EvaluationPanelResultSummaryMode = EvaluationPanelResultSummaryMode;
;
function name_optionalBoolValue(qv, nullName, trueName, falseName) {
    if (nullName === void 0) { nullName = "brak"; }
    if (trueName === void 0) { trueName = "Tak"; }
    if (falseName === void 0) { falseName = "Nie"; }
    if (qv == null)
        return nullName;
    else
        return qv ? trueName : falseName;
}
;
var EvaluationPanelResultSummary = /** @class */ (function (_super) {
    __extends(EvaluationPanelResultSummary, _super);
    function EvaluationPanelResultSummary(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { errorMessage: null, modifiedOptListValue: [], modifiedCustListValue: [] };
        return _this;
    }
    EvaluationPanelResultSummary.prototype.checkboxUseInOptMaker = function (self, idPub, apiValue) {
        var ov = (this.state.modifiedOptListValue.find(function (p) { return p.key === idPub; }));
        var uv;
        if (_.isUndefined(ov))
            uv = apiValue;
        else
            uv = ov.value;
        return React.createElement(material_1.Checkbox, { checked: uv, onChange: function (e) { return self.handleUseInOpt(self, e, idPub); } });
    };
    EvaluationPanelResultSummary.prototype.handleUseInOpt = function (self, e, idPub) {
        var _this = this;
        axios.default.post("/api/v2/handleUseInOpt", { publId: idPub, sel: e.target.checked, polonuuid: self.props.currentPersonId.polonuuid })
            .then(function (res) {
            var ov = (_this.state.modifiedOptListValue.find(function (p) { return p.key === res.data.key; }));
            if (_.isUndefined(ov)) {
                var w = _this.state.modifiedOptListValue;
                w.push({ key: res.data.key, value: res.data.value });
                _this.setState({ modifiedOptListValue: w });
            }
            else {
                ov.value = res.data.value;
                var w = _this.state.modifiedOptListValue;
                _this.setState({ modifiedOptListValue: w });
            }
        })
            .catch(function (err) {
            self.setState({ errorMessage: err.message });
        });
    };
    EvaluationPanelResultSummary.prototype.checkboxUseInCustMaker = function (self, idPub, apiValue) {
        var ov = (this.state.modifiedCustListValue.find(function (p) { return p.key === idPub; }));
        var uv;
        if (_.isUndefined(ov)) {
            if (apiValue == null)
                apiValue = false;
            uv = apiValue;
        }
        else
            uv = ov.value;
        return React.createElement(material_1.Checkbox, { checked: uv, onChange: function (e) { return self.handleUseInCust(self, e, idPub); } });
    };
    EvaluationPanelResultSummary.prototype.handleUseInCust = function (self, e, idPub) {
        var _this = this;
        axios.default.post("/api/v2/handleUseInCust", { publId: idPub, sel: e.target.checked, polonuuid: self.props.currentPersonId.polonuuid })
            .then(function (res) {
            var ov = (_this.state.modifiedCustListValue.find(function (p) { return p.key === res.data.key; }));
            if (_.isUndefined(ov)) {
                var w = _this.state.modifiedCustListValue;
                w.push({ key: res.data.key, value: res.data.value });
                _this.setState({ modifiedCustListValue: w });
            }
            else {
                ov.value = res.data.value;
                var w = _this.state.modifiedCustListValue;
                _this.setState({ modifiedCustListValue: w });
            }
        })
            .catch(function (err) {
            self.setState({ errorMessage: err.message });
        });
    };
    EvaluationPanelResultSummary.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var w = this.props.max_slotow_w_dyscyplinie - this.props.setres.slot_person_in_discipline;
        var tabela_rows = this.props.setres.pub_qualification.map(function (qp) {
            return (React.createElement("tr", { key: qp.id_publikacja.toString() + "_" + qp.p_rok.toString() },
                React.createElement("td", null, qp.id_publikacja),
                React.createElement("td", null, EvaluationPanelResultPerson_1.namePublication_p_typ(qp.rp_typ)),
                React.createElement("td", null, qp.p_rok),
                React.createElement("td", null, qp.p_tytul),
                React.createElement("td", null, qp.authors),
                React.createElement("td", null, _.has(qp, 'w_nazwa') ? qp.w_nazwa : ""),
                React.createElement("td", null, qp.dpp_punkty.toFixed(4)),
                React.createElement("td", null, qp.estimated_points.toFixed(4)),
                React.createElement("td", null, qp.estimated_slots.toFixed(4)),
                React.createElement("td", null, name_optionalBoolValue(qp.accepted_to_eval)),
                React.createElement("td", null,
                    React.createElement("span", { className: "rejectCodeValue" }, qp.reject_reason ? "R" + String(qp.reject_reason).padStart(2, '0') : "")),
                (_this.props.mode & EvaluationPanelResultSummaryMode.eprsmExtraColumnIncludeInOpt) ? React.createElement("td", null, (_this.props.mode & EvaluationPanelResultSummaryMode.eprsmExtraColumnIncludeInOptEditable) ? _this.checkboxUseInOptMaker(_this, qp.id_publikacja, qp.include_person_opt) : name_optionalBoolValue(qp.include_person_opt)) : null,
                (_this.props.mode & EvaluationPanelResultSummaryMode.eprsmExtraColumnIncludeInCust) ? React.createElement("td", null, (_this.props.mode & EvaluationPanelResultSummaryMode.eprsmExtraColumnIncludeInCustEditable) ? _this.checkboxUseInCustMaker(_this, qp.id_publikacja, qp.custom_selected) : name_optionalBoolValue(qp.custom_selected)) : null));
        });
        var extraColumns = ((this.props.mode & EvaluationPanelResultSummaryMode.eprsmExtraColumnIncludeInOpt) ? 1 : 0)
            + ((this.props.mode & EvaluationPanelResultSummaryMode.eprsmExtraColumnIncludeInCust) ? 1 : 0);
        if (((_a = tabela_rows === null || tabela_rows === void 0 ? void 0 : tabela_rows.length) !== null && _a !== void 0 ? _a : 0) == 0)
            tabela_rows = [React.createElement("tr", { key: "publikacja_dscypliny_one_row" },
                    React.createElement("td", { colSpan: 12 + extraColumns, className: "centertab red" }, "Brak informacji o przypisaniach publikacji do dyscypliny"))];
        var tabela = React.createElement("div", null,
            React.createElement("table", { className: "tablearticlesinsummary" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "articlesid_one_hdr" },
                        React.createElement("td", { colSpan: 12 + extraColumns, className: "articlescentertab articlestheader" }, "Publikacje")),
                    React.createElement("tr", { key: "articlesid_two_row" },
                        React.createElement("th", null, "ID pub."),
                        React.createElement("th", null, "Typ"),
                        React.createElement("th", null, "Rok"),
                        React.createElement("th", null, "Tytu\u0142"),
                        React.createElement("th", null, "Autorzy"),
                        React.createElement("th", null, "Czasopismo"),
                        React.createElement("th", null, "Punkty"),
                        React.createElement("th", null, "Punkty przeliczeniowe"),
                        React.createElement("th", null, "Sloty przeliczeniowe"),
                        React.createElement("th", null, "Akceptacja"),
                        React.createElement("th", null, "Kod nieuwzg."),
                        (this.props.mode & EvaluationPanelResultSummaryMode.eprsmExtraColumnIncludeInOpt) ? React.createElement("th", null, "Uwzgl.opt.") : null,
                        (this.props.mode & EvaluationPanelResultSummaryMode.eprsmExtraColumnIncludeInCust) ? React.createElement("th", null, "U\u017Cytk.") : null)),
                React.createElement("tbody", null, tabela_rows),
                React.createElement("tfoot", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: 12 + extraColumns },
                            React.createElement(ExplainPublicationReject_1.default, { rowsnum: ((_b = tabela_rows === null || tabela_rows === void 0 ? void 0 : tabela_rows.length) !== null && _b !== void 0 ? _b : 0) + 1 }))))));
        var sorted = Object.keys(this.props.summarytable).map(function (v) { return parseInt(v); }).filter(function (x) { return (!isNaN(x)) && (x > 2000); }).sort();
        var p = { rok: -1, liczba_pub: 0.0, suma_pub_pkt_calk: 0.0, suma_pub_pkt_przel: 0.0, suma_slotow_przel: 0.0,
            qualif_pub_liczba: 0, qualif_pub_pkt_calk: 0.0, qualif_pub_pkt_przel: 0.0, qualif_pub_slot_przel: 0.0 };
        var sums = sorted.map(function (kn) {
            var item = _this.props.summarytable[kn];
            p.liczba_pub += item.liczba_pub;
            p.suma_pub_pkt_calk += item.suma_pub_pkt_calk;
            p.suma_pub_pkt_przel += item.suma_pub_pkt_przel;
            p.suma_slotow_przel += item.suma_slotow_przel;
            return React.createElement(React.Fragment, { key: "summary" + kn },
                React.createElement("tr", null,
                    React.createElement("td", null, item.rok),
                    React.createElement("td", null, item.liczba_pub),
                    React.createElement("td", null, item.suma_slotow_przel.toFixed(4)),
                    React.createElement("td", null, item.suma_pub_pkt_calk.toFixed(4)),
                    React.createElement("td", null, item.suma_pub_pkt_przel.toFixed(4))));
        });
        var sums_ewal = sorted.map(function (kn) {
            var item = _this.props.summarytable[kn];
            return React.createElement(React.Fragment, { key: "summaryq" + kn },
                React.createElement("tr", null,
                    React.createElement("td", null, item.rok),
                    React.createElement("td", null, item.qualif_pub_liczba),
                    React.createElement("td", null, item.qualif_pub_slot_przel.toFixed(4)),
                    React.createElement("td", null, item.qualif_pub_pkt_calk.toFixed(4)),
                    React.createElement("td", null, item.qualif_pub_pkt_przel.toFixed(4))));
        });
        var finalLine = React.createElement(React.Fragment, { key: "summaryfinalLine" },
            React.createElement("tr", { className: "summaryRow" },
                React.createElement("td", null, "Razem"),
                React.createElement("td", null, p.liczba_pub),
                React.createElement("td", null, p.suma_slotow_przel.toFixed(4)),
                React.createElement("td", null, p.suma_pub_pkt_calk.toFixed(4)),
                React.createElement("td", null, p.suma_pub_pkt_przel.toFixed(4))));
        sums.push(finalLine);
        return React.createElement("div", { key: "disc_summary_" + this.props.setname, style: { marginBottom: '20pt' } },
            React.createElement(material_1.Typography, { variant: "h6", style: { color: 'green' } }, this.props.setname),
            tabela,
            React.createElement("div", { className: "summaryDiv" },
                React.createElement("div", { className: "groupOne" },
                    React.createElement("div", null,
                        "Suma wype\u0142nionych slot\u00F3w: ",
                        React.createElement("span", { className: "boldface" }, this.props.setres.slot_person_in_discipline.toFixed(4))),
                    React.createElement("div", null,
                        "Wolne sloty: ",
                        React.createElement("span", { className: "boldface" }, w.toFixed(4))),
                    React.createElement("div", null,
                        "Liczba punkt\u00F3w przeliczeniowych: ",
                        React.createElement("span", { className: "boldface" }, this.props.setres.points_person_in_discipline.toFixed(4))),
                    React.createElement("div", null,
                        "Liczba patent\u00F3w: ",
                        React.createElement("span", { className: "boldface" }, this.props.setres.patents_count)),
                    React.createElement("div", null,
                        "Suma puntk\u00F3w za patenty: ",
                        React.createElement("span", { className: "boldface" }, this.props.setres.patents_sum_points.toFixed(4)))),
                React.createElement("div", { className: "groupTwo" },
                    React.createElement("table", { className: "summaryGroup" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "Liczba publikacji"),
                                React.createElement("th", null, "Suma slot\u00F3w przeliczeniowych"),
                                React.createElement("th", null, "Suma pkt ca\u0142kowitych"),
                                React.createElement("th", null, "Suma pkt przeliczeniowych"))),
                        React.createElement("tbody", null, sums))),
                React.createElement("div", { className: "groupTwo", style: { marginTop: '10px' } },
                    React.createElement("table", { className: "summaryGroup" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Rok"),
                                React.createElement("th", null, "Liczba publikacji do ew."),
                                React.createElement("th", null, "Suma slot\u00F3w przeliczeniowych do ew."),
                                React.createElement("th", null, "Suma pkt ca\u0142kowitych pub. do ewal."),
                                React.createElement("th", null, "Suma pkt przeliczeniowych do ewal."))),
                        React.createElement("tbody", null, sums_ewal)))));
    };
    return EvaluationPanelResultSummary;
}(React.Component));
exports.EvaluationPanelResultSummary = EvaluationPanelResultSummary;
