"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./DanePolonTable.scss");
var DaneDoEwaulacji = /** @class */ (function (_super) {
    __extends(DaneDoEwaulacji, _super);
    function DaneDoEwaulacji(p) {
        return _super.call(this, p) || this;
    }
    DaneDoEwaulacji.prototype.render = function () {
        var _a, _b;
        var p1rows = (_b = (_a = this === null || this === void 0 ? void 0 : this.props) === null || _a === void 0 ? void 0 : _a.disp) === null || _b === void 0 ? void 0 : _b.map(function (r, i) {
            var _a;
            var factor = (r.execdate < '2022-01-01') ? 0.8 : 1.0;
            var p2rows = r.dane.map(function (s, k) {
                return React.createElement(React.Fragment, { key: "danewyzntable_s_" + k.toString() },
                    React.createElement("td", null, s.dyscyplina),
                    React.createElement("td", null, s.wielkosc_etatu),
                    React.createElement("td", null, s.prc_dysc == null ? "" : s.prc_dysc.toString() + " %"),
                    React.createElement("td", null, factor * s.slot_w_dyscyplinie));
            });
            if (((_a = p2rows === null || p2rows === void 0 ? void 0 : p2rows.length) !== null && _a !== void 0 ? _a : 0) == 0) {
                var p4 = React.createElement(React.Fragment, { key: "danewyzntable_rs_" + i.toString() },
                    React.createElement("td", { colSpan: 4, className: "red" }, "Brak zaliczania do N"));
                p2rows = [p4];
            }
            return (p2rows.map(function (t, ii) {
                return (React.createElement("tr", { key: r.execdate + ii.toString() },
                    React.createElement("td", null, r.execdate.substring(0, 4)),
                    t));
            }));
        });
        return (React.createElement("div", null,
            React.createElement("table", { className: "danewyzntable" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "danewyzntable_r1" },
                        React.createElement("td", { colSpan: 5, className: "centertab theader" }, "Wyznaczone sloty publikacyjne")),
                    React.createElement("tr", { key: "danewyzntable_r2" },
                        React.createElement("th", null, "Rok"),
                        React.createElement("th", null, "Dyscyplina"),
                        React.createElement("th", null, "Wymiar etatu"),
                        React.createElement("th", null, "Udzia\u0142 procentowy"),
                        React.createElement("th", null, "Slot publikacyjny"))),
                React.createElement("tbody", null, p1rows))));
    };
    return DaneDoEwaulacji;
}(React.Component));
exports.default = DaneDoEwaulacji;
