"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./DanePolonTable.scss");
var DanePolonZatrudnienie = /** @class */ (function (_super) {
    __extends(DanePolonZatrudnienie, _super);
    function DanePolonZatrudnienie(p) {
        return _super.call(this, p) || this;
    }
    DanePolonZatrudnienie.prototype.render = function () {
        var _a, _b, _c;
        var wiersze = (_b = (_a = this === null || this === void 0 ? void 0 : this.props) === null || _a === void 0 ? void 0 : _a.disp) === null || _b === void 0 ? void 0 : _b.map(function (r) {
            return (React.createElement("tr", { key: r.polonemployeeconditionsid },
                React.createElement("td", null, r.datefrom),
                React.createElement("td", null, r.dateto),
                React.createElement("td", null, r.positionname),
                React.createElement("td", null, r.positiongroupname),
                React.createElement("td", null,
                    r.workinghoursnumerator,
                    "/",
                    r.workinghoursdenominator,
                    " (",
                    r.workhours,
                    ")"),
                React.createElement("td", null, r.institutionfullname)));
        });
        if (((_c = wiersze === null || wiersze === void 0 ? void 0 : wiersze.length) !== null && _c !== void 0 ? _c : 0) == 0)
            wiersze = [React.createElement("tr", { key: "polonemployeeconditionsid_one_row" },
                    React.createElement("td", { colSpan: 6, className: "centertab red" }, "Brak informacji o zatrudnieniu"))];
        return (React.createElement("div", null,
            React.createElement("table", { className: "tablepolonzatr" },
                React.createElement("thead", null,
                    React.createElement("tr", { key: "polonemployeeconditionsid_one_hdr" },
                        React.createElement("td", { colSpan: 6, className: "centertab theader" }, "POLON - historia zatrudnienia")),
                    React.createElement("tr", { key: "polonemployeeconditionsid_two_row" },
                        React.createElement("th", null, "Data od"),
                        React.createElement("th", null, "Data do"),
                        React.createElement("th", null, "Stanowisko"),
                        React.createElement("th", null, "Grupa"),
                        React.createElement("th", null, "Wymiar etatu"),
                        React.createElement("th", null, "Jednostka"))),
                React.createElement("tbody", null, wiersze))));
    };
    return DanePolonZatrudnienie;
}(React.Component));
exports.default = DanePolonZatrudnienie;
