"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrgUnitSummary = exports.OrgUnitDisplayDataInDisc = void 0;
var material_1 = require("@mui/material");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var EvaluationPanelResultPerson_1 = require("./EvaluationPanelResultPerson");
var PersonHeader = function (props) {
    return React.createElement(material_1.Typography, { variant: "h6" },
        props.person.imie,
        " ",
        props.person.nazwisko);
};
var OrgUnitSummary = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var sorted = Object.keys(props.display.yrstat).map(function (v) { return parseInt(v); }).filter(function (x) { return (!isNaN(x)) && (x > 2000); }).sort();
    var p = { rok: -1, liczba_pub: 0.0, suma_pub_pkt_calk: 0.0, suma_pub_pkt_przel: 0.0, suma_slotow_przel: 0.0,
        qualif_pub_liczba: 0, qualif_pub_pkt_calk: 0.0, qualif_pub_pkt_przel: 0.0, qualif_pub_slot_przel: 0.0 };
    var sums = sorted.map(function (kn) {
        var item = props.display.yrstat[kn];
        p.liczba_pub += item.liczba_pub;
        p.suma_pub_pkt_calk += item.suma_pub_pkt_calk;
        p.suma_pub_pkt_przel += item.suma_pub_pkt_przel;
        p.suma_slotow_przel += item.suma_slotow_przel;
        return React.createElement(React.Fragment, { key: "summary" + kn },
            React.createElement("tr", null,
                React.createElement("td", null, item.rok),
                React.createElement("td", null, item.liczba_pub),
                React.createElement("td", null, item.suma_slotow_przel.toFixed(4)),
                React.createElement("td", null, item.suma_pub_pkt_calk.toFixed(4)),
                React.createElement("td", null, item.suma_pub_pkt_przel.toFixed(4))));
    });
    var finalLine = React.createElement(React.Fragment, { key: "summaryfinalLine" },
        React.createElement("tr", { className: "summaryRow" },
            React.createElement("td", null, "Razem"),
            React.createElement("td", null, p.liczba_pub),
            React.createElement("td", null, p.suma_slotow_przel.toFixed(4)),
            React.createElement("td", null, p.suma_pub_pkt_calk.toFixed(4)),
            React.createElement("td", null, p.suma_pub_pkt_przel.toFixed(4))));
    sums.push(finalLine);
    return React.createElement("div", null,
        React.createElement("div", null,
            "Wype\u0142nionych slot\u00F3w w dysycplinie: ",
            React.createElement("span", { className: "boldface" }, (_c = (_b = (_a = props.display) === null || _a === void 0 ? void 0 : _a.slot_person_in_discipline) === null || _b === void 0 ? void 0 : _b.toFixed(4)) !== null && _c !== void 0 ? _c : ""),
            " z uwzgl\u0119dnieniem limit\u00F3w na osob\u0119"),
        React.createElement("div", null,
            "Suma punkt\u00F3w wype\u0142nionych slot\u00F3w: ",
            React.createElement("span", { className: "boldface" }, (_f = (_e = (_d = props.display) === null || _d === void 0 ? void 0 : _d.points_person_in_discipline) === null || _e === void 0 ? void 0 : _e.toFixed(4)) !== null && _f !== void 0 ? _f : "")),
        React.createElement("div", null,
            "Pozosta\u0142o slot\u00F3w do wype\u0142nienia: ",
            React.createElement("span", { className: "boldface" }, (_j = (_h = (_g = props.display) === null || _g === void 0 ? void 0 : _g.remain_slot_person) === null || _h === void 0 ? void 0 : _h.toFixed(4)) !== null && _j !== void 0 ? _j : "")),
        React.createElement("div", null,
            "Punktacja za poszczeg\u00F3lne lata:",
            React.createElement("div", { className: "groupTwo" },
                React.createElement("table", { className: "summaryGroup" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Rok"),
                            React.createElement("th", null, "Liczba publikacji"),
                            React.createElement("th", null, "Suma slot\u00F3w przeliczeniowych"),
                            React.createElement("th", null, "Suma pkt ca\u0142kowitych"),
                            React.createElement("th", null, "Suma pkt przeliczeniowych"))),
                    React.createElement("tbody", null, sums)))));
};
exports.OrgUnitSummary = OrgUnitSummary;
var OrgUnitDisplayDataInDisc = /** @class */ (function (_super) {
    __extends(OrgUnitDisplayDataInDisc, _super);
    function OrgUnitDisplayDataInDisc(p) {
        var _this = _super.call(this, p) || this;
        _this.state = {};
        return _this;
    }
    OrgUnitDisplayDataInDisc.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        var rows = (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.container) === null || _b === void 0 ? void 0 : _b.osoby) === null || _c === void 0 ? void 0 : _c.map(function (elem, i) {
            return React.createElement("div", { key: "phblock_" + i, style: { border: "1px solid", borderColor: "black", margin: "5px" } },
                React.createElement(PersonHeader, { person: elem.person }),
                React.createElement(EvaluationPanelResultPerson_1.default, __assign({ key: "person_" + i, shares: elem.result, limitForOneDiscipline: _this.props.discipline, currentPersonId: elem.person }, _this.props)));
        });
        return React.createElement("div", { key: "unique2" },
            React.createElement(material_1.Typography, { variant: "h5", color: "secondary", gutterBottom: true },
                this.props.container.nazwa,
                " (",
                this.props.container.kod_jedn,
                ") \u2013 ",
                this.props.discipline_name),
            rows,
            React.createElement(material_1.Typography, { variant: "h6" },
                "Podsumowanie ",
                this.props.container.nazwa,
                " w dyscypline ",
                this.props.discipline_name),
            React.createElement(OrgUnitSummary, { display: (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.container) === null || _e === void 0 ? void 0 : _e.statystyka }),
            React.createElement("div", { style: { textAlign: 'center', textAlignLast: 'center' } },
                this.props.displayDownloadButton == 1 ? React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/api/v2/evaluationDiscResultFile?disc=" + this.props.discipline + "&period=" + this.props.periodCode, color: "primary", variant: "outlined", target: "_blank" }, "Pobierz jako XLSX") : null,
                this.props.displayDownloadButton == 2 ? React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/api/v2/evaluationUnitResultFile?unit=" + this.props.currentUnitCode + "&period=" + this.props.periodCode, color: "primary", variant: "outlined", target: "_blank" }, "Pobierz jako XLSX") : null,
                "\u00A0",
                React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika")));
    };
    return OrgUnitDisplayDataInDisc;
}(React.Component));
exports.OrgUnitDisplayDataInDisc = OrgUnitDisplayDataInDisc;
