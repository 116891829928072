"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalReloadMainPage = void 0;
var React = require("react");
var ReactDOM = require("react-dom");
var react_router_dom_1 = require("react-router-dom");
var CASPRzLogin_1 = require("./CASPRzLogin");
var DashboardRoutes_1 = require("./DashboardRoutes");
var PRzInfoPanel_1 = require("./danePanel/PRzInfoPanel");
var AdmPanelPRzInfoPanel_1 = require("./danePanel/AdmPanelPRzInfoPanel");
var AdmPatentyPanel_1 = require("./danePanel/AdmPatentyPanel");
var AdmPublikacjePanel_1 = require("./danePanel/AdmPublikacjePanel");
var FormAppAccounts_1 = require("./forms/FormAppAccounts");
var FormAppAccountsAddEdit_1 = require("./forms/FormAppAccountsAddEdit");
var FormAppAccountsImportDN10_1 = require("./forms/FormAppAccountsImportDN10");
var FormDeleteAppAccountDialog_1 = require("./forms/FormDeleteAppAccountDialog");
var AdmRaportyPanel_1 = require("./danePanel/AdmRaportyPanel");
var PublicationPanelEvalAssignment_1 = require("./danePanel/PublicationPanelEvalAssignment");
var PublicationPanelEvalReport_1 = require("./danePanel/PublicationPanelEvalReport");
var material_1 = require("@mui/material");
var AdmZestawieniaOkresowe_1 = require("./danePanel/AdmZestawieniaOkresowe");
var DisciplineInfoPanel_1 = require("./danePanel/DisciplineInfoPanel");
var ErrorBoundary_1 = require("./ErrorBoundary");
var OrgUnitInfoPanel_1 = require("./danePanel/OrgUnitInfoPanel");
function isAuth(o) {
    var a = o.physUserName;
    var b = o.loggedUserName;
    var c = o.formToken;
    var d = o.attrs;
    // console.log("atrybuty: " + JSON.stringify(d) + "; token: " + c);
    return (a !== null && b != null && typeof a === "string" && typeof b === "string" && a.length > 0 && b.length > 0);
}
function globalReloadMainPage() {
    if (document.getElementById('app')) {
        ReactDOM.render(React.createElement(MainPanel, null), document.getElementById('app'));
    }
}
exports.globalReloadMainPage = globalReloadMainPage;
function MainPanel() {
    var authState = React.useState({ physUserName: null, loggedUserName: null, formToken: null, attrs: null });
    var isAutheticated = authState[0], setisAutheticated = authState[1];
    var w = !isAuth(isAutheticated);
    var calcHost = window.location.hostname.split('.').reverse();
    var m = "";
    [String.fromCharCode(100), String.fromCharCode(97), 's', 'j'].forEach(function (element) {
        m = element.concat(m);
    });
    m = m + "@";
    calcHost = calcHost.slice(0, 3);
    calcHost.reverse().forEach(function (element) {
        m = m + element + " ";
    });
    m = m.trimRight();
    for (var i = 0; i < calcHost.length; i++) {
        m = m.replace(" ", ".");
    }
    return (React.createElement("div", null,
        React.createElement(ErrorBoundary_1.default, null,
            React.createElement(CASPRzLogin_1.CASPRzLogin, { authManager: authState, reloader: globalReloadMainPage }),
            React.createElement(react_router_dom_1.BrowserRouter, null,
                React.createElement(react_router_dom_1.Switch, null,
                    React.createElement(react_router_dom_1.Route, { path: "/dashboard", render: function () { return (w ? React.createElement("div", null,
                            "Brak dost\u0119pu bez logowania \u2013 ",
                            React.createElement("a", { href: "/api/login" }, "Zaloguj")) : React.createElement(DashboardRoutes_1.default, null)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/poloninfopanel", render: function (routeProps) { return React.createElement(PRzInfoPanel_1.default, __assign({}, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/poloninfopnl", render: function (routeProps) { return React.createElement(AdmPanelPRzInfoPanel_1.AdmPanelPRzInfoPanel, __assign({}, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/publicationpanelevalasg", exact: true, render: function (routeProps) {
                            return React.createElement(PublicationPanelEvalAssignment_1.default, __assign({}, routeProps));
                        } }),
                    React.createElement(react_router_dom_1.Route, { path: "/publicationpanelevalrep", exact: true, render: function (routeProps) {
                            return React.createElement(PublicationPanelEvalReport_1.default, __assign({}, routeProps));
                        } }),
                    React.createElement(react_router_dom_1.Route, { path: "/discsuper/info", exact: true, render: function (routeProps) { return React.createElement(DisciplineInfoPanel_1.DisciplineInfoPanel, __assign({}, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/unitsuper/info", exact: true, render: function (routeProps) { return React.createElement(OrgUnitInfoPanel_1.OrgUnitInfoPanel, __assign({}, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/patenty", render: function (routeProps) { return React.createElement(AdmPatentyPanel_1.default, __assign({}, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/publikacje", render: function (routeProps) { return React.createElement(AdmPublikacjePanel_1.default, __assign({ changeDiscMode: false }, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/zmianaprzypisan", render: function (routeProps) { return React.createElement(AdmPublikacjePanel_1.default, __assign({ changeDiscMode: true }, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/raporty", render: function (routeProps) { return React.createElement(AdmRaportyPanel_1.default, __assign({}, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/testpanel" }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/AppAccounts/Add/:template_row?", exact: true, render: function (routeProps) { return React.createElement(FormAppAccountsAddEdit_1.default, __assign({ mode: FormAppAccounts_1.EditButtons.ebAdd, initValue: routeProps.match.params.template_row }, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/AppAccounts/Edit/:edit_row", exact: true, render: function (routeProps) { return React.createElement(FormAppAccountsAddEdit_1.default, __assign({ mode: FormAppAccounts_1.EditButtons.ebEdit, initValue: routeProps.match.params.edit_row }, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/AppAccounts/Delete/:delete_row", exact: true, render: function (routeProps) { return React.createElement(FormDeleteAppAccountDialog_1.FormDeleteAppAccountDialog, __assign({ operationValue: FormAppAccounts_1.EditButtons.ebDelete, actionValue: routeProps.match.params.delete_row }, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/AppAccounts/ImportDN10", render: function (routeProps) { return React.createElement(FormAppAccountsImportDN10_1.FormAppAccountsImportDN10, __assign({}, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/AppAccounts", exact: true, render: function (routeProps) { return React.createElement(React.Fragment, { key: "rf1" },
                            React.createElement(FormAppAccounts_1.default, __assign({ onSelectCallback: function (a, b) { }, editButtons: [FormAppAccounts_1.EditButtons.ebAdd, FormAppAccounts_1.EditButtons.ebDelete, FormAppAccounts_1.EditButtons.ebEdit, FormAppAccounts_1.EditButtonsAppAccounts.ebImportDN10] }, routeProps)),
                            React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika")); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/admin/zestawieniaokresowe", render: function (routeProps) { return React.createElement(AdmZestawieniaOkresowe_1.AdmZestawieniaOkresowe, __assign({}, routeProps)); } }),
                    React.createElement(react_router_dom_1.Route, { path: "/", render: function () { return React.createElement("div", null,
                            React.createElement("h1", { style: { textAlign: "center" } }, "Dorobek Naukowy 2.0 \u2014 Politechnika Rzeszowska"),
                            React.createElement("div", { className: "defaultLargeInfo" },
                                "System s\u0142u\u017Cy do prezentacji dorobku naukowego pracownik\u00F3w PRz podlegaj\u0105cych ewaluacji dyscypliny. Zalogowani u\u017Cytkownicy mog\u0105 przej\u015B\u0107 do ",
                                React.createElement(react_router_dom_1.NavLink, { exact: true, to: "/dashboard" }, "panelu u\u017Cytkownika"),
                                " (menu logowania znajduje si\u0119 na g\u00F3rze strony po prawej)."),
                            React.createElement("div", { id: "uwagiDoSystemu" },
                                "Uwagi, b\u0142\u0119dy i sugestie dotycz\u0105ce systemu mo\u017Cna kierowa\u0107 na adres ",
                                w ? React.createElement("span", null, "(widoczny w tym miejscu po zalogowaniu)") : React.createElement("a", { href: "mailto:" + m }, m),
                                "."),
                            React.createElement("h2", { style: { color: "red" } }, "Zg\u0142aszanie publikacji do dorobku naukowego"),
                            React.createElement("div", null,
                                "Zgodnie z za\u0142\u0105cznikiem do zarz\u0105dzenia nr 102/2020 Rektora PRz z dnia 14 pa\u017Adziernika 2020\u00A0r. pkt 4 i 5:",
                                React.createElement("blockquote", null,
                                    React.createElement("ol", { start: 4 },
                                        React.createElement("li", null,
                                            React.createElement("b", null, "Autor zg\u0142asza osi\u0105gni\u0119cia przez formularz dost\u0119pny na stronie macierzystego wydzia\u0142u"),
                                            " (dost\u0119p po zalogowaniu). Ka\u017Cde osi\u0105gni\u0119cie wymaga odr\u0119bnego zg\u0142oszenia (zg\u0142oszenia zawieraj\u0105ce informacje o wi\u0119cej ni\u017C jednym osi\u0105gni\u0119ciu nie b\u0119d\u0105 rozpatrywane)."),
                                        React.createElement("li", null, "W\u00A0przypadku osi\u0105gnie\u0107 wieloautorskich zg\u0142oszenie wysy\u0142a tylko jeden autor (w uzgodnieniu z pozosta\u0142ymi autorami)."))))); } }))))));
}
exports.default = MainPanel;
globalReloadMainPage();
