"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdmZestawieniaOkresowe = void 0;
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var material_1 = require("@mui/material");
var axios = require("axios");
var x_data_grid_1 = require("@mui/x-data-grid");
var material_2 = require("@mui/material");
var detailCols = [
    { field: 'id', headerName: 'ID', width: 90, type: "number" },
    { field: 'discipline_name', headerName: 'Nazwa dyscypliny', width: 250 },
    { field: 'value_n', headerName: 'Liczba N', width: 90, type: "number" },
    { field: 'sum_qualified_slots', headerName: 'Suma wypełnionych slotów', width: 90, type: "number" },
    { field: 'sum_qualified_points', headerName: 'Suma punktów wypełnionych slotów', width: 180, type: "number" },
    { field: 'patents_count', headerName: 'Liczba patentów', width: 120, type: "number" },
    { field: 'patents_sum_points', headerName: 'Suma punktów za patenty', width: 120, type: "number" },
    { field: 'total_slots', headerName: 'Liczba slotów w dysc.', width: 120, type: "number" },
    { field: 'total_collected_points', headerName: 'Suma pkt za wszystkie publikacje', width: 120, type: "number" },
    { field: 'total_collected_p_points', headerName: 'Suma pkt przelicz. za wszystkie publikacje', width: 120, type: "number" },
    { field: 'total_collected_p_slots', headerName: 'Suma slotów przelicz. za wszystkie publikacje', width: 120, type: "number" },
    { field: 'total_number_shares', headerName: 'Liczba udziałów', width: 90, type: "number" },
];
var ZestawienieWynik = function (p) {
    var _a, _b, _c, _d, _e, _f;
    var _g = React.useState({ selflt: false }), cv = _g[0], scv = _g[1];
    if ((_b = (_a = p.v) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) {
        var r = void 0;
        if (p.g != null)
            r = p.g.id;
        else
            r = 0;
        return React.createElement(React.Fragment, { key: "row" + r },
            React.createElement("div", { className: "reportResultSet" },
                React.createElement(material_1.Typography, { variant: "h6" },
                    "Zestawienie id ",
                    r,
                    ": ", (_d = (_c = p === null || p === void 0 ? void 0 : p.g) === null || _c === void 0 ? void 0 : _c.result_name) !== null && _d !== void 0 ? _d : "",
                    " z dnia ", (_f = (_e = p === null || p === void 0 ? void 0 : p.g) === null || _e === void 0 ? void 0 : _e.request_stamp) !== null && _f !== void 0 ? _f : ""),
                React.createElement(x_data_grid_1.DataGrid, { rows: p.v, columns: detailCols, pageSize: 20, rowsPerPageOptions: [20], isRowSelectable: function (params) { return false; }, disableSelectionOnClick: true, autoHeight: true, autoPageSize: true, paginationMode: "client", filterMode: "client" }),
                React.createElement(react_router_dom_1.Link, { to: "/api/admin/RaportyZestawieniaPobierz?numrap=" + r.toString(), target: "_blank" }, "Pobierz zestawienie (bez wierszy o N < 12)")));
    }
    else
        return null;
};
var AdmZestawieniaOkresowe = /** @class */ (function (_super) {
    __extends(AdmZestawieniaOkresowe, _super);
    function AdmZestawieniaOkresowe(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { zestawienia: null, error: null, wyniki: null, expandInput: false, nowaNazwa: "",
            nowyRokOd: "", nowyRokDo: "", nowyRokOdError: false, nowyRokDoError: false,
            nowyRokOdHelper: "", nowyRokDoHelper: "" };
        _this.dispCols = [
            { field: 'id', headerName: 'ID', width: 90, type: "number" },
            { field: 'result_name', headerName: 'Nazwa', width: 250 },
            { field: 'year_from', headerName: 'Rok od', width: 90, type: "number" },
            { field: 'year_to', headerName: 'Rok do', width: 90, type: "number" },
            { field: 'request_stamp', headerName: 'Data zlecenia', width: 180 },
            { field: 'generated_stamp', headerName: 'Data realizacji', width: 180 },
            { field: 'current_state', headerName: 'Status', width: 100, type: "number", description: '0 - zlecone, 1 - przetwarzane, 2 - zakończone' }
        ];
        return _this;
    }
    AdmZestawieniaOkresowe.prototype.componentDidMount = function () {
        this.downloadReports(this);
    };
    AdmZestawieniaOkresowe.prototype.downloadReports = function (self) {
        axios.default.get("/api/admin/RaportyZestawienia").then(function (v) {
            self.setState({ zestawienia: v.data, error: null });
        }).catch(function (err) {
            self.setState({ error: err.message });
        });
    };
    AdmZestawieniaOkresowe.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        var grid;
        if (((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.zestawienia) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) == 0)
            grid = React.createElement(React.Fragment, { key: "dispgrid" });
        else
            grid = React.createElement(React.Fragment, { key: "dispgrid" },
                React.createElement(x_data_grid_1.DataGrid, { rows: this.state.zestawienia, columns: this.dispCols, pageSize: 10, rowsPerPageOptions: [10], isRowSelectable: function (params) { return true; }, disableSelectionOnClick: false, autoHeight: true, autoPageSize: true, paginationMode: "client", filterMode: "client", onSelectionModelChange: function (newSel, p) { _this.setSelectedRow(newSel); } }));
        var errmsg = (((_d = this === null || this === void 0 ? void 0 : this.state) === null || _d === void 0 ? void 0 : _d.error) != null) ? React.createElement(material_2.Alert, { severity: "error" }, "this.state.error") : null;
        var selDetails = (((_g = (_f = (_e = this === null || this === void 0 ? void 0 : this.state) === null || _e === void 0 ? void 0 : _e.wyniki) === null || _f === void 0 ? void 0 : _f.length) !== null && _g !== void 0 ? _g : 0) == 0) ? null : this.state.wyniki.map(function (value, i) {
            var _a;
            var r = (((_a = value === null || value === void 0 ? void 0 : value.length) !== null && _a !== void 0 ? _a : 0) == 0) ? null : value[0].simulation_num;
            var grp;
            if (r != null)
                grp = _this.state.zestawienia.find(function (a) { return a.id == r; });
            else
                grp = null;
            return React.createElement(ZestawienieWynik, { v: value, g: grp, key: "ZestawienieWynik" + i });
        });
        var expandingAdder;
        if (this.state.expandInput) {
            expandingAdder = React.createElement(React.Fragment, { key: "expandingAdder" },
                React.createElement(material_1.TextField, { style: { minWidth: '20%' }, id: "Nazwa", label: "Nazwa", value: this.state.nowaNazwa, onChange: function (e) { return _this.updatePartComponent(_this, e); } }),
                React.createElement(material_1.TextField, { style: { minWidth: '10%' }, id: "RokOd", label: "Rok od:", value: this.state.nowyRokOd, onChange: function (e) { return _this.updatePartComponent(_this, e); }, required: true, helperText: this.state.nowyRokOdHelper, error: this.state.nowyRokOdError }),
                React.createElement(material_1.TextField, { style: { minWidth: '10%' }, id: "RokDo", label: "Rok do:", value: this.state.nowyRokDo, onChange: function (e) { return _this.updatePartComponent(_this, e); }, required: true, helperText: this.state.nowyRokDoHelper, error: this.state.nowyRokDoError }),
                React.createElement(material_1.Button, { variant: "contained", onClick: function () { _this.acceptAndSendToServer(_this); } }, "Zatwierd\u017A"));
        }
        else
            expandingAdder = null;
        return React.createElement(React.Fragment, { key: "panelzestawieniaokresowe" },
            React.createElement(React.StrictMode, null,
                errmsg,
                React.createElement(material_1.Typography, { variant: "h6" }, "Lista zleconych raport\u00F3w"),
                grid,
                React.createElement(material_1.Button, { variant: "contained", onClick: function () { _this.prepareAreaForNewReport(_this); } }, "Nowe zlecenie"),
                React.createElement(material_1.Button, { variant: "contained", onClick: function () { _this.downloadReports(_this); } }, "Od\u015Bwie\u017C"),
                expandingAdder,
                selDetails,
                React.createElement("div", { style: { textAlign: 'center', textAlignLast: 'center' } },
                    React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika"),
                    React.createElement(material_1.Button, { component: react_router_dom_1.Link, to: "/admin/raporty", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu raport\u00F3w"))));
    };
    AdmZestawieniaOkresowe.prototype.setSelectedRow = function (rws) {
        var _this = this;
        axios.default.post("/api/admin/RaportyZestawieniaResults", { ids: rws }).then(function (v) {
            _this.setState({ wyniki: v.data, error: null });
        }).catch(function (err) {
            _this.setState({ error: err.message });
        });
    };
    AdmZestawieniaOkresowe.prototype.prepareAreaForNewReport = function (self) {
        self.setState({ expandInput: true });
    };
    AdmZestawieniaOkresowe.prototype.updatePartComponent = function (self, e) {
        switch (e.target.id) {
            case "Nazwa":
                self.setState({ nowaNazwa: e.target.value });
                break;
            case "RokOd":
                {
                    var correct = parseInt(e.target.value);
                    var msg = correct ? "" : "Tekst nie jest liczbą";
                    self.setState({ nowyRokOd: e.target.value, nowyRokOdError: !correct, nowyRokOdHelper: msg });
                }
                break;
            case "RokDo":
                {
                    var correct = parseInt(e.target.value);
                    var msg = correct ? "" : "Tekst nie jest liczbą";
                    self.setState({ nowyRokDo: e.target.value, nowyRokDoError: !correct, nowyRokDoHelper: msg });
                }
                break;
        }
    };
    AdmZestawieniaOkresowe.prototype.acceptAndSendToServer = function (self) {
        if (self.state.nowyRokDoError || self.state.nowyRokOdError)
            return;
        axios.default.post("/api/admin/RaportyZestawieniaDodaj", { nreq: self.state.nowaNazwa, nod: self.state.nowyRokOd, ndo: self.state.nowyRokDo }).then(function (v) {
            self.setState({ nowaNazwa: "", nowyRokOd: "", nowyRokDo: "", expandInput: false });
            self.downloadReports(self);
        }).catch(function (err) {
            self.setState({ error: err.message });
        });
    };
    return AdmZestawieniaOkresowe;
}(React.Component));
exports.AdmZestawieniaOkresowe = AdmZestawieniaOkresowe;
