"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var react_router_1 = require("react-router");
var axios = require("axios");
var material_1 = require("@mui/material");
var Button_1 = require("@mui/material/Button");
var PublicationPanelByPubKind_1 = require("./PublicationPanelByPubKind");
var FormSelectAvailEvalPeriods_1 = require("../forms/FormSelectAvailEvalPeriods");
;
;
var PublicationPanelEvalAssignment = /** @class */ (function (_super) {
    __extends(PublicationPanelEvalAssignment, _super);
    function PublicationPanelEvalAssignment(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { data: null, errorMessage: null, showIndicator: false };
        return _this;
    }
    PublicationPanelEvalAssignment.prototype.componentDidMount = function () {
    };
    PublicationPanelEvalAssignment.prototype.comboSelectedItemChanged = function (item) {
        var _this = this;
        this.setState({ data: null, showIndicator: true });
        axios.default.post("/api/v2/SlotFillData", { selperiod: parseInt(item) }).then(function (res) {
            _this.setState({ data: res.data });
        }).catch(function (err) {
            _this.setState({ data: null, errorMessage: err.message });
        });
    };
    PublicationPanelEvalAssignment.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        if ((_a = this === null || this === void 0 ? void 0 : this.state) === null || _a === void 0 ? void 0 : _a.errorMessage) {
            return React.createElement(React.Fragment, { key: "unique1" },
                React.createElement(material_1.Alert, { severity: "error" },
                    "B\u0142\u0105d pobierania informacji o u\u017Cytkowniku: '",
                    this.state.errorMessage,
                    "'."),
                React.createElement(react_router_1.Redirect, { to: "/dashboard" }));
        }
        var dspPanel;
        if ((_c = (_b = this === null || this === void 0 ? void 0 : this.state) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.shares) {
            dspPanel = React.createElement(PublicationPanelByPubKind_1.default, __assign({ presentData: this.state.data.shares.dorobek, userDisciplines: this.state.data.shares.dyscypliny, userId: this.state.data.shares.idPracownika, messageData: this.state.data.shares.error_desc }, this.props));
        }
        else {
            if (this.state.showIndicator)
                dspPanel = React.createElement("div", null, "Trwa \u0142adowanie publikacji...");
            else
                dspPanel = null;
        }
        return React.createElement(React.Fragment, { key: "unique1" },
            React.createElement(FormSelectAvailEvalPeriods_1.FormSelectAvailEvalPeriods, __assign({ callback: function (frm, val) { return _this.comboSelectedItemChanged(val); } }, this.props)),
            dspPanel,
            React.createElement("div", { style: { textAlign: 'center', textAlignLast: 'center' } },
                React.createElement(Button_1.default, { component: react_router_dom_1.Link, to: "/dashboard", color: "primary", variant: "outlined" }, "Powr\u00F3t do panelu u\u017Cytkownika")));
    };
    return PublicationPanelEvalAssignment;
}(React.Component));
exports.default = PublicationPanelEvalAssignment;
