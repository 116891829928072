"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ExplainPublicationReject = function (props) {
    if (props.rowsnum > 1) {
        return React.createElement("div", null,
            "Kody nieuwzgl\u0119dnienia publikacji:",
            React.createElement("br", null),
            React.createElement("span", { className: "rejectCodeValue" }, "R01"),
            " - osi\u0105gni\u0119to limit na monografie/rozdzia\u0142y/redakcje",
            React.createElement("br", null),
            React.createElement("span", { className: "rejectCodeValue" }, "R02"),
            " - przekroczenie maksimum wype\u0142nionych slot\u00F3w",
            React.createElement("br", null),
            React.createElement("span", { className: "rejectCodeValue" }, "R03"),
            " - brak zaliczania do N w roku publikacji",
            React.createElement("br", null),
            React.createElement("span", { className: "rejectCodeValue" }, "R04"),
            " - publikacja oczekuje na zatwierdzenie przez bibliotek\u0119",
            React.createElement("br", null),
            React.createElement("span", { className: "rejectCodeValue" }, "R05"),
            " - publikacja oczekuje na ponowne przeliczenie",
            React.createElement("br", null),
            React.createElement("span", { className: "rejectCodeValue" }, "R06"),
            " - brak udzia\u0142u we wskazanej dyscyplinie w tym okresie");
    }
    else {
        return React.createElement("div", null);
    }
};
exports.default = ExplainPublicationReject;
