"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./DanePolonTable.scss");
var DanePRz = /** @class */ (function (_super) {
    __extends(DanePRz, _super);
    function DanePRz(p) {
        return _super.call(this, p) || this;
    }
    DanePRz.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return (React.createElement("div", null,
            React.createElement("table", { className: "daneprztable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: 2, className: "centertab theader" }, "Informacje z systemu CAS PRz"))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Imi\u0119:"),
                        React.createElement("td", null, (_b = (_a = this === null || this === void 0 ? void 0 : this.props) === null || _a === void 0 ? void 0 : _a.disp) === null || _b === void 0 ? void 0 : _b.imie)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Nazwisko:"),
                        React.createElement("td", null, (_d = (_c = this === null || this === void 0 ? void 0 : this.props) === null || _c === void 0 ? void 0 : _c.disp) === null || _d === void 0 ? void 0 : _d.nazwisko)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Tytu\u0142/Stopie\u0144:"),
                        React.createElement("td", null, (_f = (_e = this === null || this === void 0 ? void 0 : this.props) === null || _e === void 0 ? void 0 : _e.disp) === null || _f === void 0 ? void 0 : _f.stopien)),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Jednostka:"),
                        React.createElement("td", null, (_h = (_g = this === null || this === void 0 ? void 0 : this.props) === null || _g === void 0 ? void 0 : _g.disp) === null || _h === void 0 ? void 0 :
                            _h.depart,
                            " (", (_k = (_j = this === null || this === void 0 ? void 0 : this.props) === null || _j === void 0 ? void 0 : _j.disp) === null || _k === void 0 ? void 0 :
                            _k.jedn_kod,
                            ")")),
                    React.createElement("tr", null,
                        React.createElement("th", null, "Identyfikator POLON:"),
                        React.createElement("td", null, (_m = (_l = this === null || this === void 0 ? void 0 : this.props) === null || _l === void 0 ? void 0 : _l.disp) === null || _m === void 0 ? void 0 : _m.polonuuid))))));
    };
    return DanePRz;
}(React.Component));
exports.default = DanePRz;
