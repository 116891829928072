"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var axios = require("axios");
var material_1 = require("@mui/material");
var Tooltip_1 = require("@mui/material/Tooltip");
;
var PublicationComplianceCheckerStateValues;
(function (PublicationComplianceCheckerStateValues) {
    PublicationComplianceCheckerStateValues[PublicationComplianceCheckerStateValues["pccsvUndefined"] = 0] = "pccsvUndefined";
    PublicationComplianceCheckerStateValues[PublicationComplianceCheckerStateValues["pccsvInProgress"] = 1] = "pccsvInProgress";
    PublicationComplianceCheckerStateValues[PublicationComplianceCheckerStateValues["pccsvMatched"] = 2] = "pccsvMatched";
    PublicationComplianceCheckerStateValues[PublicationComplianceCheckerStateValues["pccsvUnmatched"] = 3] = "pccsvUnmatched";
    PublicationComplianceCheckerStateValues[PublicationComplianceCheckerStateValues["pccsvNotApplicable"] = 4] = "pccsvNotApplicable";
    PublicationComplianceCheckerStateValues[PublicationComplianceCheckerStateValues["pccsvError"] = 5] = "pccsvError";
})(PublicationComplianceCheckerStateValues || (PublicationComplianceCheckerStateValues = {}));
;
;
var PublicationComplianceChecker = /** @class */ (function (_super) {
    __extends(PublicationComplianceChecker, _super);
    function PublicationComplianceChecker(p) {
        var _this = _super.call(this, p) || this;
        _this.state = { checkerState: PublicationComplianceCheckerStateValues.pccsvUndefined, errorMessage: null, response: null };
        return _this;
    }
    PublicationComplianceChecker.prototype.componentDidMount = function () {
        this.makeCallApiChecker();
    };
    PublicationComplianceChecker.prototype.componentDidUpdate = function (prevProps, prevState) {
        if ((prevProps.author != this.props.author) || (prevProps.discipline != this.props.discipline) || (prevProps.idPub != this.props.idPub)) {
            this.makeCallApiChecker();
        }
    };
    PublicationComplianceChecker.prototype.makeCallApiChecker = function () {
        var _this = this;
        if ((this.props.discipline == null) || (this.props.discipline == 0)) {
            this.setState({ response: null, errorMessage: null, checkerState: PublicationComplianceCheckerStateValues.pccsvNotApplicable });
            return;
        }
        this.setState({ checkerState: PublicationComplianceCheckerStateValues.pccsvInProgress });
        axios.default.post("/api/v2/DisciplinesCompliance", { author: this.props.author, pub: this.props.idPub, disc: this.props.discipline }).then(function (res) {
            var evalResponseStatus = _this.obtainStatus(res.data);
            _this.setState({ response: res.data, errorMessage: _this._err_msg_string, checkerState: evalResponseStatus });
        }).catch(function (err) {
            _this.setState({ response: null, errorMessage: err.message, checkerState: PublicationComplianceCheckerStateValues.pccsvError });
        });
    };
    PublicationComplianceChecker.prototype.obtainStatus = function (p) {
        this._allowed_disc = p.allowed;
        if (p.correct == "true") {
            this._err_msg_string = null;
            return PublicationComplianceCheckerStateValues.pccsvMatched;
        }
        else if (p.correct == "false") {
            this._err_msg_string = null;
            return PublicationComplianceCheckerStateValues.pccsvUnmatched;
        }
        else if (p.correct == "error") {
            this._err_msg_string = p.problem;
            return PublicationComplianceCheckerStateValues.pccsvError;
        }
        else {
            this._err_msg_string = "Nierozpoznany typ odpowiedzi";
            return PublicationComplianceCheckerStateValues.pccsvError;
        }
    };
    PublicationComplianceChecker.prototype.render = function () {
        var k = "notifPubNo" + this.props.idPub;
        switch (this.state.checkerState) {
            case PublicationComplianceCheckerStateValues.pccsvError:
                if (this.state.errorMessage == null)
                    return React.createElement(material_1.Alert, { key: k, severity: "error" }, this._err_msg_string);
                else
                    return React.createElement(material_1.Alert, { key: k, severity: "error" },
                        "B\u0142\u0105d pobierania zgodno\u015Bci ",
                        this.state.errorMessage);
            case PublicationComplianceCheckerStateValues.pccsvUndefined:
                return React.createElement("div", { key: k }, "?");
            case PublicationComplianceCheckerStateValues.pccsvInProgress:
                return React.createElement("div", { key: k }, "...");
            case PublicationComplianceCheckerStateValues.pccsvMatched:
                return React.createElement(material_1.Alert, { key: k, variant: "filled", severity: "success" });
            case PublicationComplianceCheckerStateValues.pccsvUnmatched:
                return React.createElement(material_1.Alert, { key: k, variant: "filled", severity: "warning" },
                    React.createElement(Tooltip_1.default, { title: this._allowed_disc.join(", ") },
                        React.createElement("span", null, "Dysc. czasop.")));
            case PublicationComplianceCheckerStateValues.pccsvNotApplicable:
            default:
                return React.createElement("div", { key: k }, "\u00A0");
        }
    };
    return PublicationComplianceChecker;
}(React.Component));
exports.default = PublicationComplianceChecker;
